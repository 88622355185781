import { useWebHost } from "../../hooks/useBridge";
import { PageRoot } from "../../components/PageRoot";
import { DemoCell } from "./DemoCell";
import React from "react";

export function PageArchDemoPage() {
  const webHost = useWebHost();

  return (
    <PageRoot pageData={undefined}>
      <DemoCell onClick={() => webHost.openBridge(`simple-data-demo`)}>
        Simple Data
      </DemoCell>
      <DemoCell onClick={() => webHost.openBridge(`simple-list-demo`)}>
        Simple List
      </DemoCell>
      <DemoCell onClick={() => webHost.openBridge(`page-footer-demo`)}>
        Page Footer
      </DemoCell>

      <DemoCell onClick={() => webHost.openBridge("page-bg-demo")}>
        Page Background
      </DemoCell>
    </PageRoot>
  );
}
