import { CenterModal, useModalWithItsOwnPage } from "../../components/Modal";
import { useNumberSearchParam } from "../../hooks/useTypedParam";
import { useMemo } from "react";
import { useI18n } from "../../hooks/useI18n";
import { Visit } from "../migrated/MigratedPage";
import styled, { css } from "styled-components";
import { VSpace } from "../../components/VStack";
import { useNativePage, useWebHost } from "../../hooks/useBridge";
import { andLog } from "../../components/handleError";
import { formatDate } from "../../utils/DateTimeUtils";
import { getOS } from "../../utils/deviceModel";
import { PowerMarkdown } from "../../components/PowerMarkdown";

const Title = styled.div`
  font-size: 20px;
  font-weight: 700;

  text-align: center;
`;

const Info = css`
  font-size: 15px;
  font-weight: 300;

  text-align: center;
`;

export default function NoticePage() {
  const cloverPhase = useNumberSearchParam("cloverPhase");
  const modal = useModalWithItsOwnPage();
  const i18n = useI18n();
  const webHost = useWebHost();

  const phase = useMemo(() => {
    if (!cloverPhase) {
      return 1;
    }
    if (cloverPhase < 1) {
      return 1;
    }
    if (cloverPhase > 3) {
      return 3;
    }
    return cloverPhase;
  }, [cloverPhase]);

  const title = useMemo(() => {
    switch (phase) {
      case 2:
        return i18n.clover_migrate_phase_2_title();
      case 3:
        return i18n.clover_migrate_phase_3_title();
      default:
        return i18n.clover_migrate_phase_1_title();
    }
  }, [phase]);

  const info = useMemo(() => {
    switch (phase) {
      case 2:
        return i18n.clover_migrate_phase_2_info();
      case 3:
        return i18n.clover_migrate_phase_3_info_new();
      default:
        return i18n.clover_migrate_phase_1_info();
    }
  }, [phase]);

  const buttonTitle = useMemo(() => {
    switch (phase) {
      case 2:
      case 3:
        return i18n.clover_upgrade_app();
      default:
        return i18n.clover_migrate_open_clover();
    }
  }, [phase]);

  const onVisitClick = () => {
    if (phase == 1) {
      webHost.openInWebBrowser("https://clover.space/downloads").catch(andLog);
    } else {
      const os = getOS();
      let link = "https://clover.space/downloads";
      if (os === "iOS") {
        link = "itms-apps://itunes.apple.com/app/id1528320292";
      } else if (os === "Android") {
        link =
          "https://play.google.com/store/apps/details?id=com.projz.z.android";
      }
      webHost.openInWebBrowser(link).catch(andLog);
    }
  };

  return (
    <CenterModal modal={modal} hideCloseBtn={phase === 3}>
      <Title>{title}</Title>
      <PowerMarkdown textStyle={Info}>{info}</PowerMarkdown>
      <VSpace height={5}></VSpace>
      <Visit
        style={{
          width: "calc(100% + 30px)",
          paddingTop: "3px",
          paddingBottom: "5px",
        }}
        onClick={onVisitClick}
      >
        {buttonTitle}
      </Visit>
    </CenterModal>
  );
}
