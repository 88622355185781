import { range } from "../../utils/List";
import { useSWR } from "../../hooks/swr/useSWR";
import { PageRoot } from "../../components/PageRoot";
import { DemoCell } from "./DemoCell";

function getData() {
  return new Promise<string[]>((resolve, reject) => {
    setTimeout(() => {
      const data = range(0, 100).map((i) => `data.${i}`);
      resolve(data);
    }, 500);
  });
}

export function SimpleDataDemoPage() {
  const dataSWR = useSWR(["SimpleDataDemoPage"], getData);

  return (
    <PageRoot pageData={dataSWR}>
      {dataSWR.content?.map((d) => <DemoCell key={d}>{d}</DemoCell>)}
    </PageRoot>
  );
}
