import { EnumLike, ZodType } from "zod/lib/types";
import { z } from "zod";
import BigNumber from "bignumber.js";
import { JSONUtil } from "./JSONUtil";

export function zEnum<T extends EnumLike>(value: T) {
  return z.nativeEnum(value).catch((ctx) => {
    const input = ctx.input as unknown;
    if (typeof input === "number") {
      return ctx.input;
    } else {
      throw ctx.error;
    }
  });
}

export type zStatic<T extends ZodType<any, any, any>> = Readonly<z.infer<T>>;

export const zBigInt = z.bigint().catch((ctx) => {
  const input = ctx.input as unknown;
  if (typeof input === "number") {
    return BigInt(ctx.input);
  } else if (BigNumber.isBigNumber(input)) {
    return BigInt(input.toFixed());
  } else {
    throw ctx.error;
  }
});

export const zBigNumber = z.instanceof(BigNumber).catch((ctx) => {
  const input = ctx.input as unknown;
  if (typeof input === "number") {
    return new BigNumber(input);
  } else if (typeof input === "bigint") {
    return new BigNumber(input.toString());
  } else if (typeof input === "string") {
    return new BigNumber(input);
  } else {
    throw ctx.error;
  }
});
