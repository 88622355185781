import React, { useEffect, useState } from "react";
import { useSafeAreaInsets } from "./useSafeAreaInsets";

type FocusState = {
  isFocus: boolean;
  focusedElement: HTMLElement | undefined;
};

export function useImeTranslate(): [
  React.CSSProperties,
  (state: FocusState) => void,
] {
  const safeInsets = useSafeAreaInsets();
  const [translate, setTranslate] = useState(0);
  const [focusState, setFocusState] = useState<FocusState>({
    isFocus: false,
    focusedElement: undefined,
  });

  useEffect(() => {
    let lastTimeoutTask = setTimeout(() => {
      let isFocus = focusState.isFocus;
      let focusedElement = focusState.focusedElement;
      if (isFocus && focusedElement) {
        const bottom =
          focusedElement.getBoundingClientRect().height +
          getElementPosition(focusedElement).y;
        const viewportHeight = window.innerHeight;
        const inputToBottom = viewportHeight - bottom;
        const offset = safeInsets.imeBottom - inputToBottom;
        if (offset >= 0) setTranslate(-offset);
        else setTranslate(0);
      } else {
        setTranslate(0);
      }
    }, 50);
    return () => {
      clearTimeout(lastTimeoutTask);
    };
  }, [focusState, safeInsets]);

  return [
    {
      translate: `0 ${translate}px`,
      transition: "translate 0.2s ease-in-out",
    },
    setFocusState,
  ];
}

export function getElementPosition(i: HTMLElement) {
  let element: HTMLElement | undefined = i;
  let x = 0,
    y = 0;
  while (element) {
    x += element.offsetLeft - element.scrollLeft + element.clientLeft;
    y += element.offsetTop - element.scrollTop + element.clientTop;
    const parent = element.offsetParent;
    if (parent as HTMLElement) {
      element = parent as HTMLElement;
    } else {
      element = undefined;
    }
  }
  return { x: x, y: y };
}
