import {
  HStack,
  HStackMixin,
  Spring,
  VSpace,
  VStack,
} from "../../components/VStack";
import { zStatic } from "../../utils/zodUtils";
import { MoonUser, TokenProjectHot } from "../../proto/TokenProject";
import styled, { css } from "styled-components";

import logo from "../../res/images/todamoon_logo.png";
import { Image } from "../../components/Image";
import { useMemo } from "react";
import { getFittestRes } from "../../proto/Media";
import { linear_gradient_border } from "../../components/vscroll/SVGUtils";
import { I18n, useI18n } from "../../hooks/useI18n";
import { formatBigNumberImpl, formatNumber } from "../../utils/NumberI18n";
import BigNumber from "bignumber.js";
import { AlignUndefined } from "../../utils/Nullable";
import cap_dec from "../../res/images/cap_dec.svg";
import cap_inc from "../../res/images/cap_inc.svg";
import pointing from "../../res/images/pointing.webp";
import { AutoscrollMsg } from "../../components/AutoscrollMsg";
import {
  moon_pixel_font,
  moon_pixel_font_size_12,
  moon_pixel_font_weight_400,
  shrink_on_pressed2,
} from "../../components/CommonStyles";
import { useWebHost } from "../../hooks/useBridge";
import { andLog } from "../../components/handleError";
import { SingleLineUnspecifiedWidth } from "../../components/CommonViews";
import GraphemeSplitter from "grapheme-splitter";
import { truncate } from "../../utils/TextUtils";

export const AdTag = styled.div`
  position: absolute;
  bottom: 5px;
  right: 5px;
  display: flex;
  padding: 2px 4px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.4);

  font-size: 8px;
  font-weight: 400;
`;

const Card = styled.div`
  position: relative;
  display: flex;
  min-height: calc(100vw - 40px);
  flex-direction: column;
  align-items: stretch;
  border-radius: 12px;
  border: 1px solid #ffd704;
  box-sizing: border-box;
  box-shadow: 0 0 8px 1px rgba(255, 199, 0, 0.41);
  margin: 10px 20px;
  border-image: ${linear_gradient_border(
    12,
    1,
    180,
    ["#FFF200", 0],
    ["#28CD95", 1],
  )};

  ${shrink_on_pressed2};
`;

const Icon = styled.img`
  width: 48px;
  height: 48px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 2px solid #ffd704;
  border-image: ${linear_gradient_border(
    8,
    2,
    180,
    ["#FFF200", 0],
    ["#28CD95", 1],
  )};
`;

const Name = styled.div`
  color: #0c1b2d;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 600;
  ${SingleLineUnspecifiedWidth};
`;

const Ticker = styled.div`
  color: #fff;
  font-size: 12px;
  font-weight: 800;
  background: #080b2a;
  padding: 0 0 0 5px;
  margin: 1px 1px 1px -4px;
  ${SingleLineUnspecifiedWidth};
`;

const MarketCapBg = css`
  background: linear-gradient(270deg, #021741 53%, rgba(2, 23, 65, 0) 100%);
  padding: 8px;
  align-items: end;
  flex-shrink: 0;
`;

const CapTitle = styled.div`
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
  font-size: 8px;
  font-weight: 300;
`;

const CapAmount = styled.div`
  overflow: hidden;
  color: #fff;
  text-overflow: ellipsis;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 16px;
  font-weight: 700;
`;

const CapTrend = styled.div`
  text-align: center;
  font-size: 13px;
  font-weight: 600;
`;

const InfoText = styled.span`
  font-style: normal;

  ${moon_pixel_font};
  ${moon_pixel_font_size_12};
  ${moon_pixel_font_weight_400};

  text-align: center;

  background: linear-gradient(90deg, #ebff00 0%, #2bfa78 51%, #00acff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const MiniEarned = styled.div`
  ${HStackMixin};
  height: 24px;
  gap: 2px;
  margin: 0 auto;
  border-radius: 12px;
  background: #021741;
  padding: 0 4px;
`;

const EarnedText = styled.div`
  color: #767da6;
  font-size: 10px;
  font-weight: 400;
  text-align: center;
`;

const EarnedAmount = styled.span`
  color: #ffd704;
  font-size: 12px;
  font-weight: 700;
  vertical-align: middle;
`;

function EarnedMsg(props: {
  user?: zStatic<typeof MoonUser>;
  amount: string;
  ticker: string;
}) {
  const i18n = useI18n();
  return (
    <MiniEarned>
      <Image
        key={2}
        style={{ borderRadius: 9, border: "1px solid #19FB9B" }}
        width={16}
        height={16}
        src={[props.user?.icon, 16]}
      />
      <EarnedText>
        {i18n.moon_ads_just_earned(
          truncate(i18n, props.user?.nickname, 6) ?? "-",
          <EarnedAmount>{`${formatMoney("medium", new BigNumber(props.amount))} ${props.ticker}`}</EarnedAmount>,
        )}
      </EarnedText>
    </MiniEarned>
  );
}

function getCapTrendColor(trend: number) {
  if (trend === 0) {
    return "white";
  } else if (trend < 0) {
    return "#f03d3d";
  } else {
    return "#19FB9B";
  }
}

function getCapTrendIcon(trend: number) {
  if (trend === 0) {
    return undefined;
  } else if (trend < 0) {
    return cap_dec;
  } else {
    return cap_inc;
  }
}

export function MoonHotProject(props: {
  projectHot: Required<TokenProjectHot>;
}) {
  const webHost = useWebHost();
  const project = props.projectHot.project;

  const bgUrl = useMemo(() => {
    return project.image
      ? getFittestRes(project.image, { width: 400, height: 400 }).url
      : undefined;
  }, [project.image]);

  const iconUrl = useMemo(() => {
    return project.image
      ? getFittestRes(project.image, { width: 48, height: 48 }).url
      : undefined;
  }, [project.image]);

  const cap = project.tradingStatistics?.marketCap ?? "0";
  const capTrend = project.tradingStatistics?.marketCap1Hip ?? 0;

  const capTrendColor = getCapTrendColor(capTrend);
  const capTrendIcon = getCapTrendIcon(capTrend);

  const freeAmount = new BigNumber(props.projectHot.amount);
  const i18n = useI18n();

  const infoText =
    project.enableShareToEarn && !freeAmount.isLessThanOrEqualTo(0)
      ? i18n.moon_ads_get_for_free(
          `${formatMoney("medium", freeAmount)} ${project.ticker}`,
        )
      : i18n.moon_ads_explore_memecoins();
  return (
    <Card
      style={{
        background: `linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%), url(${bgUrl}) center/cover no-repeat`,
      }}
      onClick={() => {
        if (props.projectHot.url) {
          webHost.openInWebBrowser(props.projectHot.url).catch(andLog);
        }
      }}
    >
      <Image
        src={logo}
        height={20}
        width={126}
        style={{ margin: "8px 10px" }}
      />
      <Spring />
      <HStack style={{ margin: "0px 0px 0px 10px" }}>
        <Image src={iconUrl} styledImg={Icon} style={{ zIndex: 1 }} />
        <VStack
          style={{
            background: "#28CD95",
            alignItems: "stretch",
            marginLeft: -3,
            padding: "1px 1px 1px 4px",
            gap: 1,
            flexShrink: 1,
          }}
        >
          <Name>{project.name}</Name>
          <Ticker>{project.ticker}</Ticker>
        </VStack>
        <Spring />
        {project.tradingStatistics && (
          <VStack mixin={MarketCapBg}>
            <CapTitle>{i18n.moon_ads_market_cap()}</CapTitle>
            <CapAmount>{`${formatMoney("short", new BigNumber(cap))} USD`}</CapAmount>
            {capTrendIcon && (
              <HStack style={{ gap: 4 }}>
                <Image src={capTrendIcon} />
                <CapTrend
                  style={{ color: capTrendColor }}
                >{`${formatNumber(capTrend, 2)}% (1h)`}</CapTrend>
              </HStack>
            )}
          </VStack>
        )}
      </HStack>

      {project.enableShareToEarn && project.topTenUserEarnRewards.length > 0 ? (
        <AutoscrollMsg height={40}>
          {project.topTenUserEarnRewards.map((earn) => {
            return (
              <EarnedMsg
                key={earn.statsId}
                user={earn.user}
                ticker={project.ticker}
                amount={earn.totalEarned}
              />
            );
          })}
        </AutoscrollMsg>
      ) : (
        <VSpace height={10} />
      )}

      <div
        style={{
          margin: "0 auto 10px",
          padding: "0 10px",
          textAlign: "center",
        }}
      >
        <Image
          src={pointing}
          width={28}
          height={28}
          needBg={false}
          style={{ verticalAlign: -7 }}
        />
        <InfoText>{" " + infoText}</InfoText>
      </div>

      <AdTag>AD</AdTag>
    </Card>
  );
}

type MonetaryFormat = "short" | "medium" | "long";

function formatToConfig(
  format: MonetaryFormat,
): Parameters<typeof formatBigNumberImpl>[1] {
  switch (format) {
    case "long":
      return {
        compressLeadingZeros: true,
      };
    case "medium":
      return {
        decimalPlaces: 3,
        decimalPlacesWhenLessThanZero: 5,
        compressLeadingZeros: true,
      };
    case "short":
      return {
        kmb: true,
        decimalPlaces: 2,
        decimalPlacesWhenLessThanZero: 5,
        compressLeadingZeros: true,
      };
  }
}

export function formatMoney<T extends BigNumber | undefined>(
  format: MonetaryFormat,
  value: T,
): AlignUndefined<string, T> {
  if (value === undefined) return undefined as any;

  const config = formatToConfig(format);

  return formatBigNumberImpl(value, config);
}
