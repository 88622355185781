import { PageRoot } from "../../components/PageRoot";
import { HStack } from "../../components/VStack";
import { useState } from "react";
import { FilterTab } from "../../components/FilterTab";

export function FilterTabDemoPage() {
  const [selected, setValue] = useState(false);
  return (
    <PageRoot pageData={undefined}>
      <HStack style={{ gap: 10 }}>
        <FilterTab
          onClick={() => {
            setValue(!selected);
          }}
          // style={getButtonStyle(selected)}
          selected={selected}
        >
          Tab1
        </FilterTab>
      </HStack>
    </PageRoot>
  );
}
