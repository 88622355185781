import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import "./components/common.css";
import { DebugHomePage } from "./pages/debug/DebugHomePage";
import { NotFoundPage } from "./pages/error/NotFoundPage";
import { AppHomePage } from "./pages/error/AppHomePage";
import { debugPagesRoute } from "./pages/debug/debugPagesRoute";
import { StyleSheetManager } from "styled-components";
import { shouldForwardProp } from "./utils/shouldForwardProp";
import BigNumber from "bignumber.js";
import { useCurrentLanguage } from "./hooks/useI18n";
import { getNumberFormatConfig } from "./utils/NumberI18n";
import { MoonCardWidget } from "./pages/ads/MoonCardWidget";
import NoticePage from "./pages/notice/NoticePage";
import MigratePage from "./pages/migrated/MigratedPage";

const router = createBrowserRouter([
  { path: "/", element: <AppHomePage /> },
  { path: "*", element: <NotFoundPage /> },
  { path: "/home-ads", element: <MoonCardWidget /> },
  { path: "/notice", element: <NoticePage /> },
  { path: "/migrated", element: <MigratePage /> },
  { path: "/debug-home", element: <DebugHomePage /> },
  ...debugPagesRoute,
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

function Main() {
  const lang = useCurrentLanguage();
  useEffect(() => {
    const config = getNumberFormatConfig(lang);

    BigNumber.config({
      FORMAT: {
        // decimal separator
        decimalSeparator: config.decimalSeparator,
        // grouping separator of the integer part
        groupSeparator: config.groupSeparator,
        // primary grouping size of the integer part
        groupSize: config.groupSize,
        // secondary grouping size of the integer part
        secondaryGroupSize: config.secondaryGroupSize,
      },
    });
  }, [lang]);

  return (
    <React.StrictMode>
      <StyleSheetManager shouldForwardProp={shouldForwardProp}>
        <RouterProvider router={router} />
      </StyleSheetManager>
    </React.StrictMode>
  );
}

root.render(<Main />);

serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
