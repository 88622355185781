import {
  cell_on_pressed,
  full_group_width_cell,
} from "../../components/CommonStyles";
import { ContentGroup, GroupCellDivider } from "../../components/ContentGroup";
import styled from "styled-components";
import { HStack, Spring } from "../../components/VStack";
import { DemoNote } from "./DemoCell";
import { PageRoot } from "../../components/PageRoot";

const Label = styled.div`
  color: var(--color-text00);
  font-size: 16px;
`;

const Value = styled.div`
  color: var(--color-text10);
  font-size: 14px;
`;

export function ContentGroupDemoPage() {
  return (
    <PageRoot pageData={undefined}>
      <DemoNote>Default Padding</DemoNote>
      <ContentGroup>
        <HStack style={{ paddingTop: 16, paddingBottom: 16 }}>
          <Label>The Label</Label>
          <Spring />
          <Value>1234</Value>
        </HStack>
      </ContentGroup>

      <DemoNote>Omit Vertical Padding</DemoNote>
      <ContentGroup omitTopPadding={true} omitBottomPadding={true}>
        <HStack
          style={{ paddingTop: 16, paddingBottom: 16 }}
          mixin={[full_group_width_cell, cell_on_pressed]}
        >
          <Label>Clickable</Label>
          <Spring />
          <Value>1234</Value>
        </HStack>

        <GroupCellDivider />

        <HStack style={{ paddingTop: 16, paddingBottom: 16 }}>
          <Label>The Label</Label>
          <Spring />
          <Value>1234</Value>
        </HStack>

        <GroupCellDivider />

        <HStack
          style={{ paddingTop: 16, paddingBottom: 16 }}
          mixin={[full_group_width_cell, cell_on_pressed]}
        >
          <Label>Clickable</Label>
          <Spring />
          <Value>1234</Value>
        </HStack>
      </ContentGroup>
    </PageRoot>
  );
}
