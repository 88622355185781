import React, { ReactElement, useContext, useEffect } from "react";
import { PageRootContext } from "./PageRootContext";
import { assert } from "../utils/asserts";

export function PageHeader(props: { children?: ReactElement }) {
  const pageRootContext = useContext(PageRootContext);
  assert(pageRootContext.hasPageRoot);

  useEffect(() => {
    if (props.children) pageRootContext.addHeader(props.children);
    return () => {
      if (props.children) pageRootContext.removeHeader(props.children);
    };
  }, [props.children]);

  return <></>;
}

export function PageFooter(props: { children?: ReactElement }) {
  const pageRootContext = useContext(PageRootContext);
  assert(pageRootContext.hasPageRoot);

  useEffect(() => {
    if (props.children) pageRootContext.addFooter(props.children);
    return () => {
      if (props.children) pageRootContext.removeFooter(props.children);
    };
  }, [props.children]);

  return <></>;
}
