import {
  NavButtonDesc,
  NavButtonType,
  SearchBarConfig,
} from "../bridge/NativePage";
import { DependencyList, useEffect } from "react";
import { andLog } from "../components/handleError";
import { useNativePage } from "./useBridge";
import { useI18n } from "./useI18n";

export function useNavBarEndButton(
  button: NavButtonDesc,
  action: () => void,
  deps: DependencyList,
) {
  const nativePage = useNativePage();

  useEffect(() => {
    nativePage.setNavBarEndButton(button, action).catch(andLog);
  }, deps);
}

export function useNavBarEndButton2(
  button0: NavButtonDesc,
  action0: () => void,
  button1: NavButtonDesc,
  action1: () => void,
  deps: DependencyList,
) {
  const nativePage = useNativePage();

  useEffect(() => {
    nativePage
      .setNavBarEndButton2(button0, action0, button1, action1)
      .catch(andLog);
  }, deps);
}

export function useNavBarStartButton(
  button: NavButtonDesc,
  action: () => void,
  deps: DependencyList,
) {
  const nativePage = useNativePage();

  useEffect(() => {
    nativePage.setNavBarStartButton(button, action).catch(andLog);
  }, deps);
}

export function useNavBarCloseForEdit(
  hasChanges: () => boolean,
  onClose: () => void,
  deps: DependencyList,
) {
  const nativePage = useNativePage();
  const i18n = useI18n();

  async function alertThenClose() {
    if (hasChanges()) {
      if (
        !(await nativePage.alertAreYouSure(
          i18n.compose_confirmation(),
          i18n.quit(),
          i18n.oct12_text_continue_editing(),
        ))
      ) {
        return;
      }
    }

    onClose();
  }

  useNavBarStartButton(
    { type: NavButtonType.Cross },
    () => alertThenClose().catch(andLog),
    deps,
  );
}

export function useSearchBarWithCommit(
  config: SearchBarConfig,
  onTextChange: (text: string) => void,
  deps: DependencyList,
) {
  console.log("useSearchBarWithCommit", config.searchWord);
  const nativePage = useNativePage();
  useEffect(() => {
    nativePage.addSearchToNavBarWithCommit(config, onTextChange).catch(andLog);
  }, deps);
}

export function useOnSearchCommit(onCommit: () => void, deps: DependencyList) {
  const nativePage = useNativePage();
  useEffect(() => {
    nativePage.setSearchCommitListener(onCommit).catch(andLog);
  }, deps);
}
