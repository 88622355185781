import { assert } from "./asserts";

export function groupByCount<T>(list: T[], count: number): T[][] {
  return list.reduce((acc, curr, index) => {
    if (index % count === 0) {
      acc.push([curr]);
    } else {
      acc[acc.length - 1].push(curr);
    }
    return acc;
  }, [] as T[][]);
}

export function range(start: number, end: number): number[] {
  assert(end >= start);
  if (start === end) return [start];
  return [start, ...range(start + 1, end)];
}
