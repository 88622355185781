// This file is generated by xml2ts.py. Don't change


import { getString_ru_RU } from "./ru-RU/strings";
import { getString_ja } from "./ja/strings";
import { getString_ar } from "./ar/strings";
import { getString_pt_BR } from "./pt-BR/strings";
import { getString_es_419 } from "./es-419/strings";
import { getString_en_US } from "./en-US/strings";


export const getStringByLocale = new Map([
  ["ru-RU", getString_ru_RU],
  ["ja", getString_ja],
  ["ar", getString_ar],
  ["pt-BR", getString_pt_BR],
  ["es-419", getString_es_419],
  ["en-US", getString_en_US],
]);

