import { PageRoot } from "../../components/PageRoot";
import { Image } from "../../components/Image";
import IcAlert from "./images/ic_migrate_alert.svg";
import IcBtn from "./images/ic_button.svg";
import { VSpace, VStack } from "../../components/VStack";
import React from "react";
import styled, { css } from "styled-components";
import { useI18n } from "../../hooks/useI18n";
import { button_on_pressed } from "../../components/CommonStyles";
import { useNativePage, useWebHost } from "../../hooks/useBridge";
import { andLog, handleError } from "../../components/handleError";

const Info = styled.div`
  font-size: 15px;
  font-weight: 400;
  color: white;
  text-align: center;
  padding: 0 30px;
`;

export const Visit = styled.button`
  border-image-source: url("${IcBtn}");
  border-image-repeat: round;
  border-image-slice: 10 fill;
  border-width: 10px;
  border-style: solid;
  width: 100%;

  background-color: transparent;
  padding: 8px 0 11px 0;
  ${button_on_pressed};

  font-size: 17px;
  color: #94ffde;
  font-weight: bold;
  text-shadow: 0 1px 12px #00a88c;
`;

const Stack = css`
  height: 100%;
  justify-content: center;
  margin: 0 var(--page-h-inset);
`;

const LogOut = styled.div`
  color: #ff3e65;
  font-size: 16px;
  font-weight: 500;
  ${button_on_pressed};
`;

export default function MigratePage() {
  const i18n = useI18n();
  const nativePage = useNativePage();
  const webHost = useWebHost();

  const onClickVisit = () => {
    webHost.openInWebBrowser("https://clover.space/downloads").catch(andLog);
  };

  const onClickLogOut = async () => {
    try {
      await nativePage.logOut();
    } catch (error) {
      handleError(nativePage, i18n, error);
    }
  };

  return (
    <PageRoot pageData={undefined}>
      <VStack mixin={Stack}>
        <Image src={IcAlert} />
        <Info>{i18n.clover_migrate_migrated_info()}</Info>
        <VSpace height={30}></VSpace>
        <Visit onClick={onClickVisit}>
          {i18n.clover_migrate_open_clover()}
        </Visit>
        <VSpace height={10}></VSpace>
        <LogOut onClick={onClickLogOut}>{i18n.auth_log_out()}</LogOut>
      </VStack>
    </PageRoot>
  );
}
