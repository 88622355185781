import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { andLog } from "../components/handleError";
import { useWebHost } from "./useBridge";
import { flattenStateId, StateId } from "./StateId";
import { Initializer, StateOrStateProvider } from "./Initializer";

export function useWebHostState<S>(
  id: StateId,
  initialState: S | (() => S),
): [S, Dispatch<SetStateAction<S>>, () => void];

export function useWebHostState<S>(
  id: StateId,
): [S | undefined, Dispatch<SetStateAction<S | undefined>>, () => void];

export function useWebHostState<S>(
  id: StateId,
  initialState?: Initializer<S>,
): [S, Dispatch<StateOrStateProvider<S>>, () => void] {
  const webHost = useWebHost();
  const stateId = useMemo<string>(() => flattenStateId(id), [id]);

  const [state, setState] = useState<S>(() => {
    const savedState = webHost.getAccurateState<S>(stateId);

    if (savedState === "absent") {
      if (initialState !== undefined) {
        if (typeof initialState === "function") {
          return initialState();
        } else {
          return initialState;
        }
      } else {
        return undefined;
      }
    } else {
      return savedState.state;
    }
  });

  const setter = useMemo(() => {
    return (stateOrProvider: StateOrStateProvider<S>) => {
      if (typeof stateOrProvider === "function") {
        setState((prev) => {
          const next = stateOrProvider(prev);
          webHost.setStateValue(next, stateId).catch(andLog);
          return next;
        });
      } else {
        webHost.setStateValue(stateOrProvider, stateId).catch(andLog);
        setState(stateOrProvider);
      }
    };
  }, [setState, webHost]);

  return [state, setter, () => webHost.removeStateValue(stateId)];
}
