import { EventName } from "./EventName";
import { Rejectable } from "./Rejectable";
import { LocalMedia } from "./LocalMedia";

export enum NavButtonType {
  More = 1,
  Cross = 2,
  Check = 3,
  Info = 4,
  Manage = 5,
  Text = 6,

  DoneTitle = 100,
}

export type NavButtonDesc = {
  type: NavButtonType;
  title?: string;
};

export type MediaMenuResult = {
  type: "picked" | "deleted" | "cancelled" | "error" | "reset";
  mediaList: LocalMedia[];
  error?: Rejectable;
};

export type MediaMenuConfig = {
  mimeTypes: string[];
  maxCount: number;
  cropAspectRatio?: {
    width: number;
    height: number;
  };
  // autoApplyIfMaxIsOne?: boolean,
  // allowDelete?: boolean,
  enableCapture?: boolean;
  // maxVideoCount?: number,
  // showSingleMediaType?: boolean,
  // videoMaxDuration?: number,
  // maxSize?: number,
  // compressedSize?: {
  //   width: number,
  //   height: number
  // }
  // autoSelectIfSingleSource?: boolean
};

export type DatePickerConfig = {
  components: boolean[];
  title?: string;
  initialSeconds?: number;
  minSeconds?: number;
  maxSeconds?: number;
};

export type SearchBarConfig = {
  activeKeyboard: boolean;
  searchWord: string;
  placeholder: string;
};

export type NativePage = {
  appeal: (objectId: bigint, objectType: number) => Promise<void>;

  setNavBarEndButton: (
    button: NavButtonDesc,
    action: () => void,
  ) => Promise<void>;
  setNavBarEndButton2: (
    button: NavButtonDesc,
    action: () => void,
    button2: NavButtonDesc,
    action2: () => void,
  ) => Promise<void>;
  setNavBarEndButtonEnabled: (index: number, enabled: boolean) => Promise<void>;

  removeAllNavBarEndButtons: () => Promise<void>;
  setNavBarStartButton: (
    button: NavButtonDesc,
    action: () => void,
  ) => Promise<void>;
  resetNavBarStartButtonsToBack: () => Promise<void>;

  addSearchToNavBarWithCommit: (
    config: SearchBarConfig,
    onTextChange: (text: string) => void,
  ) => Promise<void>;
  addSearchToNavBar: (
    config: SearchBarConfig,
    onTextChange: (text: string) => void,
  ) => Promise<void>;
  setSearchCommitListener: (onCommit: () => void) => Promise<void>;

  removeSearchFromNavBar: () => Promise<void>;

  viewOriginalContent: (objectId: bigint, objectType: number) => Promise<void>;

  showSpinner: () => Promise<string>;
  endSpinner: (id: string) => Promise<void>;

  infoHud: (title: string) => Promise<void>;
  warnHud: (title: string) => Promise<void>;
  successHud: (title: string) => Promise<void>;

  alertNotice: (message: string, okTitle: string) => Promise<void>;
  alertAreYouSure: (
    message: string,
    sureTitle: string,
    cancelTitle: string,
  ) => Promise<boolean>;
  alertYesOrCancel: (
    message: string,
    sureTitle: string,
    cancelTitle: string,
  ) => Promise<boolean>;

  navigateBack: () => Promise<void>;

  setPageTitle: (title: string) => Promise<void>;

  freezeNavBar: (id: string, back: () => void) => Promise<void>;
  unfreezeNavBar: (id: string) => Promise<void>;

  listenToBroadcast: (
    id: string,
    event: EventName,
    action: (param: object) => void,
  ) => Promise<void>;
  unlistenToBroadcast: (id: string, event: EventName) => Promise<void>;
  verifyProfile: (verifyCode: string) => Promise<void>;

  playBoomAnimation: () => Promise<void>;

  getWidgetEnabled: () => Promise<boolean>;

  showMediaMenu: (resultId: string, config: MediaMenuConfig) => Promise<void>;
  pickDate: (resultId: string, config: DatePickerConfig) => Promise<void>;

  pickColor: (
    resultId: string,
    current: string,
    title: string,
  ) => Promise<void>;

  pickSticker: (resultId: string) => Promise<void>;

  // back pressed callback is android only
  addBackPressedCallback: (id: string, action: () => void) => Promise<void>;
  removeBackPressedCallback: (id: string) => Promise<void>;
  promoteCampaignInPost: (campaignId: bigint) => Promise<void>;
  showCircleCard: (circleId: bigint, uid: bigint) => Promise<void>;

  logOut: () => Promise<void>;
};
