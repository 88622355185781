import { BridgeError, decodeTPS, encodeTPS } from "./Bridge";
import { WebHostData } from "./WebHostData";
import { getUniqueId } from "../utils/UniqueId";
import { andLog } from "../components/handleError";
import { EventName } from "./EventName";
import { urlAppendQuery } from "../utils/UrlUtil";
import { Env } from "./Env";

type WebHostStateOrAbsent<T> = { state: T } | "absent";

type SetStateTask = {
  key: string;
  promise: Promise<void>;
};

export class WebHost {
  constructor(
    private readonly impl: WebHostImpl,
    private readonly rawData: WebHostData,
  ) {}

  private readonly accurateStates = new Map<
    string,
    WebHostStateOrAbsent<any>
  >();

  getLoggedInUserId() {
    return this.rawData.loggedInUserId;
  }

  getWebHostCreatedTime() {
    return this.rawData.webHostCreatedTime;
  }

  getEnv(): Env {
    return {
      isSpongeKit: this.rawData.isSpongeKit,
      loggedInUserId: this.rawData.loggedInUserId,
      webHostId: this.rawData.webHostId,
    };
  }

  private readonly pendingStateTasks = new Map<string, SetStateTask>();

  getAccurateState<T>(key: string): WebHostStateOrAbsent<T> {
    const updated = this.accurateStates.get(key);
    if (updated) {
      return updated as WebHostStateOrAbsent<T>;
    }

    const tps = this.rawData.savedStates.find((e) => e.key === key)?.value;
    let accurateState;
    if (tps) {
      const decoded = decodeTPS(tps);
      accurateState = { state: decoded };
    } else {
      accurateState = "absent" as const;
    }

    this.accurateStates.set(key, accurateState);
    return accurateState;
  }

  getState<T>(key: string): T | undefined {
    const accurateState = this.getAccurateState<T>(key);
    if (accurateState === "absent") {
      return undefined;
    } else {
      return accurateState.state;
    }
  }

  setStateValue<T>(value: T, key: string) {
    const taskId = getUniqueId();
    this.accurateStates.set(key, {
      state: value,
    });
    const promise = this.impl.setStateValue(encodeTPS(value), key);
    this.pendingStateTasks.set(taskId, { key: key, promise: promise });

    promise.catch(andLog).finally(() => {
      this.pendingStateTasks.delete(taskId);
    });

    return promise;
  }

  private allSetStateTasksSettled() {
    return Promise.allSettled(
      Array.from(this.pendingStateTasks.values()).map((t) => t.promise),
    );
  }

  openBridge(
    path: string,
    param?: {
      mode?: "push" | "present" | "modal";
      close?: number | string;
      requestId?: string;
      requestEnv?: any;
    },
    query?: object,
  ) {
    const totalQuery = {
      mode: param?.mode ?? "push",
      closeCount: typeof param?.close === "number" ? param.close : undefined,
      closeName: typeof param?.close === "string" ? param.close : undefined,
      resultId: param?.requestId,
      resultEnv: param?.requestEnv ? encodeTPS(param?.requestEnv) : undefined,
      ...query,
    };
    this.openLink(urlAppendQuery(`web-bridge/${path}`, totalQuery)).catch(
      (e) => {
        if (e instanceof BridgeError) {
          const url = `${window.location.protocol}//${window.location.host}/${urlAppendQuery(`${path}`, totalQuery)}`;
          window.location.assign(url);
        } else {
          andLog(e);
        }
      },
    );
  }

  // extraInfo {
  //    shouldVerify Boolean
  //    oId: related object id
  //    oType: related object type
  // }
  async openLink(path: string, extraInfo: object = {}) {
    console.log("@_@ open link", path);
    await this.allSetStateTasksSettled();
    return await this.impl.openLink(path, extraInfo);
  }

  removeStateValue = this.impl.removeStateValue;
  onContentSizeChange = this.impl.onContentSizeChange;
  broadcast = this.impl.broadcast;
  haptic = this.impl.haptic;
  vibrate = this.impl.vibrate;
  hasStoredKey = this.impl.hasStoredKey;
  saveImage = this.impl.saveImage;
  openInWebBrowser = this.impl.openInWebBrowser;
  nativeLogger = this.impl.nativeLogger;
}

export type WebHostImpl = {
  onContentSizeChange: (width: number, height: number) => Promise<void>;
  setStateValue: (value: string, key: string) => Promise<void>;
  removeStateValue: (key: string) => Promise<void>;
  hasStoredKey: (walletAccountId: bigint) => Promise<boolean>;
  openLink: (path: string, extraInfo: object) => Promise<void>;

  broadcast: (event: EventName, eventInfo: object) => Promise<void>;
  haptic: (
    style: "light" | "medium" | "heavy" | "soft" | "rigid",
  ) => Promise<void>;
  vibrate: () => Promise<void>;
  openInWebBrowser: (url: string) => Promise<void>;

  saveImage(
    base64Data: string,
    mimeType: string,
    isStatic: boolean,
    name: string,
  ): Promise<void>;

  nativeLogger(log: string): Promise<void>;
};
