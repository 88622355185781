import styled, { css, RuleSet } from "styled-components";
import { linear_gradient_border } from "./vscroll/SVGUtils";
import {
  accent_font,
  alpha_on_pressed,
  button_on_pressed,
  two_line_text,
} from "./CommonStyles";
import colorSetAlpha from "color-alpha";
import strong_button_border from "../res/images/strong_button_border.png";
import strong_large_button_border from "../res/images/strong_large_button_border.png";

function genBg(baseColor: string | undefined) {
  return `
  linear-gradient(
    -30deg,
    ${colorSetAlpha(baseColor ?? "white", 0.1)} 0%,
    ${colorSetAlpha(baseColor ?? "white", 0.03)} 55%,
    ${colorSetAlpha(baseColor ?? "white", 0.1)} 100%
  )
  `;
}

function genBorder(baseColor: string | undefined) {
  return linear_gradient_border(
    6,
    1,
    -30,
    [colorSetAlpha(baseColor ?? "white", 0.5), 0],
    [colorSetAlpha(baseColor ?? "white", 0.1), 0.4],
    [colorSetAlpha(baseColor ?? "white", 0.1), 0.6],
    [colorSetAlpha(baseColor ?? "white", 0.5), 1],
  );
}

export const ButtonColor = {
  default: "white",
  greenish: "#0DFFCC",
  bluish: "#00E6FF",
  destructive: "#EF3537",
};

const CenterContent = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  text-align: center;

  & > img {
    width: 28px;
    height: 28px;
  }
`;

const DefaultButtonHeight = css`
  min-height: 48px;
  padding: 0 20px;
`;

function getStrongBorder(color: string) {
  return `
<svg width="227" height="62" viewBox="0 0 227 62" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group 7099">
        <path id="Rectangle 2"
              d="M7 13C7 9.68629 9.68629 7 13 7H214C217.314 7 220 9.68629 220 13V47C220 50.3137 217.314 53 214 53H54.0976H40.5199H13C9.6863 53 7 50.3137 7 47V13Z"
              fill="url(#paint0_linear_5148_13479)"/>
        <g id="Rectangle 3" filter="url(#filter0_i_5148_13479)">
            <path d="M7 13C7 9.68629 9.70399 7 13.0177 7C90.4917 7 136.508 7 213.982 7C217.296 7 220 9.68629 220 13V47C220 50.3137 217.314 53 214 53H54.0976H40.5199H13C9.68629 53 7 50.3137 7 47V13Z"
                  fill="${color}" fill-opacity="0.01"/>
        </g>
        <g id="Rectangle 1" filter="url(#filter1_d_5148_13479)">
            <path d="M183.086 7H13C9.68629 7 7 9.68629 7 13V47C7 50.3137 9.68629 53 13 53H37.5498M201.543 7H214C217.314 7 220 9.68629 220 13V47C220 50.3137 217.314 53 214 53H54.9462"
                  stroke="${color}"/>
        </g>
        <g id="Ellipse 225" filter="url(#filter2_f_5148_13479)">
            <ellipse cx="113.076" cy="51.0834" rx="50.0677" ry="1.15" fill="${color}"/>
        </g>
    </g>
    <defs>
        <filter id="filter0_i_5148_13479" x="5" y="5" width="215" height="48" filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                           result="hardAlpha"/>
            <feOffset dx="-2" dy="-2"/>
            <feGaussianBlur stdDeviation="9"/>
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.55 0"/>
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_5148_13479"/>
        </filter>
        <filter id="filter1_d_5148_13479" x="0.5" y="0.5" width="226" height="59" filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                           result="hardAlpha"/>
            <feOffset/>
            <feGaussianBlur stdDeviation="3"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5148_13479"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5148_13479" result="shape"/>
        </filter>
        <filter id="filter2_f_5148_13479" x="53.9078" y="40.8333" width="118.335" height="20.5"
                filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
            <feGaussianBlur stdDeviation="4.55" result="effect1_foregroundBlur_5148_13479"/>
        </filter>
        <linearGradient id="paint0_linear_5148_13479" x1="-0.261362" y1="7" x2="17.8095" y2="91.6642"
                        gradientUnits="userSpaceOnUse">
            <stop stop-color="${color}" stop-opacity="0.1"/>
            <stop offset="0.587401" stop-color="${color}" stop-opacity="0.05"/>
            <stop offset="1" stop-color="${color}" stop-opacity="0.15"/>
        </linearGradient>
    </defs>
</svg>

`;
}

export const StrongButton = styled.button<{
  mixin?: RuleSet<Object>;
}>`
  ${CenterContent};
  ${DefaultButtonHeight};
  font-size: 16px;
  color: white;
  font-style: normal;
  font-weight: 400;
  position: relative;
  box-sizing: border-box;

  border-radius: 6px;
  background: transparent;

  border-image: url(${strong_button_border}) 36 198 fill/ 12px 66px / 6px 6px
    7px 6px stretch;

  &:disabled {
    opacity: 0.4;
  }

  ${button_on_pressed}
  ${(p) => p.mixin}
`;

export const StrongLargeButton = styled.button<{
  mixin?: RuleSet<Object>;
}>`
  ${CenterContent};
  min-height: 60px;
  font-size: 20px;
  color: white;
  ${accent_font};
  font-style: normal;
  font-weight: 400;
  position: relative;
  box-sizing: border-box;

  border-radius: 6px;
  background: transparent;

  border-image: url(${strong_large_button_border}) 36 198 fill/ 12px 66px / 6px
    6px 7px 6px stretch;

  &:disabled {
    opacity: 0.4;
  }

  ${button_on_pressed}
  ${(p) => p.mixin}
`;

export const RegularButton = styled.button<{
  baseColor?: string;
  mixin?: RuleSet<Object>;
}>`
  ${CenterContent};
  ${DefaultButtonHeight};
  font-size: 16px;
  color: ${(p) => p.baseColor ?? "white"};
  font-style: normal;
  font-weight: 400;
  position: relative;

  box-sizing: border-box;

  border-radius: 6px;

  background: ${(p) => genBg(p.baseColor)};

  border-image: ${(p) => genBorder(p.baseColor)};

  &:disabled {
    opacity: 0.4;
  }

  ${button_on_pressed}
  ${(p) => p.mixin}
`;

export const RegularLargeButton = styled.button<{
  baseColor?: string;
  mixin?: RuleSet<Object>;
}>`
  ${CenterContent};
  min-height: 60px;
  padding: 0 20px;
  font-size: 20px;
  color: ${(p) => p.baseColor ?? "white"};
  ${accent_font};
  font-style: normal;
  font-weight: 400;
  position: relative;
  box-sizing: border-box;

  border-radius: 6px;

  background: ${(p) => genBg(p.baseColor)};

  border-image: ${(p) => genBorder(p.baseColor)};

  &:disabled {
    opacity: 0.4;
  }

  ${button_on_pressed}
  ${(p) => p.mixin}
`;

export const WeakButton = styled.button<{
  baseColor?: string;
  mixin?: RuleSet<Object>;
}>`
  ${CenterContent};
  ${DefaultButtonHeight};
  font-size: 16px;
  color: ${(p) => p.baseColor ?? "white"};
  font-style: normal;
  font-weight: 400;
  position: relative;

  box-sizing: border-box;
  border-radius: 6px;

  border-width: 1px;
  border-style: solid;
  border-color: ${(p) => colorSetAlpha(p.baseColor ?? "white", 0.5)};

  background: transparent;

  &:disabled {
    opacity: 0.4;
  }

  ${button_on_pressed}
  ${(p) => p.mixin}
`;

export const PlaceholderButton = styled.button<{ mixin?: RuleSet<Object> }>`
  ${CenterContent};
  min-height: 56px;
  padding: 0 20px;
  font-size: 16px;
  color: ${(p) => colorSetAlpha("white", 0.7)};
  font-style: normal;
  font-weight: 400;
  position: relative;
  box-sizing: border-box;

  border-color: rgba(255, 255, 255, 0.3);
  border-radius: 6px;
  border-style: dashed;
  background: rgba(255, 255, 255, 0.1);

  &:disabled {
    opacity: 0.4;
  }

  ${button_on_pressed}
  ${(p) => p.mixin}
`;

const CampaignButtonCommon = css`
  color: rgba(137, 249, 232, 1);
  box-sizing: border-box;
  border-radius: 8px;

  background: linear-gradient(
    180deg,
    rgba(137, 249, 232, 0.12) 0%,
    rgba(250, 203, 123, 0.12) 100%
  );

  border-image: ${linear_gradient_border(
    8,
    1,
    -90,
    ["rgba(137, 249, 232, 1)", 0],
    ["rgba(250, 203, 123, 1)", 1],
  )};

  &:disabled {
    opacity: 0.4;
  }

  & > div {
    ${two_line_text};
    text-shadow: 0 0 4px rgba(207, 143, 247, 0.44);
    background: linear-gradient(
      180deg,
      rgba(137, 249, 232, 1) 0%,
      rgba(250, 203, 123, 1) 100%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  & > img {
    width: 24px;
    height: 24px;
  }
`;

export const CampaignLargeMainButton = styled.button<{
  mixin?: RuleSet<Object>;
}>`
  ${CenterContent};
  ${DefaultButtonHeight};
  gap: 2px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  position: relative;

  ${CampaignButtonCommon};
  ${button_on_pressed}
  ${(p) => p.mixin}
`;

export const CampaignMainButton = styled.button<{ mixin?: RuleSet<Object> }>`
  ${CenterContent};
  min-height: 32px;
  gap: 2px;
  padding: 0 20px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  position: relative;
  min-width: 0;

  ${CampaignButtonCommon}

  ${button_on_pressed}
  ${(p) => p.mixin}
`;

export const CampaignCustomButton = styled.button<{
  color: string | undefined;
  mixin?: RuleSet<Object>;
}>`
  ${CenterContent};
  min-height: 32px;
  gap: 2px;
  padding: 0 20px;
  font-size: 12px;
  color: ${(p) => p.color ?? "white"};
  font-style: normal;
  font-weight: 500;
  position: relative;
  min-width: 0;

  box-sizing: border-box;

  & > div {
    ${two_line_text};
  }

  border-radius: 8px;

  background: transparent;

  border-style: solid;
  border-width: 1px;
  border-color: ${(p) => p.color ?? "white"};

  & > img {
    width: 24px;
    height: 24px;
  }

  &:disabled {
    opacity: 0.4;
  }

  ${button_on_pressed}
  ${(p) => p.mixin}
`;

export const LinkButton = styled.div<{ underline?: boolean }>`
  font-size: 14px;
  text-align: center;
  color: rgba(40, 197, 243, 1);
  text-decoration: ${(p) => (p.underline === false ? "none" : "underline")};
  box-sizing: border-box;
  ${alpha_on_pressed}
`;
