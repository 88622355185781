import { Env } from "../../bridge/Env";
import { useEffect, useId, useState } from "react";
import { LoadState, LoadStateKind } from "../LoadState";
import { andLog } from "../../components/handleError";
import {
  JobCancelled,
  shouldFetch,
  SWRConfig,
  SWRJob,
  WebHostCondition,
} from "./SWRJob";
import { flattenStateId, StateId } from "../StateId";
import { useWebHost } from "../useBridge";
import { SWRStore } from "../../utils/DB";
import { AlignUndefined } from "../../utils/Nullable";

const repoMap = new Map<string, SWRRepo<any, any>>();

function ensureRepo<T, C>(
  contentId: string,
  env: Env,
  initialContent: T,
  initialCursor: C,
  fetcher: (prev: T, cursor: C) => Promise<SWRFetchResult<T, C>>,
  config?: SWRConfig,
) {
  const id = flattenStateId(contentId);
  const repo = repoMap.get(id);
  if (repo) return repo as SWRRepo<T, C>;

  const newRepo = new SWRRepo<T, C>(
    contentId,
    env,
    initialContent,
    initialCursor,
    fetcher,
    config,
  );
  repoMap.set(id, newRepo);
  return newRepo;
}

function ensureSubscription<T, C>(
  subscriberId: string,
  repo: SWRRepo<T, C>,
  subscriber: SWRRepoSubscriber<T, C>,
) {
  if (!repo.hasSubscribed(subscriberId)) {
    repo.subscribe(subscriberId, subscriber);
  }

  repoMap.forEach((r, repoId) => {
    if (repoId !== repo.contentId) {
      if (r.hasSubscribed(subscriberId)) {
        r.unsubscribe(subscriberId);
      }
    }
  });
}

export type SWRRepoData<T, C> = {
  readonly content: T;
  readonly source: "init" | "fetch" | "fill";
  readonly updatedAt: number;
  readonly updatedMoreAt?: number;
  readonly cursor: C;
  readonly hasMore: boolean;
};

export type SWRFetchResult<T, C> = {
  readonly content: T;
  readonly cursor: C;
  readonly hasMore: boolean;
};

interface SWRRepoSubscriber<T, C> {
  onDataChange(data: SWRRepoData<T, C>): void;

  onLoadStateChange(loadState: LoadState | undefined): void;
}

class SWRRepo<T, C> {
  private store: SWRStore<SWRRepoData<T, C>>;
  private subscribers: Map<string, SWRRepoSubscriber<T, C>>;
  private config: Required<SWRConfig>;

  private loadState: LoadState | undefined;
  private data: SWRRepoData<T, C>;

  constructor(
    readonly contentId: string,
    env: Env,
    private readonly initialContent: T,
    private readonly initialCursor: C,
    private readonly fetcher: (
      prev: T,
      cursor: C,
    ) => Promise<SWRFetchResult<T, C>>,
    config?: SWRConfig,
  ) {
    this.store = new SWRStore("SWRStoreEntry_" + contentId, env);
    this.subscribers = new Map();
    this.data = {
      content: initialContent,
      source: "init",
      updatedAt: new Date().getTime(),
      cursor: initialCursor,
      hasMore: false,
    };

    this.config = {
      ignoreCacheWhen: config?.ignoreCacheWhen ?? WebHostCondition.Never,
      fetchInitWhen: config?.fetchInitWhen ?? WebHostCondition.NewWebHost,
    };

    this.loadInit().catch(andLog);
  }

  private loadingJob: SWRJob | undefined;

  startJob() {
    const job = new SWRJob();
    this.loadingJob?.cancel();
    this.loadingJob = job;

    return job;
  }

  endJob() {
    this.loadingJob = undefined;
  }

  private setData(data: SWRRepoData<T, C>) {
    this.data = data;
    this.subscribers.forEach((r) => r.onDataChange(data));
  }

  private setLoadState(loadState: LoadState | undefined) {
    if (loadState?.kind === "loadFailed") {
      console.error(loadState.error);
    }
    this.loadState = loadState;
    this.subscribers.forEach((r) => r.onLoadStateChange(loadState));
  }

  subscribe(id: string, subscriber: SWRRepoSubscriber<T, C>) {
    if (this.subscribers.get(id) === subscriber) return;

    subscriber.onDataChange(this.data);
    subscriber.onLoadStateChange(this.loadState);
    this.subscribers.set(id, subscriber);
  }

  unsubscribe(id: string) {
    this.subscribers.delete(id);
  }

  hasSubscribed(id: string) {
    return this.subscribers.has(id);
  }

  private async refetch(job: SWRJob) {
    const fetchResult = await job.run(() =>
      this.fetcher(this.initialContent, this.initialCursor),
    );
    const data: SWRRepoData<T, C> = {
      content: fetchResult.content,
      source: "fetch",
      updatedAt: new Date().getTime(),
      cursor: fetchResult.cursor,
      hasMore: fetchResult.hasMore,
    };
    this.setData(data);
    await job.run(() => this.store.put(data));
  }

  async loadInit() {
    const job = this.startJob();

    this.setLoadState({ kind: LoadStateKind.loading, reason: "init" });

    try {
      const storeResult = await job.runIf(
        this.config.ignoreCacheWhen !== WebHostCondition.Always,
        () => this.store.get(),
      );
      if (storeResult) {
        if (
          this.config.ignoreCacheWhen === WebHostCondition.NewWebHost &&
          !storeResult.isSameWebHost
        ) {
          // ignore
        } else {
          this.setData(storeResult.content);
        }
      }

      if (shouldFetch(storeResult, this.config.fetchInitWhen)) {
        await this.refetch(job);
      }

      this.endJob();
      this.setLoadState({ kind: LoadStateKind.loaded });
    } catch (e) {
      if (!(e instanceof JobCancelled)) {
        this.endJob();
        this.setLoadState({ kind: LoadStateKind.loadFailed, error: e });
      }
    }
  }

  async reload(reason?: string) {
    const job = this.startJob();

    this.setLoadState({ kind: LoadStateKind.loading, reason: reason });
    try {
      await this.refetch(job);

      this.endJob();
      this.setLoadState({ kind: LoadStateKind.loaded });
    } catch (e) {
      if (!(e instanceof JobCancelled)) {
        this.endJob();
        this.setLoadState({ kind: LoadStateKind.loadFailed, error: e });
      }
    }
  }

  async loadMore() {
    if (this.loadingJob) {
      return;
    }

    const job = this.startJob();

    try {
      if (this.data.hasMore) {
        this.setLoadState({ kind: LoadStateKind.loading });
        const fetchResult = await job.run(() =>
          this.fetcher(this.data.content, this.data.cursor),
        );

        const data: SWRRepoData<T, C> = {
          content: fetchResult.content,
          source: this.data.source,
          updatedAt: this.data.updatedAt,
          cursor: fetchResult.cursor,
          hasMore: fetchResult.hasMore,
          updatedMoreAt: new Date().getTime(),
        };
        this.setData(data);
        await job.run(() => this.store.put(data));
      }

      this.endJob();
      this.setLoadState({ kind: LoadStateKind.loaded });
    } catch (e) {
      if (!(e instanceof JobCancelled)) {
        this.endJob();
        this.setLoadState({ kind: LoadStateKind.loadFailed, error: e });
      }
    }
  }

  async fill(content: T, cursor: C, hasMore: boolean) {
    const job = this.startJob();
    this.setLoadState({ kind: LoadStateKind.loading });

    try {
      const data: SWRRepoData<T, C> = {
        content: content,
        source: "fill",
        updatedAt: new Date().getTime(),
        cursor: cursor,
        hasMore: hasMore,
      };

      this.setData(data);
      await job.run(() => this.store.put(data));

      this.endJob();
      this.setLoadState({ kind: LoadStateKind.loaded });
    } catch (e) {
      if (!(e instanceof JobCancelled)) {
        this.endJob();
        this.setLoadState({ kind: LoadStateKind.loadFailed, error: e });
      }
    }
  }
}

type SWRRepoResult<T, C, ID extends StateId | undefined> = AlignUndefined<
  {
    readonly data: SWRRepoData<T, C>;
    readonly loadState: LoadState | undefined;
    readonly repo: SWRRepo<T, C>;
  },
  ID
>;

export function useSWRRepo<T, C, ID extends StateId | undefined = StateId>(
  contentId: ID,
  initialContent: T,
  initialCursor: C,
  fetcher: (prev: T, cursor: C) => Promise<SWRFetchResult<T, C>>,
  config?: SWRConfig,
): SWRRepoResult<T, C, ID> {
  const webHost = useWebHost();
  const subscriberId = useId();
  const [data, setData] = useState<SWRRepoData<T, C>>({
    content: initialContent,
    source: "init",
    updatedAt: new Date().getTime(),
    cursor: initialCursor,
    hasMore: false,
  });
  const [loadState, setLoadState] = useState<LoadState>();

  const repo =
    contentId === undefined
      ? undefined
      : ensureRepo<T, C>(
          flattenStateId(contentId),
          webHost.getEnv(),
          initialContent,
          initialCursor,
          fetcher,
          config,
        );

  useEffect(() => {
    if (repo) {
      ensureSubscription(subscriberId, repo, {
        onDataChange(data: SWRRepoData<T, C>) {
          console.log("onDataChange", data);
          setData(data);
        },
        onLoadStateChange(loadState: LoadState | undefined) {
          setLoadState(loadState);
        },
      });
    }
    return () => {
      repo?.unsubscribe(subscriberId);
    };
  }, [repo, subscriberId, setData, setLoadState]);

  if (repo) return { data, loadState, repo };
  else return undefined as SWRRepoResult<T, C, ID>;
}
