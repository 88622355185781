import React from "react";
import pageBg from "../../res/images/user_upgrade_success_bg.svg";
import { PageRoot } from "../../components/PageRoot";

export function PageBgDemoPage() {
  return (
    <PageRoot
      pageData={undefined}
      background={`url("${pageBg}") center top/contain no-repeat var(--color-bg)`}
    ></PageRoot>
  );
}
