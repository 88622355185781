import React, { ReactElement } from "react";

export interface PageRootContext {
  hasPageRoot: boolean;
  addModal: (modal: ReactElement) => void;
  removeModal: (modal: ReactElement) => void;

  addHeader: (header: ReactElement) => void;
  removeHeader: (header: ReactElement) => void;

  addFooter: (footer: ReactElement) => void;
  removeFooter: (footer: ReactElement) => void;
}

export const PageRootContext = React.createContext<PageRootContext>({
  hasPageRoot: false,
  addModal: (modal: ReactElement) => {},
  removeModal: (modal: ReactElement) => {},

  addHeader: (header: ReactElement) => {},
  removeHeader: (header: ReactElement) => {},
  addFooter: (footer: ReactElement) => {},
  removeFooter: (footer: ReactElement) => {},
});
