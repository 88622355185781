import React from "react";
import { useSafeAreaInsets } from "../../hooks/useSafeAreaInsets";
import { useWebHost } from "../../hooks/useBridge";
import { DemoCell } from "./DemoCell";
import { PageRoot } from "../../components/PageRoot";

export function HooksDemoPage() {
  const safeAreaInsets = useSafeAreaInsets();
  const webHost = useWebHost();

  return (
    <PageRoot pageData={undefined}>
      <DemoCell onClick={() => webHost.openBridge(`web-host-state-demo`)}>
        useWebHostState
      </DemoCell>

      <DemoCell onClick={() => webHost.openBridge(`count-down-demo`)}>
        useCountDown
      </DemoCell>
    </PageRoot>
  );
}
