import {
  Children,
  cloneElement,
  createElement,
  Fragment,
  isValidElement,
  PropsWithChildren,
  ReactElement,
  useEffect,
  useState,
} from "react";
import { sprintf } from "sprintf-js";
import { getStringByLocale } from "../res/strings/R.strings";
import { pickLocale } from "locale-matcher";
import { unreachable } from "../utils/asserts";

export function useCurrentLanguage() {
  // State to hold the current language
  const [language, setLanguage] = useState(navigator.language);

  useEffect(() => {
    // Handler to update the language state
    const handleLanguageChange = () => {
      setLanguage(navigator.language);
    };

    // Add event listener for language changes
    window.addEventListener("languagechange", handleLanguageChange);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("languagechange", handleLanguageChange);
    };
  }, []);

  return language;
}

type ValueType<T> = T extends Map<any, infer V> ? V : never;
export type I18n = ReturnType<ValueType<typeof getStringByLocale>>;

function tagI18n<T extends object>(flag: string, wrapped: T): T {
  return new Proxy(wrapped, {
    get: function (target, prop, receiver) {
      const original: any = target[prop as keyof T];

      if (typeof original === "function") {
        return function (this: typeof wrapped, ...args: any[]) {
          const res = (original as any).apply(this, args as any);
          if (prop === "plural") {
            return tagI18n(flag, res);
          } else {
            if (typeof res === "string") {
              if (res[0] === "-" || res[0] === "#") return res + flag;
              else return flag + res;
            } else if (isValidElement<PropsWithChildren>(res)) {
              return cloneElement(res, undefined, [
                [...Children.toArray(res.props.children), flag],
              ]);
            } else {
              unreachable();
            }
          }
        };
      } else {
        return original;
      }
    },
  }) as T;
}

function splitFormatString(formatString: string): string[] {
  // Regular expression to match format indicators
  const formatIndicatorRegex = /%(\d+\$)?[sd]/g;

  // Array to hold the result
  const result: string[] = [];

  // Start index for the next string fragment
  let lastIndex = 0;

  // Iterate over all matches
  let match;
  while ((match = formatIndicatorRegex.exec(formatString)) !== null) {
    // Add the string fragment before the current match
    if (match.index > lastIndex) {
      result.push(formatString.substring(lastIndex, match.index));
    }

    // Add the current format indicator
    result.push(match[0]);

    // Update the last index
    lastIndex = formatIndicatorRegex.lastIndex;
  }

  // Add the remaining string fragment after the last match
  if (lastIndex < formatString.length) {
    result.push(formatString.substring(lastIndex));
  }

  return result;
}

export type I18nReactElement = {
  __i18nBrand: "I18nReactElement";
} & ReactElement;

export function reactPrintF(
  template: string,
  ...args: (ReactElement | string | number)[]
): I18nReactElement {
  const children = splitFormatString(template).map((s, i) => {
    if (s.startsWith("%") && !s.startsWith("%%")) {
      const index = s.includes("$")
        ? parseInt(s.substring(1).split("$")[0]) - 1
        : Math.floor(i / 2);
      return args[index];
    } else {
      return s;
    }
  });
  return {
    __i18nBrand: "I18nReactElement",
    ...createElement(Fragment, null, ...children),
  };
}

function buildStrOrComponent(
  template: string,
  ...args: (ReactElement | string | number)[]
): I18nReactElement | string {
  if (args.find((arg) => isValidElement(arg)) !== undefined) {
    return reactPrintF(template, ...args);
  } else {
    return sprintf(template, ...args);
  }
}

export const kFlags = new Map([
  ["ru-RU", "🇷🇺"],
  ["ja", "🇯🇵"],
  ["ar", "🇸🇦"],
  ["pt-BR", "🇵🇹"],
  ["es-419", "🇪🇸"],
  ["en-US", "🇺🇸"],
]);

function i18nFromLang(lang: string): I18n {
  const matched = pickLocale(
    lang,
    Array.from(getStringByLocale.keys()),
    "en-US",
  );
  const i18n = getStringByLocale.get(matched)!(buildStrOrComponent);
  if (process.env.NODE_ENV === "development") {
    const flag = kFlags.get(matched) ?? "🌎";
    return tagI18n(flag, i18n);
  } else {
    return i18n;
  }
}

export function useI18n() {
  const language = useCurrentLanguage();
  const [i18n, setI18n] = useState(i18nFromLang(navigator.language));
  useEffect(() => {
    setI18n(i18nFromLang(language));
  }, [language]);

  return i18n;
}
