import BigNumber from "bignumber.js";
import { AlignUndefined } from "./Nullable";

export function getNumberFormatConfig(
  locale: ConstructorParameters<typeof Intl.NumberFormat>[0],
): {
  decimalSeparator: string;
  groupSeparator?: string;
  groupSize?: number;
  secondaryGroupSize?: number;
} {
  const sample = 111111111.111111;
  const parts = new Intl.NumberFormat(locale).formatToParts(sample).reverse();

  const decIndex = parts.findIndex((part) => part.type === "decimal")!;
  const groupIndex = parts.findIndex((part) => part.type === "group");
  if (groupIndex === undefined) {
    return {
      decimalSeparator: parts[decIndex].value,
    };
  }

  let groupSize: number | undefined;
  let secGroupSize: number | undefined;
  for (let i = decIndex + 1; i < parts.length; ++i) {
    switch (parts[i].type) {
      case "integer":
        if (groupSize === undefined) {
          groupSize = parts[i].value.length;
        } else if (secGroupSize === undefined) {
          secGroupSize = parts[i].value.length;
        }
        break;
    }
  }

  return {
    decimalSeparator: parts[decIndex].value,
    groupSeparator: parts[groupIndex].value,
    groupSize: groupSize!,
    secondaryGroupSize: secGroupSize === groupSize ? undefined : secGroupSize,
  };
}

export function getCurrentDecimalSeparator() {
  return new BigNumber("1.1").toFormat().split("1").join("");
}

export function getCurrentGroupSeparator() {
  return new BigNumber("1111").toFormat().split("1").join("");
}

function bigNumberToFormat(
  amount: BigNumber,
  decimalPlaces: number | undefined,
  keepTrailingZeros: boolean | undefined,
) {
  if (decimalPlaces === undefined) {
    return amount.toFormat();
  } else {
    const str = amount.toFixed(decimalPlaces, BigNumber.ROUND_DOWN);
    if (keepTrailingZeros) return str;
    const clean = new BigNumber(str);
    return clean.toFormat();
  }
}

function getLeadingZerosCount(absAmount: number) {
  if (absAmount >= 1 || absAmount <= 0) {
    throw new Error(
      `Input must be a fractional number between 0 and 1 (exclusive), but it is ${absAmount}`,
    );
  }
  let count = 0;
  let itr = absAmount;
  while (itr < 1) {
    itr *= 10;
    count++;
  }

  return count - 1;
}

export function formatBigNumberImpl(
  amount: BigNumber,
  config: {
    kmb?: boolean;
    decimalPlaces?: number;
    decimalPlacesWhenLessThanZero?: number;
    keepTrailingZeros?: boolean;
    compressLeadingZeros?: boolean;
  },
): string {
  const absAmount = amount.abs();
  if (config.kmb && absAmount.isGreaterThanOrEqualTo(1e9)) {
    return (
      bigNumberToFormat(
        amount.dividedBy(1e9),
        config.decimalPlaces,
        config.keepTrailingZeros,
      ) + "B"
    );
  } else if (config.kmb && absAmount.isGreaterThanOrEqualTo(1e6)) {
    return (
      bigNumberToFormat(
        amount.dividedBy(1e6),
        config.decimalPlaces,
        config.keepTrailingZeros,
      ) + "M"
    );
  } else if (config.kmb && absAmount.isGreaterThanOrEqualTo(1e3)) {
    return (
      bigNumberToFormat(
        amount.dividedBy(1e3),
        config.decimalPlaces,
        config.keepTrailingZeros,
      ) + "K"
    );
  } else if (absAmount.isGreaterThanOrEqualTo(1)) {
    return bigNumberToFormat(
      amount,
      config.decimalPlaces,
      config.keepTrailingZeros,
    );
  } else if (absAmount.isZero()) {
    return amount.toFormat();
  } else {
    const leadingZeros = getLeadingZerosCount(absAmount.toNumber());
    if (leadingZeros < 3 || !config.compressLeadingZeros) {
      return bigNumberToFormat(
        amount,
        config.decimalPlacesWhenLessThanZero
          ? (config.compressLeadingZeros ? leadingZeros : 0) +
              config.decimalPlacesWhenLessThanZero
          : undefined,
        config.keepTrailingZeros,
      );
    } else {
      const temp = amount.multipliedBy(`1${"0".repeat(leadingZeros)}`);
      const str = bigNumberToFormat(
        temp,
        config.decimalPlacesWhenLessThanZero,
        config.keepTrailingZeros,
      );
      const decSep = getCurrentDecimalSeparator();
      return str.replace(
        decSep,
        `${decSep}0` + String.fromCharCode(0x2080 + leadingZeros),
      );
    }
  }
}

export function formatNumber<N extends number | undefined>(
  num: N,
  decimalPlaces?: number,
): AlignUndefined<string, N> {
  if (num !== undefined) {
    return formatBigNumberImpl(new BigNumber(num), {
      decimalPlaces: decimalPlaces,
      decimalPlacesWhenLessThanZero: decimalPlaces,
      keepTrailingZeros: false,
      compressLeadingZeros: false,
    });
  } else {
    return undefined as any;
  }
}

export function formatNumberForEdit<N extends number | undefined>(
  num: N,
): AlignUndefined<string, N> {
  if (num !== undefined) {
    return formatBigNumberForEdit(new BigNumber(num));
  } else {
    return undefined as any;
  }
}

export function formatBigNumberForEdit<B extends BigNumber | undefined>(
  num: B,
): AlignUndefined<string, B> {
  if (num !== undefined) {
    return formatBigNumberImpl(num, {
      keepTrailingZeros: true,
    })
      .split(getCurrentGroupSeparator())
      .join("");
  } else {
    return undefined as any;
  }
}
