import React from "react";
import { VStack } from "../../components/VStack";
import styled from "styled-components";
import { useI18n } from "../../hooks/useI18n";
import { PageRoot } from "../../components/PageRoot";
import { Image } from "../../components/Image";

const Title = styled.div`
  font-size: 40px;
  font-weight: 500;
  color: var(--color-text00);
`;

const BuildLabel = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: var(--color-text10);
`;

export function AppHomePage() {
  const i18n = useI18n();

  const gitCommitCount = process.env.REACT_APP_GIT_COMMIT_COUNT;

  return (
    <PageRoot pageData={undefined} scrollDisabled={true}>
      <VStack style={{ height: "80%", justifyContent: "center" }}>
        <Title>{i18n.app_name_projz_z()}</Title>
        <BuildLabel>V. {gitCommitCount}</BuildLabel>
      </VStack>
    </PageRoot>
  );
}
