import { JSONUtil } from "../utils/JSONUtil";

export type Rejectable = {
  name: string;
  message: string;
  apiCode?: number;
  debugMessage?: string;
  redirectUrl?: string;
};

export const isRejectable = (value: any): value is Rejectable => {
  return !!(value.name && value.message);
};

export const isError = (value: any): value is Error => {
  return !!(value.name && value.message);
};

export function getErrorMsg(e: unknown): string {
  if (isRejectable(e) || isError(e)) {
    return e.message;
  } else {
    return JSONUtil.stringify(e);
  }
}

export enum APIErrorCode {
  NEED_UPGRADE_USER_LEVEL_WHEN_CREATE_CAMPAIGNS = 3051,
  NEED_UPGRADE_USER_LEVEL_WHEN_CLAIM_REWARDS = 3052,
  UNLOCK_FAILED = 3054,
}
