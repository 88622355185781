import {
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJs,
  ChartData,
  ChartOptions,
  ChartType,
  DefaultDataPoint,
  Legend,
  LinearScale,
  PieController,
} from "chart.js";
import { useEffect, useState } from "react";
import { CenterChild, HStack, VStack } from "./VStack";
import styled from "styled-components";
import { hMarginWithPageInset } from "./CommonStyles";
import { JSONUtil } from "../utils/JSONUtil";

ChartJs.register(
  PieController,
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  LinearScale,
  Legend,
);

function ChartImpl(props: {
  type: ChartType;
  data: ChartData<ChartType, DefaultDataPoint<ChartType>, unknown>;
  options?: ChartOptions;
}) {
  const [canvasElement, setCanvasElement] = useState<HTMLCanvasElement | null>(
    null,
  );

  useEffect(() => {
    let chart: ChartJs<ChartType> | null = null;
    if (canvasElement) {
      chart = new ChartJs(canvasElement, {
        type: props.type,
        data: props.data,
        options: props.options,
      });
    }
    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [canvasElement, props.type, props.data]);

  return <canvas ref={(e) => setCanvasElement(e)}></canvas>;
}

const Box = styled.div`
  border-radius: 2px;
  width: 6px;
  height: 6px;
`;

const Title = styled.div`
  color: var(--color-text20);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
`;

const Subtitle = styled.div`
  color: var(--color-text00);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
`;

export function LegendLabel(props: {
  boxColor: string;
  title: string;
  subtitle: string;
}) {
  return (
    <HStack style={{ gap: 4, alignItems: "start" }}>
      <Box style={{ backgroundColor: props.boxColor, marginTop: 5 }} />
      <VStack style={{ alignItems: "start" }}>
        <Title>{props.title}</Title>
        <Subtitle>{props.subtitle}</Subtitle>
      </VStack>
    </HStack>
  );
}

export function Chart(props: {
  type: "doughnut" | "bar";
  data: {
    value: number;
    title: string;
    subtitle: string;
    color: string;
  }[];
}) {
  return (
    <HStack
      style={{ justifyContent: "", ...hMarginWithPageInset, width: "100%" }}
    >
      <div style={{ width: "45%" }}>
        <ChartImpl
          type={props.type}
          data={{
            labels: props.data.map((d) => d.title),
            datasets: [
              {
                backgroundColor: props.data.map((d) => d.color),
                data: props.data.map((d) => d.value),
                borderWidth: 0,
                borderRadius: props.type === "doughnut" ? 0 : 6,
              },
            ],
          }}
          options={{
            scales: {
              x: {
                display: false,
              },
              y: {
                grid: {
                  display: props.type === "bar",
                  color: (ctx) =>
                    ctx.tick.value === 0
                      ? "rgba(255, 255, 255, 0.2)"
                      : "transparent",
                },
                display: true,
                ticks: { display: false },
              },
            },
            plugins: {
              legend: {
                display: false,
              },
            },
            animation: {
              duration: 0, // 将动画持续时间设为0以禁用动画
            },
          }}
        />
      </div>
      <CenterChild style={{ width: "55%" }}>
        <VStack style={{ alignItems: "start", gap: 12 }}>
          {props.data.map((d) => (
            <LegendLabel
              key={JSONUtil.stringify(d)}
              boxColor={d.color}
              title={d.title}
              subtitle={d.subtitle}
            />
          ))}
        </VStack>
      </CenterChild>
    </HStack>
  );
}
