import React from "react";
import { PageRoot } from "../../components/PageRoot";
import { range } from "../../utils/List";
import { DemoCell } from "./DemoCell";
import { PageFooter } from "../../components/PageHeaderFooter";
import { RegularButton } from "../../components/Buttons";
import { RowCenterButton } from "../../components/CommonStyles";
import { useSafeAreaInsets } from "../../hooks/useSafeAreaInsets";

export function PageFooterDemoPage() {
  const safeAreaInsets = useSafeAreaInsets();
  return (
    <PageRoot pageData={undefined}>
      {range(0, 100)
        .map((i) => `data.${i}`)
        .map((d) => (
          <DemoCell key={d}>{d}</DemoCell>
        ))}
      <PageFooter>
        <RegularButton
          style={{
            ...RowCenterButton,
            marginTop: 8,
            marginBottom: safeAreaInsets.bottom + 30,
          }}
        >
          Bottom Button
        </RegularButton>
      </PageFooter>
    </PageRoot>
  );
}
