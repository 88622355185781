import { SpeedTitle, SpeedTitleNote } from "../../components/SpeedTitle";
import { HStack, Spring } from "../../components/VStack";
import React from "react";
import { useSafeAreaInsets } from "../../hooks/useSafeAreaInsets";
import { LinkButton } from "../../components/Buttons";
import { PageRoot } from "../../components/PageRoot";

export function SpeedTitleDemoPage() {
  const safeAreaInsets = useSafeAreaInsets();
  return (
    <PageRoot pageData={undefined}>
      <SpeedTitle>General</SpeedTitle>

      <HStack style={{ marginTop: 32 }}>
        <SpeedTitle>With Action</SpeedTitle>
        <Spring />
        <LinkButton style={{ fontSize: 12 }}>More</LinkButton>
      </HStack>

      <SpeedTitle style={{ marginTop: 32 }}>With Note</SpeedTitle>
      <SpeedTitleNote>This is a note for the Speed Title</SpeedTitleNote>
    </PageRoot>
  );
}
