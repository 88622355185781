/**
 * Work around Safari 14 IndexedDB open bug.
 *
 * Safari has a horrible bug where IDB requests can hang while the browser is starting up. https://bugs.webkit.org/show_bug.cgi?id=226547
 * The only solution is to keep nudging it until it's awake.
 */
async function idbReady(): Promise<void> {
  if (
    navigator.userAgent &&
    navigator.userAgent.includes("iPhone OS 14_") &&
    !!indexedDB.databases
  ) {
    let intervalId: any;

    return new Promise<void>((resolve) => {
      const tryIdb = () => indexedDB.databases().finally(resolve);
      intervalId = setInterval(tryIdb, 200);
      tryIdb();
    }).finally(() => clearInterval(intervalId));
  } else {
    return Promise.resolve();
  }
}

function openDBImpl(
  name: string,
  version: number,
  upgrade: (
    db: IDBDatabase,
    oldVersion: number,
    newVersion: number | null,
  ) => void,
): Promise<IDBDatabase> {
  const request = indexedDB.open(name, version);
  return new Promise((resolve, reject) => {
    request.onupgradeneeded = (event) => {
      console.log("idb open onupgradeneeded", event);
      upgrade(request.result, event.oldVersion, event.newVersion);
    };

    request.onsuccess = (event) => {
      console.log("idb open onsuccess", event);
      resolve(request.result);
    };

    request.onerror = (event) => {
      console.log("idb open onerror", event);
      reject(request.error);
    };
  });
}

export async function openDB(
  name: string,
  version: number,
  upgrade: (
    db: IDBDatabase,
    oldVersion: number,
    newVersion: number | null,
  ) => void,
): Promise<IDBDatabase> {
  await idbReady();
  return openDBImpl(name, version, upgrade);
}

function dbExecute<T>(
  db: IDBDatabase,
  storeName: string,
  mode: IDBTransactionMode,
  action: (store: IDBObjectStore) => IDBRequest<T>,
) {
  const store = db.transaction(storeName, mode).objectStore(storeName);
  const request = action(store);
  return new Promise<T>((resolve, reject) => {
    request.onsuccess = (event) => {
      //console.log("idb onsuccess", event)
      resolve(request.result);
    };

    request.onerror = (event) => {
      console.log("idb onerror", event);
      reject(request.error);
    };
  });
}

export async function dbGet<T>(
  db: IDBDatabase,
  storeName: string,
  query: IDBValidKey | IDBKeyRange,
) {
  const r = await dbExecute(db, storeName, "readonly", (store) =>
    store.get(query),
  );
  if (r) {
    return r as T | null;
  } else {
    return null;
  }
}

export function dbPut<T>(
  db: IDBDatabase,
  storeName: string,
  value: T,
  key?: IDBValidKey,
) {
  return dbExecute(db, storeName, "readwrite", (store) =>
    store.put(value, key),
  );
}

export function dbDelete(
  db: IDBDatabase,
  storeName: string,
  query: IDBValidKey | IDBKeyRange,
) {
  return dbExecute(db, storeName, "readwrite", (store) => store.delete(query));
}

export function dbClear(db: IDBDatabase, storeName: string) {
  return dbExecute(db, storeName, "readwrite", (store) => store.clear());
}
