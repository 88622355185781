import { useEffect, useId, useMemo, useRef, useState } from "react";
import { andLog } from "../components/handleError";
import { useWebHost } from "./useBridge";
import { ScrollSizeObserver } from "scroll-size-observer";

type SavedScroll = {
  scrollHeight: number;
  scrollWidth: number;
  clientHeight: number;
  clientWidth: number;
  scrollTop: number;
  scrollLeft: number;
};

export function useSavedScroll() {
  const elementId = useId();
  const [scroller, setScroller] = useState<HTMLElement | null>(null);
  const hasRestoredRef = useRef<boolean>(false);
  const webHost = useWebHost();

  const observer = useMemo(
    () =>
      new ScrollSizeObserver((entries) =>
        entries.forEach((e) => {
          if (e.target === scroller) {
            saveOrRestore();
          }
        }),
      ),
    [scroller],
  );

  function saveScroll(target: HTMLElement) {
    const newSavedScroll = {
      scrollHeight: Math.floor(target.scrollHeight),
      scrollWidth: Math.floor(target.scrollWidth),
      clientHeight: Math.floor(target.clientHeight),
      clientWidth: Math.floor(target.clientWidth),
      scrollTop: Math.floor(target.scrollTop),
      scrollLeft: Math.floor(target.scrollLeft),
    };
    webHost.setStateValue(newSavedScroll, elementId).catch(andLog);
  }

  function restoreScroll(target: HTMLElement) {
    const savedScroll = webHost.getState<SavedScroll>(elementId);
    if (savedScroll === undefined) {
      hasRestoredRef.current = true;
    } else {
      if (savedScroll.scrollHeight === target.scrollHeight) {
        target.scrollTop = savedScroll.scrollTop;
        hasRestoredRef.current = true;
      }
    }
  }

  function saveOrRestore() {
    if (!scroller) return;
    if (hasRestoredRef.current) {
      saveScroll(scroller);
    } else {
      restoreScroll(scroller);
    }
  }

  useEffect(() => {
    if (!scroller) return;
    observer.observe(scroller);
    return () => {
      observer.disconnect();
    };
  }, [scroller, observer]);

  const timeOut = useRef<any>();

  return {
    scrollRef: setScroller,
    onScroll: (event: React.UIEvent<HTMLElement>) => {
      if (timeOut.current) {
        clearTimeout(timeOut.current);
      }

      timeOut.current = setTimeout(() => {
        saveOrRestore();
      }, 300);
    },
  };
}
