import { SWRGetResult } from "../../utils/DB";

export class JobCancelled extends Error {
  constructor() {
    super("SWR Job cancelled"); // call the parent constructor
    this.name = "JobCancelled"; // set the name property
  }
}

export class SWRJob {
  cancelled = false;

  cancel() {
    this.cancelled = true;
  }

  async run<R>(action: () => Promise<R>) {
    try {
      const r = await action();
      if (this.cancelled) {
        throw new JobCancelled();
      }
      return r;
    } catch (e) {
      throw e;
    }
  }

  async runIf<R>(condition: boolean, action: () => Promise<R>) {
    if (!condition) {
      return null;
    }
    try {
      const r = await action();
      if (this.cancelled) {
        throw new JobCancelled();
      }
      return r;
    } catch (e) {
      throw e;
    }
  }
}

export function shouldFetch<T extends object>(
  contentFromDb: SWRGetResult<T> | null,
  fetchInitWhen: WebHostCondition,
): boolean {
  switch (fetchInitWhen) {
    case WebHostCondition.Never:
      return false;
    case WebHostCondition.NewWebHost:
      return !contentFromDb || !contentFromDb.isSameWebHost;
    case WebHostCondition.Always:
      return true;
    default:
      return true;
  }
}

export type SWRConfig = {
  ignoreCacheWhen?: WebHostCondition;
  fetchInitWhen?: WebHostCondition;
};

export enum WebHostCondition {
  Never,
  NewWebHost,
  Always,
}
