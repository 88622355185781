// This file is generated by xml2ts.py. Don't change


import {ReactElement} from "react";
type I18nReactElement = ReactElement & {__i18nBrand: "I18nReactElement"};
type I18nResult<A> = ReactElement extends A ? I18nReactElement : string;


const pluralRules = new Intl.PluralRules("ja")
export function getString_ja(
  strBuilder: (format: string, ...args: (ReactElement|string|number)[]) => unknown
) {
  return {
    app_name_projz_z: ()=>`Project Z`,
    app_name_spongekit_z: ()=>`SpongeKit`,
    auth_log_out: ()=>`ログアウト`,
    cash_rewards_day_number: <A0 extends ReactElement | number>(arg0: A0)=>strBuilder(`%d日目`, arg0) as I18nResult<A0>,
    clover_migrate_migrated_info: ()=>`Your account has migrated to our new app Clover.Space. Please use Clover.Space to continue enjoying our services. Project Z will be unsupported soon.`,
    clover_migrate_open_clover: ()=>`Update App`,
    clover_migrate_phase_1_info: ()=>`Exciting news! We’re happy to announce that you’re among the first to receive an exclusive invitation to preview our new app, Clover.Space! Your feedback is invaluable to us, and we look forward to hearing your thoughts. Thank you for being part of our journey!`,
    clover_migrate_phase_1_title: ()=>`Clover.Space Preview`,
    clover_migrate_phase_2_info: ()=>`Exciting news! We’re thrilled to introduce our new app - **Clover.Space**. Update the app for a brand new experience. Thank you for being part of our journey!`,
    clover_migrate_phase_2_title: ()=>`Introducing Clover.Space`,
    clover_migrate_phase_3_info_new: ()=>`We’re thrilled to introduce our new app, **Clover.Space**. You need to update the app to continue using our services. Thank you for being part of this journey!`,
    clover_migrate_phase_3_title: ()=>`Introducing Clover.Space`,
    clover_upgrade_app: ()=>`Upgrade App`,
    compose_confirmation: ()=>`編集を終了しますか？`,
    done: ()=>`完了`,
    help_split_appeal: ()=>`申し立て`,
    loading: ()=>`読み込み中…`,
    mars_account_disabled: ()=>`Account Disabled`,
    mars_account_restricted: ()=>`Account Restricted`,
    mars_account_waring: ()=>`Warning`,
    mars_bonus_card_title: <A0 extends ReactElement | number>(arg0: A0)=>strBuilder(`%d DAY STREAK`, arg0) as I18nResult<A0>,
    mars_bonus_missed: ()=>`MISSED`,
    mars_check_gift_detail: ()=>`Check Gift detail>>`,
    mars_cloud_box: ()=>`Cloud Box`,
    mars_journey_rule_desc: ()=>`To get your checkmark for the day, you need to engage in at least 10 rounds of conversation.  A “round” of conversation is defined as a back-and-forth message from each participant.

To clarify:
- Participant A sends a message
- Participant B responds to the message.
This is considered one round of conversation.

Along your voyage, you have 4 checkpoints.  When you reach a checkpoint and have obtained a checkmark each day since the previous checkpoint, you’ll receive a Gift.`,
    mars_journey_rule_title: ()=>`30 Day Friendship Voyage Instructions`,
    mars_mbox_desc: ()=>`The box includes exactly one of the above`,
    mars_mbox_how_to_claim: <A0 extends ReactElement | string, A1 extends ReactElement | string>(arg0: A0, arg1: A1)=>strBuilder(`You need to obtain a checkmark each day from %s to %s to claim the Gift Box.`, arg0, arg1) as I18nResult<A0|A1>,
    mars_mbox_open: ()=>`Open`,
    mars_mystery_boxes: ()=>`Mystery Boxes`,
    mars_mystery_boxes_subtitle: ()=>`From 30 Day Friendship Voyage`,
    mars_palm_box: ()=>`Palm Box`,
    mars_rainbow_box: ()=>`Rainbow Box`,
    mars_see_more: ()=>`See More`,
    mars_sys_msg_center: ()=>`System Message Center`,
    mars_sys_msg_post_disabled: ()=>`Post Disabled`,
    mars_the_day_of: <A0 extends ReactElement | number>(arg0: A0)=>strBuilder(`the Day %d`, arg0) as I18nResult<A0>,
    mars_wave_box: ()=>`Wave Box`,
    meet_now_try_again: ()=>`再度トライ`,
    mod_appeal_view_original_content: ()=>`元々のコンテンツを表示する`,
    moon_ads_explore_memecoins: ()=>`Explore memecoins`,
    moon_ads_get_for_free: <A0 extends ReactElement | string>(arg0: A0)=>strBuilder(`Get %s For Free!`, arg0) as I18nResult<A0>,
    moon_ads_just_earned: <A0 extends ReactElement | string, A1 extends ReactElement | string>(arg0: A0, arg1: A1)=>strBuilder(`%s just earned %s`, arg0, arg1) as I18nResult<A0|A1>,
    moon_ads_market_cap: ()=>`Market Cap`,
    moon_ads_truncate_elipsis: <A0 extends ReactElement | string>(arg0: A0)=>strBuilder(`%s…`, arg0) as I18nResult<A0>,
    no_content_yet: ()=>`まだコンテンツがありません`,
    oct12_text_continue_editing: ()=>`編集を続ける`,
    ok: ()=>`OK`,
    quit: ()=>`終了`,
    retry: ()=>`リトライ`,


    plural: (q: number) => {
      return {
        mars_journey_condition_to_claim: <A0 extends ReactElement | number>(arg0: A0) => {
          const pluralMap = new Map([
            ["one", `You need to obtain a checkmark for %d consecutive day to open the Gift Box`],
            ["other", `You need to obtain a checkmark for %d consecutive days to open the Gift Box`],
          ])
          return strBuilder(pluralMap.get(pluralRules.select(q)) ?? pluralMap.get("other") ?? "missing", arg0)  as I18nResult<A0>;
        },
      };
    }
  }
}