import { SWRConfig } from "./SWRJob";
import { StateId } from "../StateId";
import { omit } from "../../utils/pick";
import { CursorAndSize, PagedList } from "./useSWRArray";
import { useSWRAccum } from "./SWRAccum";

type ContentT<E extends {}, P extends PagedList<E>> = Partial<
  Omit<P, "pagination" | "list">
> &
  Pick<P, "list">;

export function useSWRList<
  E extends {},
  P extends PagedList<E> = PagedList<E>,
  ID extends StateId | undefined = StateId,
>(
  contentId: ID,
  fetcher: (pageParam: CursorAndSize) => Promise<P>,
  config?: SWRConfig & { maxSize?: number },
) {
  return useSWRAccum<ContentT<E, P> | undefined, string | null, ID>(
    contentId,
    undefined,
    null,
    async (prev, cursor) => {
      const page = await fetcher({
        pageToken: cursor,
        size: process.env.NODE_ENV === "development" ? 10 : 30,
      });

      const accumList = prev ? prev.list.concat(page.list) : page.list;
      let newList: E[];
      let hasMore: boolean;

      if (config?.maxSize !== undefined && config.maxSize <= accumList.length) {
        newList = accumList.slice(0, config.maxSize);
        hasMore = false;
      } else {
        newList = accumList;
        hasMore = !!page.pagination.nextPageToken;
      }

      return {
        content: {
          ...omit(page, ["pagination", "list"]),
          list: newList,
        },
        cursor: page.pagination.nextPageToken ?? null,
        hasMore: hasMore,
      };
    },
    config,
  );
}
