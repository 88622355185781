import {
  alpha_on_pressed,
  full_page_width_cell,
  shrink_on_pressed,
} from "../../components/CommonStyles";
import React from "react";
import { useSafeAreaInsets } from "../../hooks/useSafeAreaInsets";
import styled, { RuleSet } from "styled-components";
import { DemoCell, DemoNote } from "./DemoCell";
import { ContentGroup } from "../../components/ContentGroup";
import { HStack, VSpace } from "../../components/VStack";
import { Image } from "../../components/Image";
import ic_energy from "../../res/images/ic_energy.webp";
import { PageRoot } from "../../components/PageRoot";

const ButtonLike = styled.div`
  padding: 20px;
  color: white;
  font-size: 16px;
  text-align: center;
`;

export function PressedEffectDemoPage() {
  const safeAreaInsets = useSafeAreaInsets();

  return (
    <PageRoot pageData={undefined}>
      <DemoNote>cell_on_pressed</DemoNote>
      <DemoCell>Used for row style cell</DemoCell>

      <DemoNote>shrink_on_pressed</DemoNote>

      <ContentGroup mixin={shrink_on_pressed}>
        Used for card style cell
        <VSpace height={100} />
      </ContentGroup>

      <DemoNote>alpha_on_pressed</DemoNote>
      <HStack mixin={alpha_on_pressed}>
        <Image width={30} height={30} src={ic_energy} alt={"energy"} />
        <ButtonLike>Used for anything else</ButtonLike>
      </HStack>
    </PageRoot>
  );
}

const Cell = styled.div<{ mixin: RuleSet<Object> }>`
  color: var(--color-text00);
  font-size: 16px;
  padding: 20px 0;
  ${full_page_width_cell}
  ${(p) => p.mixin}
`;
