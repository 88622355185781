import React from "react";
import { useSafeAreaInsets } from "../../hooks/useSafeAreaInsets";
import { useWebHost } from "../../hooks/useBridge";
import { DemoCell } from "./DemoCell";
import { andLog } from "../../components/handleError";
import { PageRoot } from "../../components/PageRoot";

export function HapticDemoPage() {
  const safeAreaInsets = useSafeAreaInsets();
  const webHost = useWebHost();

  return (
    <PageRoot pageData={undefined}>
      <DemoCell
        onClick={() => {
          webHost.haptic("light").catch(andLog);
        }}
      >
        Light
      </DemoCell>

      <DemoCell
        onClick={() => {
          webHost.haptic("medium").catch(andLog);
        }}
      >
        Medium
      </DemoCell>
      <DemoCell
        onClick={() => {
          webHost.haptic("heavy").catch(andLog);
        }}
      >
        Heavy
      </DemoCell>
      <DemoCell
        onClick={() => {
          webHost.haptic("soft").catch(andLog);
        }}
      >
        Soft
      </DemoCell>
      <DemoCell
        onClick={() => {
          webHost.haptic("rigid").catch(andLog);
        }}
      >
        Rigid
      </DemoCell>
      <DemoCell
        onClick={() => {
          webHost.vibrate().catch(andLog);
        }}
      >
        Vibrate
      </DemoCell>
    </PageRoot>
  );
}
