import { Chart } from "../../components/Chart";
import { PageRoot } from "../../components/PageRoot";

export function ChartDemoPage() {
  return (
    <PageRoot pageData={undefined}>
      <Chart
        type={"bar"}
        data={[
          {
            color: "#F07304",
            title: "Initial Launch",
            subtitle: "50%",
            value: 50,
          },
          {
            color: "#BC0EAB",
            title: "Community",
            subtitle: "25%",
            value: 25,
          },
          {
            color: "#3BDF67",
            title: "DEX LP",
            subtitle: "25%",
            value: 25,
          },
        ]}
      />

      <Chart
        type={"doughnut"}
        data={[
          {
            color: "#F07304",
            title: "Initial Launch",
            subtitle: "50%",
            value: 50,
          },
          {
            color: "#BC0EAB",
            title: "Community",
            subtitle: "25%",
            value: 25,
          },
          {
            color: "#3BDF67",
            title: "DEX LP",
            subtitle: "25%",
            value: 25,
          },
        ]}
      />
    </PageRoot>
  );
}
