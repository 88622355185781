import React from "react";
import { ModalController } from "./Modal";

export interface ModalContext {
  modal: ModalController;
}

export const ModalContext = React.createContext<ModalContext>({
  modal: {} as ModalController,
});
