import { StateId } from "../StateId";
import { SWRFetchResult, SWRRepoData, useSWRRepo } from "./useSWRRepo";
import { SWRConfig } from "./SWRJob";
import { AlignUndefined } from "../../utils/Nullable";
import { andLog } from "../../components/handleError";
import { LoadState } from "../LoadState";
import { omit } from "../../utils/pick";

export type SWRAccumMeta<T, C> = Omit<SWRRepoData<T, C>, "content">;

export type SWRAccum<T, C = string | null> = {
  readonly content: T;
  readonly meta: SWRAccumMeta<T, C>;
  readonly loadState: LoadState | undefined;
  readonly load: (reason?: string) => Promise<void>;
  readonly loadMore: () => void;
};

export function useSWRAccum<
  T,
  C = string | null,
  ID extends StateId | undefined = StateId,
>(
  contentId: ID,
  initialAccum: T,
  initialCursor: C,
  fetcher: (prev: T, cursor: C) => Promise<SWRFetchResult<T, C>>,
  config?: SWRConfig,
): AlignUndefined<SWRAccum<T, C>, ID> {
  const repo = useSWRRepo(
    contentId,
    initialAccum,
    initialCursor,
    fetcher,
    config,
  );

  if (repo === undefined)
    return undefined as AlignUndefined<SWRAccum<T, C>, ID>;

  return {
    content: repo.data.content,
    meta: omit(repo.data, ["content"]),
    loadState: repo.loadState,
    load: async (reason?: string) => await repo.repo.reload(reason),
    loadMore: () => {
      repo.repo.loadMore().catch(andLog);
    },
  };
}
