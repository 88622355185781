import GraphemeSplitter from "grapheme-splitter";
import { I18n } from "../hooks/useI18n";
import { AlignUndefined } from "./Nullable";

const splitter = new GraphemeSplitter();
export function truncate<T extends string | undefined>(
  i18n: I18n,
  str: T,
  count: number,
): AlignUndefined<string, T> {
  if (str !== undefined) {
    const splitted = splitter.splitGraphemes(str);
    if (splitted.length > count) {
      return i18n.moon_ads_truncate_elipsis(splitted.slice(0, count).join(""));
    } else {
      return str;
    }
  } else {
    return undefined as AlignUndefined<string, T>;
  }
}
