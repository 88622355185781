export type PropsFrom<TComponent> =
  TComponent extends React.FC<infer Props>
    ? Props
    : TComponent extends React.Component<infer Props>
      ? Props
      : never;

export function removeOptional<O, R>(fn: (o?: O) => R, o?: O) {
  return () => {
    fn(o);
  };
}
