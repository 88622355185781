import React from "react";
import { useSafeAreaInsets } from "../../hooks/useSafeAreaInsets";
import { LoadStateKind } from "../../hooks/LoadState";
import { useI18n } from "../../hooks/useI18n";
import { PageRoot } from "../../components/PageRoot";

export function NotFoundPage() {
  const safeAreaInsets = useSafeAreaInsets();
  const i18n = useI18n();
  return (
    <PageRoot
      pageData={{
        loadState: {
          kind: LoadStateKind.loadFailed,
          error: Error("Page Not Found"),
        },
        hasContents: false,
        refresh: async (reason?: string) => {
          window.location.reload();
        },
      }}
      scrollDisabled={true}
    ></PageRoot>
  );
}
