export enum LoadStateKind {
  loading = "loading",
  loaded = "loaded",
  loadFailed = "loadFailed",
}

export type Loading = {
  kind: LoadStateKind.loading;
  reason?: string;
};

export type Loaded = {
  kind: LoadStateKind.loaded;
};

export type LoadFailed = {
  kind: LoadStateKind.loadFailed;
  error: unknown;
};

export type LoadState = Loading | Loaded | LoadFailed;

export function AllDoneWithErrorFirst(
  loadStates: (LoadState | undefined)[],
  isError?: (error: unknown) => boolean,
): LoadState | undefined {
  const failed = loadStates.find(
    (ls) =>
      ls?.kind === LoadStateKind.loadFailed && (!isError || isError(ls.error)),
  );
  if (failed !== undefined) return failed;

  const loading = loadStates.find((ls) => ls?.kind === LoadStateKind.loading);
  if (loading !== undefined) return loading;

  const notStarted = loadStates.findIndex((ls) => ls === undefined);
  if (notStarted !== undefined) {
    return undefined;
  }

  return loadStates[0];
}

export function AllDoneWithLoadingFirst(
  loadStates: (LoadState | undefined)[],
  isError?: (error: unknown) => boolean,
): LoadState | undefined {
  const loading = loadStates.find((ls) => ls?.kind === LoadStateKind.loading);
  if (loading !== undefined) return loading;

  const failed = loadStates.find(
    (ls) =>
      ls?.kind === LoadStateKind.loadFailed && (!isError || isError(ls.error)),
  );
  if (failed !== undefined) return failed;

  const notStarted = loadStates.findIndex((ls) => ls === undefined);
  if (notStarted !== undefined) {
    return undefined;
  }

  return loadStates[0];
}
