import { LoadState } from "../../hooks/LoadState";

export type RefreshObject = {
  readonly loadState?: LoadState;
  readonly load: (reason?: string) => Promise<void>;
};

export type RefreshCallback = {
  readonly onRefresh: () => Promise<void>;
  readonly isLoading: boolean;
};

export type RefreshTarget = RefreshObject | RefreshCallback;

export function isRefreshObject(
  refresh: RefreshTarget,
): refresh is RefreshObject {
  return (refresh as any).load;
}

export function isRefreshTargetLoading(target: RefreshTarget) {
  if (isRefreshObject(target)) {
    return (
      target.loadState?.kind === "loading" &&
      target.loadState.reason === "pullToRefresh"
    );
  } else {
    return target.isLoading;
  }
}

export function isAnyRefreshTargetLoading(
  target: RefreshTarget | RefreshTarget[],
) {
  if (Array.isArray(target)) {
    return target.filter((t) => isRefreshTargetLoading(t)).length > 0;
  } else {
    return isRefreshTargetLoading(target);
  }
}

export async function refreshTargetLoad(target: RefreshTarget) {
  if (isRefreshObject(target)) {
    await target.load("pullToRefresh");
  } else {
    await target.onRefresh();
  }
}

export async function refreshTargetLoadAll(
  target: RefreshTarget | RefreshTarget[],
) {
  if (Array.isArray(target)) {
    await Promise.all(
      target.map(async (t) => {
        await refreshTargetLoad(t);
      }),
    );
  } else {
    await refreshTargetLoad(target);
  }
}
