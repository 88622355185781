import {
  CSSProperties,
  PropsWithChildren,
  ReactNode,
  useMemo,
  useState,
} from "react";
import styled, { keyframes } from "styled-components";
import { VStack, VStackMixin } from "./VStack";

export const CellAnimation = keyframes`
  0% {
    transform: translateY(100%);
  }
  10% {
    transform: translateY(0);
  }

  90% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-100%);
  }
`;

const Cell = styled.div`
  ${VStackMixin};
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  animation: ${CellAnimation} 3s ease-out 1 forwards;
`;

export function AutoscrollMsg(
  props: Pick<CSSProperties, "height"> & {
    children?: ReadonlyArray<ReactNode>;
  },
) {
  const [displayingOrder, setDisplayingOrder] = useState(0);

  const displayingChild = useMemo(() => {
    if (props.children?.length) {
      const index = displayingOrder % props.children.length;
      return props.children[index];
    } else {
      return undefined;
    }
  }, [displayingOrder, props.children]);

  return (
    <div
      style={{
        overflow: "hidden",
        position: "relative",
        height: props.height,
      }}
    >
      <Cell
        key={displayingOrder}
        onAnimationEnd={() => setDisplayingOrder((o) => o + 1)}
      >
        {displayingChild}
      </Cell>
    </div>
  );
}
