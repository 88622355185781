import { DependencyList, useEffect } from "react";
import { andLog } from "../components/handleError";
import { I18n, useI18n } from "./useI18n";
import { useNativePage } from "./useBridge";

export function usePageTitle(
  stringFn: (i18n: I18n) => string,
  deps?: DependencyList,
) {
  const nativePage = useNativePage();
  const i18n = useI18n();
  useEffect(() => {
    nativePage.setPageTitle(stringFn(i18n)).catch(andLog);
  }, [i18n, nativePage, ...(deps ?? [])]);
}
