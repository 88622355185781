import { useEffect } from "react";
import { useWebHostState } from "./useWebHostState";

export type SafeAreaInsets = {
  top: number;
  bottom: number;
  imeBottom: number;
};

export function useSafeAreaInsets(): SafeAreaInsets {
  const [safeAreaInsets, setSafeAreaInsets] = useWebHostState(
    "SafeAreaInsets",
    null,
  );
  useEffect(() => {
    function safeAreaInsetsDidChange(evt: any) {
      setSafeAreaInsets(evt.detail);
    }

    window.addEventListener("safeAreaInsetsDidChange", safeAreaInsetsDidChange);
    return () => {
      window.removeEventListener(
        "safeAreaInsetsDidChange",
        safeAreaInsetsDidChange,
      );
    };
  }, []);
  return (
    safeAreaInsets ?? (window as any).safeAreaInsets ?? { top: 0, bottom: 0 }
  );
}
