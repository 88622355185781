import { useEffect, useRef, useState } from "react";

export function useDebounceState<T>(
  delay: number,
  initialValue?: T,
): [T | undefined, (v: T) => void, (v: T) => void] {
  const [value, setValue] = useState(initialValue);
  const valueRef = useRef<T | null>(null);
  const timeOut = useRef<any>(null);

  const debouncedSet = (v: T) => {
    if (timeOut.current) {
      clearTimeout(timeOut.current);
    }

    timeOut.current = setTimeout(() => {
      if (valueRef.current !== v) {
        setValue(v);
        valueRef.current = v;
      }
    }, delay);
  };
  const immediateSet = (v: T) => {
    if (timeOut.current) {
      clearTimeout(timeOut.current);
    }
    timeOut.current = null;
    setValue(v);
  };

  return [value, debouncedSet, immediateSet];
}

export function useChange<T>(
  value: T,
  onChange: (prev: T, current: T) => void,
  onInit?: (value: T) => void,
) {
  const prev = useRef<{ value: T }>();
  useEffect(() => {
    const record = prev.current;
    if (record !== undefined) {
      const prevValue = record.value;
      if (prevValue !== value) {
        prev.current = { value: value };
        onChange(prevValue, value);
      }
    } else {
      prev.current = { value: value };
      if (onInit) {
        onInit(value);
      }
    }
  }, [value]);
}
