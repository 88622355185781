import React from "react";
import { useSafeAreaInsets } from "../../hooks/useSafeAreaInsets";
import { PowerMarkdown } from "../../components/PowerMarkdown";
import { css } from "styled-components";
import { DemoNote } from "./DemoCell";
import { reactPrintF } from "../../hooks/useI18n";
import ic_energy from "../../res/images/ic_energy.webp";
import { Image } from "../../components/Image";
import { PageRoot } from "../../components/PageRoot";

export function PowerMarkdownDemoPage() {
  const safeAreaInsets = useSafeAreaInsets();
  const content = `- Every Circle upgrade will create a Circle Achievement NFT, but the quantity is limited.
- The Circle Achievement NFT is only available to Circle members with high contribution scores. To learn how to increase your contribution score, click here
- The Circle Achievement NFT will be released after this upgrade.`;
  return (
    <PageRoot pageData={undefined}>
      <DemoNote>Plain Text</DemoNote>
      <PowerMarkdown textStyle={markdown_style}>
        {
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc imperdiet, dolor non pulvinar fermentum"
        }
      </PowerMarkdown>

      <DemoNote>Rich Text</DemoNote>
      <PowerMarkdown textStyle={markdown_style}>
        {
          "Lorem *ipsum* dolor **sit** amet, `consectetur` adipiscing elit. Nunc imperdiet, dolor non pulvinar fermentum"
        }
      </PowerMarkdown>

      <DemoNote>Multiline Plain Text</DemoNote>
      <PowerMarkdown textStyle={markdown_style}>
        {
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit.\n\nNunc imperdiet, \n\n\n\ndolor non pulvinar fermentum"
        }
      </PowerMarkdown>

      <DemoNote>Pure List</DemoNote>
      <PowerMarkdown textStyle={markdown_style}>
        {
          "- Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.\n- Nunc imperdiet, \n\n\n\ndolor non pulvinar fermentum"
        }
      </PowerMarkdown>

      <DemoNote>List</DemoNote>
      <PowerMarkdown textStyle={markdown_style}>
        {
          "Lorem ipsum dolor sit amet\n- Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.\n- Nunc imperdiet, \n\n\n\ndolor non pulvinar fermentum"
        }
      </PowerMarkdown>

      <DemoNote>React Markdown</DemoNote>
      <PowerMarkdown textStyle={markdown_style}>
        {reactPrintF(
          "Lorem ipsum %s sit amet\n- Lorem ipsum dolor sit amet, %s adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.\n- Nunc %s imperdiet, \n\n\n\ndolor non pulvinar fermentum",
          <span style={{ color: "red" }}>dolor</span>,
          <span style={{ fontSize: 20, fontWeight: 600, color: "yellow" }}>
            consectetur
          </span>,

          <Image width={30} height={30} src={ic_energy} alt={"energy"} />,
        )}
      </PowerMarkdown>
    </PageRoot>
  );
}

const markdown_style = css`
  color: white;
  font-weight: 300;
  font-size: 16px;
`;
