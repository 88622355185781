import React from "react";
import { useWebHost } from "../../hooks/useBridge";
import { DemoCell } from "./DemoCell";
import { PageRoot } from "../../components/PageRoot";

export function ComponentsDemoPage() {
  const webHost = useWebHost();

  return (
    <PageRoot pageData={undefined}>
      <DemoCell onClick={() => webHost.openBridge(`slider-demo`)}>
        Slider
      </DemoCell>

      <DemoCell onClick={() => webHost.openBridge(`haptic-demo`)}>
        Haptic
      </DemoCell>

      <DemoCell onClick={() => webHost.openBridge(`button-demo`)}>
        Button
      </DemoCell>
      <DemoCell onClick={() => webHost.openBridge(`imageview-demo`)}>
        Image
      </DemoCell>

      <DemoCell onClick={() => webHost.openBridge(`modal-demo`)}>
        Modal
      </DemoCell>

      <DemoCell onClick={() => webHost.openBridge("input-demo")}>
        Input
      </DemoCell>
      <DemoCell onClick={() => webHost.openBridge("chart-demo")}>
        Chart
      </DemoCell>
      <DemoCell onClick={() => webHost.openBridge("content-group-demo")}>
        ContentGroup
      </DemoCell>
      <DemoCell onClick={() => webHost.openBridge("speed-title-demo")}>
        SpeedTitle
      </DemoCell>
      <DemoCell onClick={() => webHost.openBridge("pressed-effect-demo")}>
        Pressed Effect
      </DemoCell>
      <DemoCell onClick={() => webHost.openBridge("instruction-demo")}>
        Instruction
      </DemoCell>
      <DemoCell onClick={() => webHost.openBridge("power-markdown-demo")}>
        Power Markdown
      </DemoCell>
      <DemoCell onClick={() => webHost.openBridge("filter-tab-demo")}>
        Filter Tab
      </DemoCell>
      <DemoCell onClick={() => webHost.openBridge("auto-fit-text-demo")}>
        Auto Fit Text
      </DemoCell>
    </PageRoot>
  );
}
