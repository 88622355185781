function makeQuery(obj: any): string {
  const str = Object.keys(obj)
    .map((key) => {
      const value = obj[key];
      if (value === undefined || value === null) {
        return null;
      } else {
        return `${key}=${encodeURIComponent(value)}`;
      }
    })
    .filter((x) => x)
    .join("&");
  if (str.length > 0) {
    return "?" + str;
  } else {
    return "";
  }
}

export function urlAppendQuery(url: string, obj: object): string {
  return `${url}${makeQuery(obj)}`;
}
