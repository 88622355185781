import { ListPayload, PageParam } from "../../proto/ListPayload";
import { range } from "../../utils/List";
import { useSWRArray } from "../../hooks/swr/useSWRArray";
import { PageRoot } from "../../components/PageRoot";
import { DemoCell } from "./DemoCell";

function getData(pageParam: PageParam) {
  console.log("hello", pageParam.pageToken);
  return new Promise<ListPayload<string>>((resolve, reject) => {
    setTimeout(() => {
      const section = pageParam.pageToken ? parseInt(pageParam.pageToken) : 0;
      const data = range(0, pageParam.size - 1).map((i) => `P${section}.${i}`);
      resolve({
        list: data,
        pagination: {
          nextPageToken: `${section + 1}`,
        },
      });
    }, 500);
  });
}

export function SimpleListDemoPage() {
  const dataSWR = useSWRArray(
    ["SimpleListDemoPage2"],
    (pageParam) => getData(pageParam),
    { maxSize: 30 },
  );

  return (
    <PageRoot pageData={dataSWR}>
      {dataSWR.content.map((d) => (
        <DemoCell key={d} style={{ height: 50 }}>
          {d}
        </DemoCell>
      ))}
    </PageRoot>
  );
}
