import { css } from "styled-components";
import { CSSProperties } from "react";

export const cell_on_pressed = css`
  &:active {
    background: rgba(255, 255, 255, 0.1);
  }
`;

export const shrink_on_pressed = css`
  transition: transform 0.2s ease-in-out;

  &:active {
    transform: scale(0.95) perspective(1px);
  }
`;

/**
 * 关键点:not(:focus-within)
 * 避免子元素的active 影响父元素的style
 */
export const shrink_on_pressed2 = css`
  transition: transform 0.2s ease-in-out;

  &:active:not(:focus-within) {
    transform: scale(0.95) perspective(1px);
  }
`;

export const button_on_pressed = css`
  transition: opacity 0.2s ease-in-out;

  &:active:enabled {
    opacity: 0.3;
  }
`;

export const alpha_on_pressed = css`
  transition: opacity 0.2s ease-in-out;

  &:active {
    opacity: 0.3;
  }
`;

export const cell_bottom_divider = css`
  &:before {
    display: block;
    position: absolute;
    left: var(--page-h-inset);
    right: var(--page-h-inset);
    flex-shrink: 0;
    bottom: 0;
    height: 1px;
    content: "";
    background-color: var(--color-cell-divider);
  }
`;

export const h_margin_with_page_inset = css`
  margin-left: var(--page-h-inset);
  margin-right: var(--page-h-inset);
`;

export const hPaddingWithPageInset: CSSProperties = {
  paddingLeft: "var(--page-h-inset)",
  paddingRight: "var(--page-h-inset)",
};

export const hMarginWithPageInset: CSSProperties = {
  marginLeft: "var(--page-h-inset)",
  marginRight: "var(--page-h-inset)",
};

export const h_padding_with_page_inset = css`
  padding-left: var(--page-h-inset);
  padding-right: var(--page-h-inset);
`;

export const full_page_width_cell = css`
  ${h_padding_with_page_inset};
  margin-left: var(--page-h-inset-neg);
  margin-right: var(--page-h-inset-neg);
`;
export const full_page_width_cell_no_padding = css`
  margin-left: var(--page-h-inset-neg);
  margin-right: var(--page-h-inset-neg);
`;

export const FullPageWidthCell = {
  ...hPaddingWithPageInset,
  marginLeft: "var(--page-h-inset-neg)",
  marginRight: "var(--page-h-inset-neg)",
};

export const full_group_width_cell = css`
  padding-left: var(--group-inset);
  padding-right: var(--group-inset);
  margin-left: var(--group-inset-neg);
  margin-right: var(--group-inset-neg);
`;

export const FullGroupWidthCell = {
  paddingLeft: "var(--group-inset)",
  paddingRight: "var(--group-inset)",
  marginLeft: "var(--group-inset-neg)",
  marginRight: "var(--group-inset-neg)",
};

export const RowCenterButton = {
  marginLeft: "auto",
  marginRight: "auto",
  minWidth: "76%",
};

export const two_line_text = css`
  // limit to 2 lines
  // https://albertwalicki.com/learn/solutions/how-to-limit-text-to-n-lines
  overflow: hidden;
  text-overflow: ellipsis;

  word-wrap: break-word;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
export const accent_font = css``;

// https://caniuse.com/mdn-css_selectors_lang_argument_list
// android webview doesn't support multiple languages in one selector
export const moon_pixel_font = css`
  &:lang(en) {
    font-family: PressStart2P, sans-serif;
  }
  &:lang(es) {
    font-family: PressStart2P, sans-serif;
  }
  &:lang(pt) {
    font-family: PressStart2P, sans-serif;
  }
  &:lang(ru) {
    font-family: PressStart2P, sans-serif;
  }
  &:lang(tr) {
    font-family: PressStart2P, sans-serif;
  }

  &:lang(vi) {
    font-family: HandJet, sans-serif;
  }

  &:lang(ar) {
    font-family: HandJet, sans-serif;
  }
`;

export const moon_pixel_font_size_12 = css`
  &:lang(en) {
    font-size: 12px;
  }
  &:lang(es) {
    font-size: 12px;
  }
  &:lang(pt) {
    font-size: 12px;
  }
  &:lang(ru) {
    font-size: 12px;
  }
  &:lang(tr) {
    font-size: 12px;
  }

  font-size: 18px;
`;
export const moon_pixel_font_weight_400 = css`
  &:lang(en) {
    font-weight: 400;
  }
  &:lang(es) {
    font-weight: 400;
  }
  &:lang(pt) {
    font-weight: 400;
  }
  &:lang(ru) {
    font-weight: 400;
  }
  &:lang(tr) {
    font-weight: 400;
  }

  font-weight: 700;
`;
