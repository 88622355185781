import React, { useMemo } from "react";
import { useSafeAreaInsets } from "../../hooks/useSafeAreaInsets";
import { MonoLabel } from "./DemoCell";
import { useWebHostState } from "../../hooks/useWebHostState";
import { useNavBarEndButton } from "../../hooks/useNavBar";
import { NavButtonType } from "../../bridge/NativePage";
import { button_on_pressed } from "../../components/CommonStyles";
import styled from "styled-components";
import { HStack, VSpace, VStack } from "../../components/VStack";
import { JSONUtil } from "../../utils/JSONUtil";
import { useWebHost } from "../../hooks/useBridge";
import { PageRoot } from "../../components/PageRoot";

const StateDisplay = styled.div`
  min-height: 20px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 4px;
  padding: 12px;
  color: white;
  font-size: 16px;
  font-weight: 400;
`;

const DebugButton = styled.button`
  background: transparent;
  border-radius: 4px;
  border-color: rgba(255, 255, 255, 0.3);
  border-width: 1px;
  border-style: solid;
  padding: 8px 16px;

  color: white;
  font-size: 16px;
  font-weight: 400;

  ${button_on_pressed}
`;

function StringStateCase(props: { value: string }) {
  const [state, setState, clearWebHostState] = useWebHostState<string>(
    ["StringStateCase", props.value],
    props.value,
  );
  return (
    <VStack style={{ gap: 8, alignItems: "stretch" }}>
      <MonoLabel>{`useWebHostState<string> ${props.value}`}</MonoLabel>
      <StateDisplay>{JSONUtil.stringify(state)}</StateDisplay>
      <HStack style={{ justifyContent: "space-between" }}>
        <DebugButton onClick={() => setState((prev) => prev + props.value)}>
          Repeat
        </DebugButton>
        <DebugButton onClick={() => setState(props.value)}>Reset</DebugButton>
        <DebugButton onClick={() => clearWebHostState()}>Clear</DebugButton>
      </HStack>
    </VStack>
  );
}

function StringOrUndefinedStateCase(props: { value: string }) {
  const [state, setState, clearWebHostState] = useWebHostState<string>([
    "StringOrUndefinedStateCase",
    props.value,
  ]);
  return (
    <VStack style={{ gap: 8, alignItems: "stretch" }}>
      <MonoLabel>{`useWebHostState<string|undefined> ${props.value}`}</MonoLabel>
      <StateDisplay>{JSONUtil.stringify(state)}</StateDisplay>
      <HStack style={{ justifyContent: "space-between" }}>
        <DebugButton
          onClick={() =>
            setState((prev) => (prev ? prev + props.value : props.value))
          }
        >
          Repeat
        </DebugButton>
        <DebugButton onClick={() => setState(undefined)}>Reset</DebugButton>
        <DebugButton onClick={() => clearWebHostState()}>Clear</DebugButton>
      </HStack>
    </VStack>
  );
}

function StringOrNullStateCase(props: { value: string }) {
  const [state, setState, clearWebHostState] = useWebHostState<string | null>(
    ["StringOrNullStateCase", props.value],
    null,
  );
  return (
    <VStack style={{ gap: 8, alignItems: "stretch" }}>
      <MonoLabel>{`useWebHostState<string|null> ${props.value}`}</MonoLabel>
      <StateDisplay>{JSONUtil.stringify(state)}</StateDisplay>
      <HStack style={{ justifyContent: "space-between" }}>
        <DebugButton
          onClick={() =>
            setState((prev) => (prev ? prev + props.value : props.value))
          }
        >
          Repeat
        </DebugButton>
        <DebugButton onClick={() => setState(null)}>Reset</DebugButton>
        <DebugButton onClick={() => clearWebHostState()}>Clear</DebugButton>
      </HStack>
    </VStack>
  );
}

export function WebHostStateDemoPage() {
  const webHost = useWebHost();
  const safeAreaInsets = useSafeAreaInsets();

  useNavBarEndButton(
    { type: NavButtonType.DoneTitle, title: "Navigate Away" },
    () => {
      webHost.openBridge(
        `circle-contribute/1778370586329088000/1790633344040656896`,
      );
    },
    [],
  );

  const showFirst = useMemo(() => {
    return Math.round(Math.random()) % 2 === 0;
  }, []);

  return (
    <PageRoot pageData={undefined}>
      <StringStateCase value={"hello"} />

      <VSpace height={40} />
      {showFirst && <StringStateCase value={"a"} />}
      {!showFirst && <StringStateCase value={"b"} />}

      <VSpace height={40} />
      {showFirst && <StringOrUndefinedStateCase value={"c"} />}
      {!showFirst && <StringOrUndefinedStateCase value={"d"} />}

      <VSpace height={40} />
      {showFirst && <StringOrNullStateCase value={"e"} />}
      {!showFirst && <StringOrNullStateCase value={"f"} />}
    </PageRoot>
  );
}
