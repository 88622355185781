import {
  getBestRes,
  getFittestRes,
  getSmallestRes,
  Media,
  Size,
} from "../proto/Media";
import icFailure from "../res/images/ic_broken_img.svg";
import empty_src from "../res/images/empty_src.svg";
import React, { CSSProperties, useMemo, useState } from "react";
import styled from "styled-components";
import { useChange } from "../hooks/ExtHooks";

export type MediaSizeMode = Size | "best" | "smallest" | number;

const DefaultImg = styled.img<{ $background: string }>`
  background: ${(p) => p.$background};
  object-fit: cover;
`;

export function Image(props: {
  width?: string | number | undefined;
  height?: string | number | undefined;
  src: readonly [Media | undefined, MediaSizeMode] | string | undefined;
  style?: CSSProperties;
  alt?: string;
  styledImg?: ReturnType<typeof styled.img>;
  needBg?: boolean;
  onClick?: () => void;
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const url = useMemo(() => {
    if (typeof props.src === "string") {
      if (props.src.length === 0) {
        return empty_src;
      } else {
        return props.src;
      }
    } else if (props.src) {
      const [media, fitMode] = props.src;
      if (media) {
        if (fitMode === "best") {
          return getBestRes(media).url;
        } else if (fitMode === "smallest") {
          return getSmallestRes(media).url;
        } else if (typeof fitMode === "number") {
          return getFittestRes(media, {
            width: fitMode,
            height: fitMode,
          }).url;
        } else {
          return getFittestRes(media, fitMode).url;
        }
      } else {
        return empty_src;
      }
    } else {
      return empty_src;
    }
  }, [props.src]);

  useChange(url, (prev, current) => {
    if (prev !== current) {
      setIsError(false);
      setIsLoading(true);
    }
  });

  const StyledImg = props.styledImg ?? DefaultImg;

  return (
    <StyledImg
      width={props.width}
      height={props.height}
      $background={
        (!url.startsWith("data") &&
          !url.startsWith("/static") &&
          (isLoading || isError)) ||
        props.needBg
          ? "rgba(255, 255, 255, 0.06)"
          : "transparent"
      }
      src={isError ? icFailure : url}
      style={props.style}
      onError={(e) => {
        if (e.currentTarget.src === icFailure) {
          return;
        }

        setIsLoading(false);
        setIsError(true);
      }}
      onLoad={(e) => {
        if (e.currentTarget.src === icFailure) {
          return;
        }

        setIsLoading(false);
        setIsError(false);
      }}
      alt={props.alt ?? "icon"}
      onClick={props.onClick}
    />
  );
}
