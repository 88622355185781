import { getErrorMsg, isRejectable } from "../bridge/Rejectable";
import styled, { keyframes } from "styled-components";
import { I18n } from "../hooks/useI18n";
import { NativePage } from "../bridge/NativePage";

export function handleError(nativePage: NativePage, i18n: I18n, error: any) {
  andLog(error);
  if (isRejectable(error) && error.apiCode) {
    nativePage.alertNotice(getErrorMsg(error), i18n.ok()).catch(andLog);
  } else {
    nativePage.warnHud(getErrorMsg(error)).catch(andLog);
  }
}

const Spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
  border: 5px solid var(--color-text30);
  border-top: 5px solid var(--color-text00);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: ${Spin} 1s linear infinite;
`;

export function andLog(e: any) {
  console.error("Got error but ignore:", getErrorMsg(e));
}
