import { dbDelete, dbGet, dbPut, openDB } from "./dbPromise";
import { JSONUtil } from "./JSONUtil";
import { Env } from "../bridge/Env";

export enum Store {
  SWR = "swr",
}

let dbPromise: Promise<IDBDatabase> | null = null;

export const DB = {
  get(): Promise<IDBDatabase> {
    if (dbPromise) {
      return dbPromise;
    }

    const promise = openDB("TheDB", 1, (db, oldVersion, newVersion) => {
      console.log("upgrade", { o: oldVersion, n: newVersion });
      if (!db.objectStoreNames.contains(Store.SWR)) {
        db.createObjectStore(Store.SWR, { keyPath: ["contentId", "userId"] });
      }
    });

    dbPromise = promise;
    return promise;
  },
};

type SWREntry = {
  content: string;
  contentId: string;
  userId: string;
  webHostId: string;
  updatedAt: Date;
};

export type SWRGetResult<T> = {
  content: T;
  isSameWebHost: boolean;
};

export class SWRStore<T> {
  constructor(
    readonly contentId: string,
    readonly env: Env,
  ) {}

  async put(content: T) {
    const entry = {
      content: JSONUtil.stringify(content),
      contentId: this.contentId,
      userId: this.env.loggedInUserId.toString(),
      webHostId: this.env.webHostId,
      updateAt: new Date(),
    };
    const db = await DB.get();
    await dbPut(db, Store.SWR, entry);
  }

  async delete() {
    const db = await DB.get();
    await dbDelete(db, Store.SWR, [
      this.contentId,
      this.env.loggedInUserId.toString(),
    ]);
  }

  async get(): Promise<SWRGetResult<T> | null> {
    const db = await DB.get();
    try {
      const entry = await dbGet<SWREntry>(db, Store.SWR, [
        this.contentId,
        this.env.loggedInUserId.toString(),
      ]);
      if (entry) {
        return {
          content: JSONUtil.parse(entry.content) as T,
          isSameWebHost: entry.webHostId === this.env.webHostId,
        };
      } else {
        return null;
      }
    } catch (e) {
      console.error("Failed to read", e);
      return null;
    }
  }
}
