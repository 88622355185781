import styled from "styled-components";
import { f_svg_ellipse_arc } from "./SVGUtils";
import React, { useEffect, useMemo, useState } from "react";
import { ChasingCircle } from "./ChasingCircle";
import { useChange } from "../../hooks/ExtHooks";

// from SwipeRefreshLayout.java
const METRIC = {
  large: {
    circleDiameter: 56,
    shadowRadius: 3,
    progress: {
      radius: 11,
      strokeWidth: 3,
      arrowWidth: 12,
      arrowHeight: 6,
    },
    circleTarget: 64,
  },
  default: {
    circleDiameter: 40,
    shadowRadius: 3,
    progress: {
      radius: 7.5,
      strokeWidth: 2.5,
      arrowWidth: 10,
      arrowHeight: 5,
    },
    circleTarget: 64,
  },
};

export type Metric = (typeof METRIC)["default"];

export const Frisbee = styled.div<{ metric: Metric }>`
  width: ${(p) => p.metric.circleDiameter}px;
  height: ${(p) => p.metric.circleDiameter}px;
  border-radius: 50%;
  box-shadow: 0 0 ${(p) => p.metric.shadowRadius}px 0 rgba(0, 0, 0, 0.25);
  box-sizing: border-box;

  background: white;

  position: absolute;
  top: -${(p) => p.metric.shadowRadius * 2 + p.metric.circleDiameter}px;
  left: calc(50% - 20px);

  transform-origin: center;

  display: flex;
  justify-content: center;
  align-items: center;
`;

// copied from SwipeRefreshLayout.java
function calcRefreshControlLayout(metric: Metric, pullDistance: number) {
  const overscrollTop = Math.max(0, pullDistance);

  const mCustomSlingshotDistance = 0;
  const mUsingCustomStart = false;
  const mSpinnerOffsetEnd = metric.circleTarget;
  const mOriginalOffsetTop = 0;

  const mTotalDragDistance = metric.circleTarget;
  const originalDragPercent = overscrollTop / mTotalDragDistance;

  const dragPercent = Math.min(1, Math.abs(originalDragPercent));
  const adjustedPercent = (Math.max(dragPercent - 0.4, 0) * 5) / 3;
  const extraOS = Math.abs(overscrollTop) - mTotalDragDistance;
  const slingshotDist =
    mCustomSlingshotDistance > 0
      ? mCustomSlingshotDistance
      : mUsingCustomStart
        ? mSpinnerOffsetEnd - mOriginalOffsetTop
        : mSpinnerOffsetEnd;
  const tensionSlingshotPercent = Math.max(
    0,
    Math.min(extraOS, slingshotDist * 2) / slingshotDist,
  );
  const tensionPercent =
    (tensionSlingshotPercent / 4 - Math.pow(tensionSlingshotPercent / 4, 2)) *
    2;
  const extraMove = slingshotDist * tensionPercent * 2;

  const targetY =
    mOriginalOffsetTop + (slingshotDist * dragPercent + extraMove);
  return {
    targetY: targetY,
    progress: Math.max(0, Math.min(1, overscrollTop / mTotalDragDistance)),
    rotationAngle: (-0.25 + 0.4 * adjustedPercent + tensionPercent * 2) * 0.5,
  };
}

type RefreshControlLayout = ReturnType<typeof calcRefreshControlLayout>;

function getTransform(
  metric: Metric,
  refreshControlLayout: RefreshControlLayout | null,
  atDockPosition: boolean,
) {
  if (atDockPosition) {
    return `translateY(${metric.circleTarget}px)`;
  } else if (refreshControlLayout !== null) {
    return `translateY(${refreshControlLayout.targetY}px) rotateZ(${refreshControlLayout.rotationAngle * 360}deg)`;
  } else {
    return `translateY(${metric.circleTarget}px) scale(0)`;
  }
}

export function MaterialRefreshControl(props: {
  readonly isLarge?: boolean;
  readonly pullDistance: number | null;
  readonly isLoading: boolean;
  readonly onThresholdPassed: (passed: boolean) => void;
  readonly onStartRefresh: () => void;
}) {
  const metric = useMemo(
    () => (props.isLarge ? METRIC.large : METRIC.default),
    [props.isLarge],
  );

  const refreshControlLayout = useMemo(
    () =>
      props.pullDistance !== null
        ? calcRefreshControlLayout(metric, props.pullDistance)
        : null,
    [metric, props.pullDistance, props.isLoading],
  );

  const [isDocking, setIsDocking] = useState<boolean>(false);

  useChange(props.pullDistance, (prev, current) => {
    if (prev !== null && current === null) {
      setIsDocking(true);
    }
  });

  useEffect(() => {
    if (props.isLoading || props.pullDistance !== null) {
      setIsDocking(false);
    }
  }, [props.pullDistance, props.isLoading]);

  useEffect(() => {
    props.onThresholdPassed(
      refreshControlLayout
        ? refreshControlLayout.targetY >= metric.circleTarget
        : false,
    );
  }, [refreshControlLayout, props.onThresholdPassed, metric]);

  return (
    <Frisbee
      style={{
        transform: getTransform(
          metric,
          refreshControlLayout,
          props.isLoading || isDocking,
        ),
        transition:
          props.pullDistance && props.pullDistance > 0
            ? "none"
            : "transform .2s",
      }}
      metric={metric}
      onTransitionEnd={() => {
        if (isDocking) {
          setIsDocking(false);
          props.onStartRefresh();
        }
      }}
    >
      {refreshControlLayout && (
        <svg
          width={metric.circleDiameter}
          height={metric.circleDiameter}
          viewBox={`0 0 ${metric.circleDiameter} ${metric.circleDiameter}`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          opacity={refreshControlLayout.progress}
        >
          <defs>
            <marker
              orient="auto"
              viewBox={`0 0 ${metric.progress.arrowWidth} ${metric.progress.arrowWidth}`}
              id="g-arrowhead-rep"
              refX={0}
              refY={metric.progress.arrowWidth / 2}
              markerWidth={3 * refreshControlLayout.progress}
              markerHeight={3 * refreshControlLayout.progress}
            >
              <path
                fill="var(--color-text00r)"
                className="g-marker g-rep"
                d={`M0,0L${metric.progress.arrowHeight},${metric.progress.arrowWidth / 2}L0,${metric.progress.arrowWidth}`}
              ></path>
            </marker>
          </defs>

          <path
            d={f_svg_ellipse_arc(
              [metric.circleDiameter / 2, metric.circleDiameter / 2],
              [
                metric.progress.radius + metric.progress.strokeWidth / 2,
                metric.progress.radius + metric.progress.strokeWidth / 2,
              ],
              [0, 0.8 * Math.PI * 2 * refreshControlLayout.progress],
              Math.PI * 0.8,
            ).join(" ")}
            stroke="var(--color-text00r)"
            strokeWidth={`${metric.progress.strokeWidth}`}
            markerEnd="url(#g-arrowhead-rep)"
          />
        </svg>
      )}
      {!refreshControlLayout && (
        <ChasingCircle
          radius={metric.progress.radius}
          strokeWidth={metric.progress.strokeWidth}
          color={"var(--color-text00r)"}
        />
      )}
    </Frisbee>
  );
}
