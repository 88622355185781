import React from "react";
import { DemoCell } from "./DemoCell";
import { useWebHost } from "../../hooks/useBridge";
import { HStack, Spring } from "../../components/VStack";
import chevron from "../../res/images/chevron.svg";
import { PageRoot } from "../../components/PageRoot";
import { Image } from "../../components/Image";

export function DebugHomePage() {
  const webHost = useWebHost();

  return (
    <PageRoot pageData={undefined}>
      <DemoCell onClick={() => webHost.openBridge(`components-demo`)}>
        <HStack>
          <span style={{ color: "aqua", fontSize: 20 }}>Component Demos</span>
          <Spring />
          <Image src={chevron} alt={"reveal"} />
        </HStack>
      </DemoCell>

      <DemoCell onClick={() => webHost.openBridge(`hooks-demo`)}>
        <HStack>
          <span style={{ color: "aqua", fontSize: 20 }}>Hook Demos</span>
          <Spring />
          <Image src={chevron} alt={"reveal"} />
        </HStack>
      </DemoCell>

      <DemoCell onClick={() => webHost.openBridge(`page-arch-demo`)}>
        <HStack>
          <span style={{ color: "aqua", fontSize: 20 }}>Page Demos</span>
          <Spring />
          <Image src={chevron} alt={"reveal"} />
        </HStack>
      </DemoCell>

      <DemoCell
        onClick={() => webHost.openBridge(`circle-trade/1778370586329088000`)}
      >
        Trade Page
      </DemoCell>

      <DemoCell
        onClick={() =>
          webHost.openBridge("campaign-buttons/1791834330026733568")
        }
      >
        Edit Buttons
      </DemoCell>
      <DemoCell onClick={() => webHost.openBridge("user-upgrade-success/2")}>
        user-upgrade-success
      </DemoCell>

      <DemoCell onClick={() => webHost.openBridge("monetary-value-demo")}>
        Monetary Value
      </DemoCell>

      <DemoCell onClick={() => webHost.openBridge("")}>About</DemoCell>
    </PageRoot>
  );
}
