import { DependencyList, useCallback, useEffect, useId, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { decodeTPS, encodeTPS } from "../bridge/Bridge";
import { andLog } from "../components/handleError";
import { isRejectable, Rejectable } from "../bridge/Rejectable";
import { useBroadcast } from "./useBroadcast";
import {
  DatePickerConfig,
  MediaMenuConfig,
  NativePage,
} from "../bridge/NativePage";
import { I18n, useI18n } from "./useI18n";
import { useNativePage, usePageCloser, useWebHost } from "./useBridge";
import { WebHost } from "../bridge/WebHost";

type BridgeBroadcastParam = {
  id: string;
  env?: string;
  value?: string; // TPS
  error?: string; // TPS
};

export function useBridgeResultProvider<R extends {}>() {
  const webHost = useWebHost();
  const pageCloser = usePageCloser();

  const [query] = useSearchParams();
  return useCallback(
    (r: R | Rejectable) => {
      const callbackId = query.get("resultId") ?? "❌missing resultId";
      const callbackEnv = query.get("resultEnv") ?? undefined;

      console.log(callbackId, callbackEnv);
      webHost
        .broadcast("OnBridgeResult", {
          id: callbackId,
          env: callbackEnv,
          value: !isRejectable(r) ? (r ? encodeTPS(r) : undefined) : undefined,
          error: isRejectable(r) ? encodeTPS(r) : undefined,
        })
        .then(() => pageCloser.close())
        .catch(andLog);
    },
    [query],
  );
}

export function pushProvider<EnvT = undefined>(path: string) {
  return (
    webHost: WebHost,
    nativePage: NativePage,
    i18n: I18n,
    id: string,
    env: EnvT,
  ) => {
    webHost.openBridge(path, {
      mode: "push",
      requestId: id,
      requestEnv: encodeTPS(env),
    });
  };
}

export function modalProvider<EnvT = undefined>(path: string) {
  return (
    webHost: WebHost,
    nativePage: NativePage,
    i18n: I18n,
    id: string,
    env: EnvT,
  ) => {
    webHost.openBridge(path, {
      mode: "modal",
      requestId: id,
      requestEnv: encodeTPS(env),
    });
  };
}

export function presentProvider<EnvT = undefined>(path: string) {
  return (
    webHost: WebHost,
    nativePage: NativePage,
    i18n: I18n,
    id: string,
    env: EnvT,
  ) => {
    webHost.openBridge(path, {
      mode: "present",
      requestId: id,
      requestEnv: encodeTPS(env),
    });
  };
}

export function mediaMenuProvider(config: MediaMenuConfig) {
  return (webHost: WebHost, nativePage: NativePage, i18n: I18n, id: string) => {
    nativePage.showMediaMenu(id, config).catch(andLog);
  };
}

export function datePickerProvider(config: DatePickerConfig) {
  return (webHost: WebHost, nativePage: NativePage, i18n: I18n, id: string) => {
    nativePage.pickDate(id, config).catch(andLog);
  };
}

export function stickerPickerProvider() {
  return (webHost: WebHost, nativePage: NativePage, i18n: I18n, id: string) => {
    nativePage.pickSticker(id).catch(andLog);
  };
}

export function useBridgeResultConsumer<R extends {}, EnvT = undefined>(
  provider: (
    webHost: WebHost,
    nativePage: NativePage,
    i18n: I18n,
    id: string,
    env: EnvT,
  ) => void,
  onResult: (r: R | Rejectable, env: EnvT) => boolean,
  deps: DependencyList,
): (env?: EnvT) => void {
  const webHost = useWebHost();
  const nativePage = useNativePage();
  const i18n = useI18n();
  const requestId = useId();

  const [pendingResult, setPendingResult] = useState<{
    r: R | Rejectable;
    env: EnvT;
  }>();

  useEffect(() => {
    if (pendingResult) {
      if (onResult(pendingResult.r, pendingResult.env)) {
        setPendingResult(undefined);
      }
    }
  }, [pendingResult, ...deps]);

  useBroadcast<BridgeBroadcastParam>(
    "OnBridgeResult",
    (param) => {
      if (param.id === requestId) {
        const error = param.error
          ? (decodeTPS(param.error) as Rejectable)
          : undefined;
        const value = (param.value ? decodeTPS(param.value) : undefined) as R;
        const env = (param.env ? decodeTPS(param.env) : undefined) as EnvT;
        setPendingResult({
          r: error ?? value,
          env: env,
        });
      }
    },
    deps,
  );

  return (env?: EnvT) => {
    provider(webHost, nativePage, i18n, requestId, env as any);
  };
}
