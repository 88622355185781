// This file is generated by xml2ts.py. Don't change


import {ReactElement} from "react";
type I18nReactElement = ReactElement & {__i18nBrand: "I18nReactElement"};
type I18nResult<A> = ReactElement extends A ? I18nReactElement : string;


const pluralRules = new Intl.PluralRules("ru-RU")
export function getString_ru_RU(
  strBuilder: (format: string, ...args: (ReactElement|string|number)[]) => unknown
) {
  return {
    app_name_projz_z: ()=>`Project Z`,
    app_name_spongekit_z: ()=>`SpongeKit`,
    auth_log_out: ()=>`Выйти`,
    cash_rewards_day_number: <A0 extends ReactElement | number>(arg0: A0)=>strBuilder(`День %d`, arg0) as I18nResult<A0>,
    clover_migrate_migrated_info: ()=>`Ваша учетная запись перенесена в наше новое приложение Clover.Space Пожалуйста, используйте Clover.Space, чтобы продолжить пользоваться нашими услугами. Поддержка Project Z в ближайшее время будет прекращена.`,
    clover_migrate_open_clover: ()=>`Обновлять`,
    clover_migrate_phase_1_info: ()=>`Интересные новости! Мы рады сообщить, что вы в числе первых получили эксклюзивное приглашение на предзапуск нашего нового приложения Clover.Space! Ваши отзывы бесценны для нас, и мы с нетерпением ждем ваших замечаний. Спасибо, что присоединились к нашему путешествию!`,
    clover_migrate_phase_1_title: ()=>`Анонс Clover.Space`,
    clover_migrate_phase_2_info: ()=>`Потрясающие новости! Мы рады представить наше новое приложение - **Clover.Space**. Обновите приложение, чтобы получить совершенно новые впечатления. Спасибо, что присоединились к нашему путешествию!`,
    clover_migrate_phase_2_title: ()=>`Представляем Clover.Space`,
    clover_migrate_phase_3_info_new: ()=>`Мы рады представить вам наше новое приложение **Clover.Space**. Вам необходимо обновить приложение, чтобы продолжать пользоваться нашими услугами. Спасибо вас за то, что остаетесь с нами!`,
    clover_migrate_phase_3_title: ()=>`Представляем Clover.Space`,
    clover_upgrade_app: ()=>`Upgrade App`,
    compose_confirmation: ()=>`Выйти из редактирования?`,
    done: ()=>`Готово`,
    help_split_appeal: ()=>`Обжаловать`,
    loading: ()=>`Загрузка…`,
    mars_account_disabled: ()=>`Учетная запись отключена`,
    mars_account_restricted: ()=>`Учетная запись ограничена`,
    mars_account_waring: ()=>`Предупреждение`,
    mars_bonus_card_title: <A0 extends ReactElement | number>(arg0: A0)=>strBuilder(`период %d дней`, arg0) as I18nResult<A0>,
    mars_bonus_missed: ()=>`Пропущен`,
    mars_check_gift_detail: ()=>`Посмотреть детали подарков`,
    mars_cloud_box: ()=>`Облачная Шкатулка`,
    mars_journey_rule_desc: ()=>`Чтобы получить свою отметку за день, вам необходимо принять участие как минимум в 10 раундах беседы. “Раунд” беседы определяется как обмен сообщениями от каждого участника.

Для уточнения:

- Участник A отправляет сообщение

- Участник B отвечает на сообщение.

Это считается одним раундом разговора.


На протяжении всего путешествия у вас есть 4 отметки. Когда пришло время получить отметку, у вас появится галочкой каждый день с момента предыдущей отметки. Только после этого вы получите подарок.`,
    mars_journey_rule_title: ()=>`Инструкции для 30-дневного Путешествия Друзей`,
    mars_mbox_desc: ()=>`В коробке вы найдете тот подарок, указанный выше`,
    mars_mbox_how_to_claim: <A0 extends ReactElement | string, A1 extends ReactElement | string>(arg0: A0, arg1: A1)=>strBuilder(`Вам необходимо получать отметки каждый день в течении %s to %s, чтобы получить подарок.`, arg0, arg1) as I18nResult<A0|A1>,
    mars_mbox_open: ()=>`Открыть`,
    mars_mystery_boxes: ()=>`Таинственная шкатулка`,
    mars_mystery_boxes_subtitle: ()=>`От 30-дневного Путешествия Друзей`,
    mars_palm_box: ()=>`Шкатулка Пляж`,
    mars_rainbow_box: ()=>`Радужная Шкатулка`,
    mars_see_more: ()=>`Посмотреть больше`,
    mars_sys_msg_center: ()=>`Центр сообщений`,
    mars_sys_msg_post_disabled: ()=>`Пост отключен`,
    mars_the_day_of: <A0 extends ReactElement | number>(arg0: A0)=>strBuilder(`День %d`, arg0) as I18nResult<A0>,
    mars_wave_box: ()=>`Шкатулка Океан`,
    meet_now_try_again: ()=>`Попробовать снова`,
    mod_appeal_view_original_content: ()=>`Посмотреть оригинальный контент`,
    moon_ads_explore_memecoins: ()=>`Узнать мемтокены`,
    moon_ads_get_for_free: <A0 extends ReactElement | string>(arg0: A0)=>strBuilder(`Получи %s БЕСПЛАТНО`, arg0) as I18nResult<A0>,
    moon_ads_just_earned: <A0 extends ReactElement | string, A1 extends ReactElement | string>(arg0: A0, arg1: A1)=>strBuilder(`%s только что заработали %s`, arg0, arg1) as I18nResult<A0|A1>,
    moon_ads_market_cap: ()=>`Рыночная капитализация`,
    moon_ads_truncate_elipsis: <A0 extends ReactElement | string>(arg0: A0)=>strBuilder(`%s…`, arg0) as I18nResult<A0>,
    no_content_yet: ()=>`Пока нет контента`,
    oct12_text_continue_editing: ()=>`Продолжить редактировать`,
    ok: ()=>`OK`,
    quit: ()=>`Выйти`,
    retry: ()=>`Повторить`,


    plural: (q: number) => {
      return {
        mars_journey_condition_to_claim: <A0 extends ReactElement | number>(arg0: A0) => {
          const pluralMap = new Map([
            ["one", `Вам необходимо получать отметки в течение %d последующего дня, чтобы получить подарок.`],
            ["other", `Вам необходимо получать отметки в течение %d последующих дней, чтобы получить подарок.`],
            ["few", `Вам необходимо получать отметки в течение %d последующих дней, чтобы получить подарок.`],
            ["many", `Вам необходимо получать отметки в течение %d последующих дней, чтобы получить подарок.`],
          ])
          return strBuilder(pluralMap.get(pluralRules.select(q)) ?? pluralMap.get("other") ?? "missing", arg0)  as I18nResult<A0>;
        },
      };
    }
  }
}