import { JSONUtil } from "../utils/JSONUtil";
import { base64ToAny } from "../utils/Blob";

import { urlAppendQuery } from "../utils/UrlUtil";
import { Media } from "../proto/Media";
import { LocalMedia, MediaScene } from "./LocalMedia";
import { assert } from "../utils/asserts";
import { ZodType } from "zod/lib/types";
import { zBigInt, zStatic } from "../utils/zodUtils";
import { fromError } from "zod-validation-error";
import { pruneNulls } from "../utils/pick";
import { z } from "zod";
import { TokenProject, TokenProjectHot } from "../proto/TokenProject";

class EndPoint<S extends ZodType<any, any, any>> {
  constructor(
    private readonly client: BackendClient,
    private readonly responseSchema: S,
    private readonly method: "GET" | "POST",
    private readonly url: string,
    private readonly query: object | undefined,
    private readonly body: object | undefined,
  ) {
    assert(
      !url.includes("?"),
      `url should not contains query. try passing in the query parameter. ${url}`,
    );
  }

  async run(params?: object): Promise<zStatic<S>> {
    const totalQuery = {
      ...this.query,
      ...params,
    };
    const totalUrl = urlAppendQuery(this.url, totalQuery);
    console.log(this.method, totalUrl);
    let data: string;
    switch (this.method) {
      case "GET":
        data = await this.client.get(totalUrl);
        break;
      case "POST":
        data = await this.client.post(
          totalUrl,
          this.body ? JSONUtil.stringify(this.body) : "",
        );
        break;
    }
    try {
      const obj = base64ToAny(data);
      pruneNulls(obj);
      return this.responseSchema.parse(obj);
    } catch (e) {
      throw fromError(e);
    }
  }
}

export class Backend {
  constructor(private readonly client: BackendClient) {}

  private GET<S extends ZodType<any, any, any>>(
    responseScheme: S,
    url: string,
    query?: object,
  ) {
    return new EndPoint(
      this.client,
      responseScheme,
      "GET",
      url,
      query,
      undefined,
    );
  }

  private POST<S extends ZodType<any, any, any>>(
    responseScheme: S,
    url: string,
    body?: object,
  ) {
    return new EndPoint(
      this.client,
      responseScheme,
      "POST",
      url,
      undefined,
      body,
    );
  }

  sendLocalMedia = this.client.sendLocalMedia;

  getMoonHotProject = () =>
    this.GET(TokenProjectHot, `/v1/recommend-hot-project`).run();
}

export type BackendClient = {
  get: (url: string) => Promise<string>;
  post: (url: string, body: string) => Promise<string>;
  sendLocalMedia: (
    localMedia: LocalMedia,
    scene: MediaScene,
    progressListener: (uploaded: bigint, total: bigint) => void,
  ) => Promise<Media>;
};
