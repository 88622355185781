import styled from "styled-components";
import React, {
  CSSProperties,
  PropsWithChildren,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Spring, VSpace, VStackMixin } from "./VStack";
import svgToMiniDataURI from "mini-svg-data-uri";
import { useSafeAreaInsets } from "../hooks/useSafeAreaInsets";
import {
  DefaultEmptyView,
  LoadStateCell,
  LoadStateView,
} from "./LoadStateView";
import { VScroll } from "./vscroll/VScroll";
import { SWRSingle } from "../hooks/swr/useSWR";
import { hPaddingWithPageInset } from "./CommonStyles";
import {
  LoadState,
  LoadStateKind,
  AllDoneWithErrorFirst,
} from "../hooks/LoadState";
import { andLog } from "./handleError";
import { PageRootContext } from "./PageRootContext";
import { SWRAccum } from "../hooks/swr/SWRAccum";
import { useCurrentLanguage, useI18n } from "../hooks/useI18n";
import { useBooleanSearchParam } from "../hooks/useTypedParam";
import { useNativePage, useWebHost } from "../hooks/useBridge";
import { getErrorMsg } from "../bridge/Rejectable";
import { NativePage } from "../bridge/NativePage";

const FullScreen = styled.div`
  display: flex;
  justify-content: center;
  margin: auto;
  width: 100vw;
  height: 100vh;
`;

const LimitedWidth = styled.div`
  position: relative;
  max-width: 600px;
  width: 100%;
  height: 100%;
  overflow: hidden; // don't allow document scrolling

  ${VStackMixin};
  align-items: stretch;
  box-sizing: border-box;
`;

const DebugLabel = styled.div`
  top: 30px;
  color: black;
  position: absolute;
  margin-left: 18px;
  margin-right: 18px;
  font-size: 11px;
  background-color: beige;
`;

export type PageData = {
  loadState: LoadState | undefined;
  hasContents: boolean;
  refresh: (reason?: string) => Promise<void>;
};

export type LoadStateConfig = {
  emptyText?: string;
  errorImage?: string;
  reloadText?: string;
  emptyStateProvider?: () => ReactElement;
};

type SWRType =
  | Omit<SWRSingle<{}>, "fill"> // fill is contravariance
  | SWRAccum<{}[]> // useSWRArray
  | SWRAccum<{ list: {}[] }> // useSWRList
  | SWRAccum<unknown>;

function isSWRSingle(
  obj: undefined | PageData | SWRType,
): obj is Omit<SWRSingle<{}>, "fill"> {
  return (
    obj !== undefined && "load" in obj && "fill" in obj && !("loadMore" in obj)
  );
}

function isSWRArray(
  obj: undefined | PageData | SWRType,
): obj is SWRAccum<{}[]> {
  return (
    obj !== undefined &&
    "load" in obj &&
    "loadMore" in obj &&
    "content" in obj &&
    Array.isArray(obj.content)
  );
}

function isValidSWRList(
  obj: undefined | PageData | SWRType,
): obj is SWRAccum<{ list: {}[] }> {
  return (
    obj !== undefined &&
    "load" in obj &&
    "loadMore" in obj &&
    "content" in obj &&
    obj.content !== undefined &&
    obj.content !== null &&
    typeof obj.content === "object" &&
    "list" in obj.content &&
    Array.isArray(obj.content["list"])
  );
}

function isSWRAccumKnown(
  obj: undefined | PageData | SWRType,
): obj is SWRAccum<unknown> {
  return (
    obj !== undefined &&
    "load" in obj &&
    "loadMore" in obj &&
    !isSWRArrayOrList(obj)
  );
}

function isSWRArrayOrList(
  obj: undefined | PageData | SWRType,
): obj is SWRAccum<{ list: {}[] }> | SWRAccum<{}[]> {
  return isSWRArray(obj) || isValidSWRList(obj);
}

function isSWRType(obj: undefined | PageData | SWRType): obj is
  | Omit<SWRSingle<{}>, "fill">
  | SWRAccum<{
      list: {}[];
    }>
  | SWRAccum<{}[]> {
  return isSWRSingle(obj) || isSWRArray(obj) || isValidSWRList(obj);
}

function swrTypeHasContents(swr: SWRType) {
  if (isValidSWRList(swr)) {
    return swr.content.list.length > 0;
  } else if (isSWRArray(swr)) {
    return swr.content.length > 0;
  } else if (isSWRSingle(swr)) {
    return swr.content !== undefined && Object.keys(swr.content).length > 0;
  } else {
    return false;
  }
}

function swrListHasContents(swrs: (SWRType | undefined)[]) {
  for (const swr of swrs) {
    if (swr) {
      if (swrTypeHasContents(swr)) return true;
    }
  }

  return false;
}

function getAllErrors(obj: PageData | SWRType | (SWRType | undefined)[]) {
  if (Array.isArray(obj)) {
    return obj
      .map((o) => o?.loadState)
      .flatMap((s) => {
        if (s?.kind === "loadFailed") {
          return [s.error];
        } else {
          return [];
        }
      });
  } else if (obj) {
    if (obj.loadState?.kind === "loadFailed") {
      return [obj.loadState.error];
    } else {
      return [];
    }
  } else {
    return [];
  }
}

function combineToOne(
  obj: PageData | SWRType | (SWRType | undefined)[],
): PageData & { allErrors: unknown[] } {
  function combineToPageData(
    obj: PageData | SWRType | (SWRType | undefined)[],
  ): PageData {
    if (Array.isArray(obj)) {
      return {
        loadState: AllDoneWithErrorFirst(obj.map((s) => s?.loadState)),
        hasContents: swrListHasContents(obj),
        refresh: (reason?: string) =>
          Promise.all(obj.map((s) => s?.load(reason))).then(),
      };
    } else if (isSWRType(obj)) {
      return {
        loadState: obj.loadState,
        hasContents: swrTypeHasContents(obj),
        refresh: (reason) => obj.load(reason),
      };
    } else if (isSWRAccumKnown(obj)) {
      return {
        loadState: obj.loadState,
        hasContents: false,
        refresh: (reason) => obj.load(reason),
      };
    } else {
      return obj;
    }
  }

  return {
    ...combineToPageData(obj),
    allErrors: getAllErrors(obj),
  };
}

function getAllSWRArrayOrList(
  obj: undefined | PageData | SWRType | (SWRType | undefined)[],
) {
  if (obj === undefined) {
    return [];
  } else if (Array.isArray(obj)) {
    return obj.filter(isSWRArrayOrList);
  } else if (isSWRArrayOrList(obj)) {
    return [obj];
  } else if (isSWRSingle(obj)) {
    return [];
  } else {
    return [];
  }
}

const FullPageState = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;
  overflow: hidden;
`;

const ContentContainer = styled.div`
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;

  box-sizing: border-box;
  overflow: hidden;
`;

async function displayErrors(
  nativePage: NativePage,
  errors: readonly unknown[],
) {
  for (const error of errors) {
    await nativePage.warnHud(getErrorMsg(error));
  }
}

export function PageRoot(
  props: PropsWithChildren<{
    background?: CSSProperties["background"];
    pageData: undefined | PageData | SWRType | (SWRType | undefined)[];
    loadStateConfig?: LoadStateConfig;
    emptyTopSpace?: number;
    safeTopDisabled?: boolean;
    scrollDisabled?: boolean;
    pullToRefreshDisabled?: boolean;
    extraSafeBottom?: number;
    fullPageStateDisabled?: boolean;
    contentBackground?: ReactElement;
  }>,
) {
  const safeAreaInsets = useSafeAreaInsets();
  const gitCommitCount = process.env.REACT_APP_GIT_COMMIT_COUNT;
  const currentUrl = window.location.host;
  const webHost = useWebHost();

  const pullToRefreshDisabledParam = useBooleanSearchParam(
    "pullToRefreshDisabled",
  );

  const pullToRefreshDisabled = useMemo(
    () => pullToRefreshDisabledParam ?? props.pullToRefreshDisabled ?? false,
    [pullToRefreshDisabledParam, props.pullToRefreshDisabled],
  );

  const transparentBg = useBooleanSearchParam("transparentBg", false);

  const [modals, setModals] = useState<readonly ReactElement[]>([]);
  const [headers, setHeaders] = useState<readonly ReactElement[]>([]);
  const [footers, setFooters] = useState<readonly ReactElement[]>([]);

  const nativePage = useNativePage();
  const canonicalPageData = useMemo(
    () => (props.pageData ? combineToOne(props.pageData) : undefined),
    [props.pageData],
  );

  const displayedErrorsRef = useRef<readonly unknown[]>([]);

  useEffect(() => {
    if (canonicalPageData) {
      const newErrors = canonicalPageData.allErrors.filter(
        (e) => !displayedErrorsRef.current.includes(e),
      );

      displayErrors(nativePage, canonicalPageData.hasContents ? newErrors : [])
        .then(() => {
          displayedErrorsRef.current =
            displayedErrorsRef.current.concat(newErrors);
        })
        .catch(andLog);
    }
  }, [canonicalPageData, nativePage]);

  const refreshTarget = useMemo(() => {
    if (canonicalPageData && !pullToRefreshDisabled) {
      return {
        onRefresh: () => canonicalPageData.refresh("pullToRefresh"),
        isLoading:
          canonicalPageData.loadState?.kind === LoadStateKind.loading &&
          canonicalPageData.loadState.reason === "pullToRefresh",
      };
    } else {
      return undefined;
    }
  }, [canonicalPageData, pullToRefreshDisabled]);

  const allSWRArrayOrLists = useMemo(
    () => getAllSWRArrayOrList(props.pageData),
    [props.pageData],
  );

  const i18n = useI18n();

  let pageStateView: ReactElement | undefined = undefined;
  if (canonicalPageData) {
    if (!canonicalPageData.hasContents) {
      if (canonicalPageData.loadState?.kind === LoadStateKind.loaded) {
        if (props.loadStateConfig?.emptyStateProvider) {
          pageStateView = props.loadStateConfig.emptyStateProvider();
        } else {
          pageStateView = (
            <DefaultEmptyView
              title={props.loadStateConfig?.emptyText ?? i18n.no_content_yet()}
              topSpace={props.emptyTopSpace}
            />
          );
        }
      } else {
        pageStateView = (
          <LoadStateView
            loadState={canonicalPageData.loadState}
            onClickRetry={() => canonicalPageData.refresh().catch(andLog)}
            {...props.loadStateConfig}
          />
        );
      }
    }
  }

  const uniqueSWRArrayOrList =
    allSWRArrayOrLists.length == 1 ? allSWRArrayOrLists[0] : undefined;

  let content = props.children;
  if (props.scrollDisabled !== true) {
    content = (
      <VScroll
        style={{
          paddingBottom:
            footers.length === 0
              ? safeAreaInsets.bottom + (props.extraSafeBottom ?? 30)
              : 0,
          ...hPaddingWithPageInset,
        }}
        refreshTarget={refreshTarget}
        onScroll={allSWRArrayOrLists}
      >
        {content}

        {uniqueSWRArrayOrList &&
          uniqueSWRArrayOrList.loadState?.kind === LoadStateKind.loaded &&
          !swrTypeHasContents(uniqueSWRArrayOrList) && (
            <DefaultEmptyView
              title={props.loadStateConfig?.emptyText ?? i18n.no_content_yet()}
              topSpace={props.emptyTopSpace}
            />
          )}

        {uniqueSWRArrayOrList && (
          <LoadStateCell loadState={uniqueSWRArrayOrList.loadState} />
        )}
      </VScroll>
    );
  }

  const language = useCurrentLanguage();
  return (
    <FullScreen
      lang={language}
      style={{
        background: transparentBg
          ? "transparent"
          : process.env.NODE_ENV === "development"
            ? "var(--color-text00)"
            : "var(--color-bg)",
      }}
    >
      <LimitedWidth
        style={{
          background: transparentBg
            ? "transparent"
            : props.background ?? "#0A001A",
        }}
      >
        {props.contentBackground}
        {props.fullPageStateDisabled !== true && pageStateView && (
          <FullPageState>{pageStateView}</FullPageState>
        )}

        {props.safeTopDisabled !== true && (
          <VSpace height={safeAreaInsets.top} />
        )}
        {headers}
        <PageRootContext.Provider
          value={{
            hasPageRoot: true,
            addModal: (modal: ReactElement) => {
              setModals((prev) => {
                return [...prev, modal];
              });
            },
            removeModal: (modal: ReactElement) => {
              setModals((prev) => {
                return prev.filter((m) => m !== modal);
              });
            },
            addHeader: (header: ReactElement) => {
              setHeaders((prev) => {
                return [...prev, header];
              });
            },
            removeHeader(header: ReactElement) {
              setHeaders((prev) => {
                return prev.filter((m) => m !== header);
              });
            },
            addFooter: (footer: ReactElement) => {
              setFooters((prev) => {
                return [...prev, footer];
              });
            },
            removeFooter: (footer: ReactElement) => {
              setFooters((prev) => {
                return prev.filter((m) => m !== footer);
              });
            },
          }}
        >
          {props.fullPageStateDisabled === true && pageStateView && (
            <ContentContainer>{pageStateView}</ContentContainer>
          )}
          {
            <>
              <ContentContainer
                style={
                  canonicalPageData?.hasContents !== false
                    ? undefined
                    : { display: "none" }
                }
              >
                {content}
              </ContentContainer>
              {/*when ContentContainer with 'display' being 'none', */}
              {/*it doesn't occupy the space, so we need a 'Spring' */}
              {/*to push the 'footers' to the bottom.*/}
              <Spring />
            </>
          }
        </PageRootContext.Provider>
        {footers}
        {modals}
      </LimitedWidth>
      {webHost.getEnv().isSpongeKit && (
        <DebugLabel>{`${gitCommitCount} ${currentUrl} @${window.devicePixelRatio}x`}</DebugLabel>
      )}
    </FullScreen>
  );
}
