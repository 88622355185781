import { z } from "zod";
import { zBigInt, zStatic } from "../utils/zodUtils";

export const Resource = z.object({
  width: z.number(),
  height: z.number(),
  url: z.string(),
});
export type Resource = zStatic<typeof Resource>;

export const Media = z.object({
  mediaId: zBigInt.optional(),
  baseUrl: z.string(),
  resourceList: z.array(Resource),
});
export type Media = zStatic<typeof Media>;

export function getBestRes(media: Media): Resource {
  return sortResList(media.resourceList, false)[0];
}

export function getSmallestRes(media: Media): Resource {
  return sortResList(media.resourceList, true)[0];
}

export type Size = {
  width: number;
  height: number;
};

export function getFittestRes(media: Media, fitSize: Size): Resource {
  let bestResource: Resource | undefined = undefined;
  let scale = window.devicePixelRatio;
  for (let resource of media.resourceList) {
    if (
      resource.width * resource.height >
      fitSize.width * fitSize.height * scale * scale
    ) {
      if (bestResource) {
        if (
          resource.width * resource.height <
          bestResource.width * bestResource.height
        ) {
          bestResource = resource;
        }
      } else {
        bestResource = resource;
      }
    }
  }
  return bestResource ?? getBestRes(media);
}

function sortResList(resourceList: Resource[], decrease: boolean): Resource[] {
  let value = decrease ? -1 : 1;
  return resourceList.sort((a, b) => {
    if (a.height > b.height) {
      return -1 * value;
    } else if (a.height < b.height) {
      return value;
    } else {
      return 0;
    }
  });
}
