import { LoadState } from "../LoadState";
import { SWRConfig } from "./SWRJob";
import { StateId } from "../StateId";
import { SWRRepoData, useSWRRepo } from "./useSWRRepo";
import { AlignUndefined } from "../../utils/Nullable";
import { omit } from "../../utils/pick";

export type SWRSingleMeta<T> = Omit<
  SWRRepoData<T, undefined>,
  "content" | "updatedMoreAt" | "hasMore" | "cursor"
>;

export type SWRSingle<T extends {}> = {
  readonly content: T | undefined;
  readonly meta: SWRSingleMeta<T>;
  readonly loadState: LoadState | undefined;
  readonly load: (reason?: string) => Promise<void>;
  readonly fill: (content: T) => Promise<void>;
};

export function useSWR<T extends {}, ID extends StateId | undefined = StateId>(
  contentId: ID,
  fetcher: () => Promise<T>,
  config?: SWRConfig,
): AlignUndefined<SWRSingle<T>, ID> {
  const repo = useSWRRepo(
    contentId,
    undefined,
    undefined,
    () =>
      fetcher().then((r) => {
        return { content: r, cursor: undefined, hasMore: false };
      }),
    config,
  );

  if (repo === undefined) return undefined as AlignUndefined<SWRSingle<T>, ID>;
  return {
    content: repo.data.content,
    meta: omit(repo.data, ["content", "updatedMoreAt", "hasMore", "cursor"]),
    loadState: repo.loadState,
    load: async (reason?: string) => await repo.repo.reload(reason),
    fill: async (content: T) => await repo.repo.fill(content, undefined, false),
  };
}
