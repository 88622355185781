import { z } from "zod";
import { Media } from "./Media";
import BigNumber from "bignumber.js";
import { zBigInt, zBigNumber, zStatic } from "../utils/zodUtils";

export const MoonUser = z.object({
  uid: zBigInt,
  nickname: z.string().default("-"),
  icon: Media.optional(),
});

export const TradingStatistics = z.object({
  marketCap: z.string().default("0"),
  marketCap1Hip: z.number().default(0),
});

export const ShareToEarnUserStats = z.object({
  statsId: zBigInt,
  totalEarned: z.string().default("0"),
  user: MoonUser.optional(),
});

export const TokenProject = z.object({
  projectId: zBigInt,
  ticker: z.string(),
  name: z.string(),
  image: Media.optional(),
  enableShareToEarn: z.boolean().default(false),
  availableShareToEarnReward: z.string().default("0"),
  tradingStatistics: TradingStatistics.optional(),
  topTenUserEarnRewards: z.array(ShareToEarnUserStats).default([]),
});

export const TokenProjectHot = z.object({
  project: TokenProject.optional(),
  url: z.string().default(""),
  amount: z.string().default("0"),
});

export type TokenProjectHot = zStatic<typeof TokenProjectHot>;
