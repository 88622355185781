import styled from "styled-components";
import { accent_font } from "./CommonStyles";

export const SpeedTitle = styled.div`
  position: relative;
  ${accent_font};
  font-size: 14px;
  font-weight: 500;
  color: var(--color-text00);
  box-sizing: border-box;
  padding-bottom: 4px;

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    width: 80px;
    left: auto;
    height: 1px;
    background: linear-gradient(to right, #ffffff, rgba(255, 255, 255, 0));
  }
`;

export const SpeedTitleNote = styled.div`
  color: #fff;
  font-size: 12px;
  font-weight: 300;
  opacity: 0.5;

  margin-top: 6px;
`;
