import React, { useState } from "react";
import { DemoCell } from "./DemoCell";
import {
  BottomSheet,
  CenterModal,
  ModalDesc,
  ModalTitle,
  useModal,
  useModalWithItsOwnPage,
} from "../../components/Modal";
import { BSMenu, BSMenuItem } from "../../components/BSMenu";
import { useNativePage, useWebHost } from "../../hooks/useBridge";
import { andLog } from "../../components/handleError";
import { VStack } from "../../components/VStack";
import { usePageTitle } from "../../hooks/usePageTitle";
import { RegularButton } from "../../components/Buttons";
import {
  modalProvider,
  useBridgeResultConsumer,
  useBridgeResultProvider,
} from "../../hooks/useBridgeResult";
import { PageRoot } from "../../components/PageRoot";
import { RegularInputGroup } from "../../components/Input";

export function DemoBridgeModalPage() {
  const modal = useModalWithItsOwnPage();
  const webHost = useWebHost();

  const provide = useBridgeResultProvider<string>();

  return (
    <CenterModal modal={modal}>
      <ModalTitle>Bridge Modal</ModalTitle>
      <ModalDesc>
        {
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc imperdiet, dolor non pulvinar fermentum, erat augue condimentum leo, non cursus velit nisl ac felis. Nullam quis vestibulum tellus, nec commodo orci. Proin odio ante, sodales quis pellentesque sit amet, aliquam sit amet ex. Sed a velit dignissim, aliquet nibh ut, rutrum mauris. Donec id augue et lectus tristique lacinia dignissim quis leo. Donec eget dignissim mi. Mauris pulvinar placerat dolor sit amet vulputate. Aliquam erat volutpat. Sed vel lacus congue, congue metus et, gravida erat."
        }
      </ModalDesc>
      <RegularButton onClick={() => webHost.openBridge("debug-home")}>
        Open
      </RegularButton>
      <RegularButton onClick={() => provide("hello")}>
        Provide Result
      </RegularButton>
    </CenterModal>
  );
}

export function DemoBridgeBottomSheetPage() {
  const bottomSheet = useModalWithItsOwnPage();
  const webHost = useWebHost();

  return (
    <BottomSheet modal={bottomSheet}>
      <VStack style={{ padding: "20px 20px 40px", gap: 18 }}>
        <ModalTitle>This is a Bottom Sheet</ModalTitle>
        <ModalDesc>
          {
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc imperdiet, dolor non pulvinar fermentum, erat augue condimentum leo, non cursus velit nisl ac felis. Nullam quis vestibulum tellus, nec commodo orci. Proin odio ante, sodales quis pellentesque sit amet, aliquam sit amet ex. Sed a velit dignissim, aliquet nibh ut, rutrum mauris. Donec id augue et lectus tristique lacinia dignissim quis leo. Donec eget dignissim mi. Mauris pulvinar placerat dolor sit amet vulputate. Aliquam erat volutpat. Sed vel lacus congue, congue metus et, gravida erat."
          }
        </ModalDesc>
      </VStack>
    </BottomSheet>
  );
}

export function ModalDemoPage() {
  const nativePage = useNativePage();
  const webHost = useWebHost();

  usePageTitle((i18n) => "Modal Demo");

  const bottomSheet = useModal("bottomSheet");
  const modal = useModal("modal");
  const bsMenu = useModal("bsMenu");
  const modalWithInput = useModal("modalWithInput");

  const showBridgeModal = useBridgeResultConsumer<string>(
    modalProvider("demo-bridge-modal"),
    (r) => {
      nativePage.alertNotice(`Got result ${r}`, "OK").catch(andLog);
      return true;
    },
    [],
  );

  const [input, setInput] = useState("");

  const designNFT = useModal("designNFT");
  return (
    <PageRoot pageData={undefined}>
      <DemoCell onClick={() => bottomSheet.open()}>Bottom Sheet</DemoCell>
      <DemoCell onClick={() => modal.open()}>Modal</DemoCell>
      <DemoCell onClick={() => modalWithInput.open()}>
        Modal with Input
      </DemoCell>
      <DemoCell onClick={() => bsMenu.open()}>
        BSMenu
        <CenterModal modal={modal}>
          <ModalTitle>
            This is a Modal Dialog, you can declare it anywhere.
          </ModalTitle>
          <ModalDesc>
            {
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc imperdiet, dolor non pulvinar fermentum, erat augue condimentum leo, non cursus velit nisl ac felis. Nullam quis vestibulum tellus, nec commodo orci. Proin odio ante, sodales quis pellentesque sit amet, aliquam sit amet ex. Sed a velit dignissim, aliquet nibh ut, rutrum mauris. Donec id augue et lectus tristique lacinia dignissim quis leo. Donec eget dignissim mi. Mauris pulvinar placerat dolor sit amet vulputate. Aliquam erat volutpat. Sed vel lacus congue, congue metus et, gravida erat."
            }
          </ModalDesc>
          <RegularButton onClick={() => bsMenu.open()}>
            Prompt Sheet
          </RegularButton>
        </CenterModal>
      </DemoCell>
      <DemoCell onClick={() => showBridgeModal()}>Bridge Modal</DemoCell>
      <DemoCell onClick={() => designNFT.open()}>Input Modal</DemoCell>
      <DemoCell
        onClick={() =>
          webHost.openBridge("demo-bridge-bottom-sheet", { mode: "modal" })
        }
      >
        Bridge Bottom Sheet
      </DemoCell>

      <BottomSheet modal={bottomSheet}>
        <VStack style={{ padding: "20px 20px 40px", gap: 18 }}>
          <ModalTitle>This is a Bottom Sheet</ModalTitle>
          <ModalDesc>
            {
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc imperdiet, dolor non pulvinar fermentum, erat augue condimentum leo, non cursus velit nisl ac felis. Nullam quis vestibulum tellus, nec commodo orci. Proin odio ante, sodales quis pellentesque sit amet, aliquam sit amet ex. Sed a velit dignissim, aliquet nibh ut, rutrum mauris. Donec id augue et lectus tristique lacinia dignissim quis leo. Donec eget dignissim mi. Mauris pulvinar placerat dolor sit amet vulputate. Aliquam erat volutpat. Sed vel lacus congue, congue metus et, gravida erat."
            }
          </ModalDesc>
        </VStack>
      </BottomSheet>
      <CenterModal modal={modalWithInput}>
        <ModalTitle>This is a Modal Dialog with Text Input</ModalTitle>
        <ModalDesc>
          {
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc imperdiet, dolor non pulvinar fermentum, erat augue condimentum leo, non cursus velit nisl ac felis. Nullam quis vestibulum tellus, nec commodo orci. Proin odio ante, sodales quis pellentesque sit amet, aliquam sit amet ex. Sed a velit dignissim, aliquet nibh ut, rutrum mauris. Donec id augue et lectus tristique lacinia dignissim quis leo. Donec eget dignissim mi. Mauris pulvinar placerat dolor sit amet vulputate. Aliquam erat volutpat. Sed vel lacus congue, congue metus et, gravida erat."
          }
        </ModalDesc>
        <RegularInputGroup
          value={input}
          updateValue={setInput}
          label="Label"
          maxLength={10}
        />
      </CenterModal>
      <BSMenu modal={bsMenu} title={"The Title"}>
        <BSMenuItem
          title={"Menu 1"}
          onClick={() => {
            nativePage.successHud("Menu 1 clicked").catch(andLog);
          }}
        />
        <BSMenuItem
          title={"Menu 2"}
          onClick={() => {
            nativePage.successHud("Menu 2 clicked").catch(andLog);
          }}
        />
        <BSMenuItem
          title={"dontCloseOnClick"}
          trailingTitle={"Trailing Title"}
          dontCloseOnClick={true}
          onClick={() => {
            nativePage.successHud("dontCloseOnClick Clicked").catch(andLog);
          }}
        />
      </BSMenu>
    </PageRoot>
  );
}
