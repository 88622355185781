import { PageRoot } from "../../components/PageRoot";
import React, { useEffect, useRef, useState } from "react";
import { usePageTitle } from "../../hooks/usePageTitle";

export function AutoFitTextPage() {
  return (
    <PageRoot pageData={undefined}>
      <div style={{ width: "100%", border: "1px solid black" }}>
        <AutoResizeText text="这ksadkokokokokokokokokokokokokoko" />
      </div>
    </PageRoot>
  );
}

interface AutoResizeTextProps {
  text: string;
  maxFontSize?: number;
  minFontSize?: number;
  className?: string;
  indicatorStyle?: React.CSSProperties;
}

export const AutoResizeText: React.FC<AutoResizeTextProps> = ({
  text,
  maxFontSize = 16,
  minFontSize = 5,
  className = "",
  indicatorStyle,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLSpanElement>(null);
  const [fontSize, setFontSize] = useState(maxFontSize);

  useEffect(() => {
    const resizeText = () => {
      const containerWidth = containerRef.current?.offsetWidth || 0;
      let textWidth = textRef.current?.offsetWidth || 0;

      if (containerWidth === 0 || textWidth === 0) {
        return;
      }

      let newFontSize = fontSize;

      // Reduce font size until text fits within container
      while (textWidth > containerWidth && newFontSize > minFontSize) {
        newFontSize -= 1;
        textRef.current!.style.fontSize = `${newFontSize}px`;
        textWidth = textRef.current!.offsetWidth;
      }

      // Increase font size until text exceeds container
      while (textWidth <= containerWidth && newFontSize < maxFontSize) {
        newFontSize += 1;
        textRef.current!.style.fontSize = `${newFontSize}px`;
        if (textRef.current!.offsetWidth > containerWidth) {
          newFontSize -= 1;
          break;
        }
        textWidth = textRef.current!.offsetWidth;
      }

      setFontSize(newFontSize);
    };

    resizeText();
    window.addEventListener("resize", resizeText);

    return () => {
      window.removeEventListener("resize", resizeText);
    };
  }, [fontSize, maxFontSize, minFontSize]);

  useEffect(() => {
    setFontSize(maxFontSize);
  }, [text, maxFontSize]);

  useEffect(() => {
    setFontSize(maxFontSize);
  }, [text, maxFontSize]);

  return (
    <div
      ref={containerRef}
      className={className}
      style={{
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
      }}
    >
      <span
        ref={textRef}
        style={{
          fontSize: `${fontSize}px`,
          whiteSpace: "nowrap",
          alignItems: "center",
          alignSelf: "center",
          ...indicatorStyle,
        }}
      >
        {text}
      </span>
    </div>
  );
};
