// This file is generated by xml2ts.py. Don't change


import {ReactElement} from "react";
type I18nReactElement = ReactElement & {__i18nBrand: "I18nReactElement"};
type I18nResult<A> = ReactElement extends A ? I18nReactElement : string;


const pluralRules = new Intl.PluralRules("pt-BR")
export function getString_pt_BR(
  strBuilder: (format: string, ...args: (ReactElement|string|number)[]) => unknown
) {
  return {
    app_name_projz_z: ()=>`Project Z`,
    app_name_spongekit_z: ()=>`SpongeKit`,
    auth_log_out: ()=>`Sair`,
    cash_rewards_day_number: <A0 extends ReactElement | number>(arg0: A0)=>strBuilder(`Dia %d`, arg0) as I18nResult<A0>,
    clover_migrate_migrated_info: ()=>`Sua conta migrou para o nosso novo aplicativo Clover.Space. Por favor, use o Clover.Space para continuar aproveitando nossos serviços. O Project Z deixará de ter suporte em breve.`,
    clover_migrate_open_clover: ()=>`Atualize Aplicativo`,
    clover_migrate_phase_1_info: ()=>`Notícias empolgantes! Estamos felizes em anunciar que você está entre os primeiros a receber um convite exclusivo para visualizar nosso novo aplicativo, Clover.Space! Seu feedback é inestimável para nós, e estamos ansiosos para ouvir suas ideias. Obrigado por fazer parte da nossa jornada!`,
    clover_migrate_phase_1_title: ()=>`Clover.Space Visualização`,
    clover_migrate_phase_2_info: ()=>`Nova atualização! Estamos felizes em apresentar nosso novo aplicativo - **Clover.Space**. Atualize o aplicativo para uma experiência nova. Obrigado por fazer parte da nossa jornada!`,
    clover_migrate_phase_2_title: ()=>`Apresentando o Clover.Space`,
    clover_migrate_phase_3_info_new: ()=>`Estamos felizes em apresentar nosso novo aplicativo - **Clover.Space**. Você precisa atualizar o aplicativo para continuar usando nossos serviços. Obrigado por fazer parte da nossa jornada!`,
    clover_migrate_phase_3_title: ()=>`Apresentando o Clover.Space`,
    clover_upgrade_app: ()=>`Upgrade App`,
    compose_confirmation: ()=>`Sair da edição?`,
    done: ()=>`Concluído`,
    help_split_appeal: ()=>`Apelo`,
    loading: ()=>`Carregando…`,
    mars_account_disabled: ()=>`Conta Desativada`,
    mars_account_restricted: ()=>`Conta Restrita`,
    mars_account_waring: ()=>`Aviso`,
    mars_bonus_card_title: <A0 extends ReactElement | number>(arg0: A0)=>strBuilder(`%d DIAS SEGUIDOS`, arg0) as I18nResult<A0>,
    mars_bonus_missed: ()=>`PERDIDO`,
    mars_check_gift_detail: ()=>`Ver Detalhe do Presente>>`,
    mars_cloud_box: ()=>`Caixa Cloud`,
    mars_journey_rule_desc: ()=>`Para obter o seu sinal de verificação do dia, você precisa se envolver em pelo menos 10 rodadas de conversa. Uma “rodada” de conversa é definida como uma mensagem de ida e volta de cada participante.


Para esclarecer:

- O participante A envia uma mensagem

- O participante B responde à mensagem.

Esta é considerada uma rodada de conversa.


Ao longo da sua jornada, você tem 4 checkpoints. Quando você chegar a um checkpoint e tiver obtido um sinal de verificação a cada dia desde o checkpoint anterior, você receberá um Presente.`,
    mars_journey_rule_title: ()=>`Instruções da Jornada de Amizade de 30 Dias`,
    mars_mbox_desc: ()=>`A caixa inclui exatamente um dos itens acima`,
    mars_mbox_how_to_claim: <A0 extends ReactElement | string, A1 extends ReactElement | string>(arg0: A0, arg1: A1)=>strBuilder(`Você precisa obter um sinal de verificação todos os dias de %s a %s para resgatar a Caixa de Presente.`, arg0, arg1) as I18nResult<A0|A1>,
    mars_mbox_open: ()=>`Abrir`,
    mars_mystery_boxes: ()=>`Caixas Misteriosas`,
    mars_mystery_boxes_subtitle: ()=>`Da Jornada de Amizade de 30 Dias`,
    mars_palm_box: ()=>`Caixa Palm`,
    mars_rainbow_box: ()=>`Caixa Rainbow`,
    mars_see_more: ()=>`Ver Mais`,
    mars_sys_msg_center: ()=>`Central de Mensagens do Sistema`,
    mars_sys_msg_post_disabled: ()=>`Post Desativado`,
    mars_the_day_of: <A0 extends ReactElement | number>(arg0: A0)=>strBuilder(`o Dia %d`, arg0) as I18nResult<A0>,
    mars_wave_box: ()=>`Caixa Wave`,
    meet_now_try_again: ()=>`Tente Novamente`,
    mod_appeal_view_original_content: ()=>`Exibir Conteúdo Original`,
    moon_ads_explore_memecoins: ()=>`Explorar Memecoins`,
    moon_ads_get_for_free: <A0 extends ReactElement | string>(arg0: A0)=>strBuilder(`Obtenha %s Gratuitamente!`, arg0) as I18nResult<A0>,
    moon_ads_just_earned: <A0 extends ReactElement | string, A1 extends ReactElement | string>(arg0: A0, arg1: A1)=>strBuilder(`%s ganhou %s`, arg0, arg1) as I18nResult<A0|A1>,
    moon_ads_market_cap: ()=>`Valor de Mercado`,
    moon_ads_truncate_elipsis: <A0 extends ReactElement | string>(arg0: A0)=>strBuilder(`%s…`, arg0) as I18nResult<A0>,
    no_content_yet: ()=>`Nenhum Conteúdo Ainda`,
    oct12_text_continue_editing: ()=>`Continuar editando`,
    ok: ()=>`OK`,
    quit: ()=>`Sair`,
    retry: ()=>`Tentar Novamente`,


    plural: (q: number) => {
      return {
        mars_journey_condition_to_claim: <A0 extends ReactElement | number>(arg0: A0) => {
          const pluralMap = new Map([
            ["one", `Você precisa obter um sinal de verificação por %d dia consecutivo para abrir a Caixa de Presente`],
            ["other", `Você precisa obter um sinal de verificação por %d dias consecutivos para abrir a Caixa de Presente`],
          ])
          return strBuilder(pluralMap.get(pluralRules.select(q)) ?? pluralMap.get("other") ?? "missing", arg0)  as I18nResult<A0>;
        },
      };
    }
  }
}