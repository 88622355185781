import { Bridge, getBridgeHook } from "../bridge/Bridge";
import { WebHost, WebHostImpl } from "../bridge/WebHost";
import { Backend, BackendClient } from "../bridge/Backend";
import { NativePage } from "../bridge/NativePage";
import { NativeModal } from "../bridge/NativeModal";
import { base64ToAny } from "../utils/Blob";
import { WebHostData } from "../bridge/WebHostData";
import { useMemo } from "react";

let bridge: Bridge;
let webHostData: WebHostData;

function ensureBridge() {
  if (!bridge) {
    bridge = new Bridge(window);
  }

  return bridge;
}

function getWebHostDataBase64(window: any) {
  if ("webHostDataProvider" in window) {
    const provider = window["webHostDataProvider"];
    if ("getWebHostDataBase64" in provider) {
      return provider["getWebHostDataBase64"]();
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
}

function ensureWebHostData() {
  if (!webHostData) {
    const webHostDataBase64 = getWebHostDataBase64(window);
    webHostData = webHostDataBase64
      ? (base64ToAny(webHostDataBase64) as WebHostData)
      : {
          isSpongeKit: false,
          loggedInUserId: BigInt(0),
          webHostCreatedTime: 0,
          webHostId: "",
          savedStates: [],
        };
  }

  return webHostData;
}

let webHost: WebHost;

export function useWebHost(): WebHost {
  if (!webHost) {
    webHost = new WebHost(
      getBridgeHook<WebHostImpl>(ensureBridge(), "webHost"),
      ensureWebHostData(),
    );
  }
  return webHost;
}

let nativePage: NativePage;

function ensureNativePage() {
  if (!nativePage) {
    nativePage = getBridgeHook<NativePage>(ensureBridge(), "nativePage");
  }
  return nativePage;
}

export function useNativePage(): NativePage {
  return ensureNativePage();
}

export function useNativePageOrNull() {
  const bridge = ensureBridge();
  if (bridge.hasHook("nativePage")) {
    return ensureNativePage();
  } else {
    return null;
  }
}

let backend: Backend;

export function useBackend() {
  if (!backend) {
    backend = new Backend(
      getBridgeHook<BackendClient>(ensureBridge(), "backend"),
    );
  }

  return backend;
}

let toDaMoon: Backend;

export function useToDaMoon() {
  if (!toDaMoon) {
    toDaMoon = new Backend(
      getBridgeHook<BackendClient>(ensureBridge(), "todamoon"),
    );
  }

  return toDaMoon;
}

let nativeModal: NativeModal;

function ensureNativeModal() {
  if (!nativeModal) {
    nativeModal = getBridgeHook<NativeModal>(ensureBridge(), "nativeModal");
  }
  return nativeModal;
}

export function useNativeModal(): NativeModal {
  return ensureNativeModal();
}

export function usePageCloser() {
  const bridge = ensureBridge();

  return useMemo(() => {
    if (bridge.hasHook("nativeModal")) {
      return {
        close: () => ensureNativeModal().dismiss(),
      };
    } else if (bridge.hasHook("nativePage")) {
      return {
        close: () => ensureNativePage().navigateBack(),
      };
    } else {
      return {
        close: () => {
          return Promise.resolve();
        },
      };
    }
  }, [bridge]);
}
