import { JSONUtil } from "./JSONUtil";

function arrayBufferToBase64(arrayBuffer: ArrayBuffer) {
  const uint8Array = new Uint8Array(arrayBuffer);
  const binaryString = Array.from(uint8Array)
    .map((byte) => String.fromCharCode(byte))
    .join("");
  return btoa(binaryString);
}

function base64ToArrayBuffer(base64String: string) {
  const binaryString = atob(base64String);
  const length = binaryString.length;
  const uint8Array = new Uint8Array(length);

  for (let i = 0; i < length; i++) {
    uint8Array[i] = binaryString.charCodeAt(i);
  }

  return uint8Array.buffer;
}

export function utf8ToBase64(str: string) {
  return arrayBufferToBase64(new TextEncoder().encode(str).buffer);
}

export function base64ToUtf8(base64: string) {
  return new TextDecoder().decode(base64ToArrayBuffer(base64));
}

export function anyToBase64(value: any) {
  return utf8ToBase64(JSONUtil.stringify(value));
}

export function base64ToAny(base64: string) {
  return JSONUtil.parse(base64ToUtf8(base64));
}
