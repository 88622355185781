import styled, { RuleSet } from "styled-components";
import Markdown, { compiler, MarkdownToJSX } from "markdown-to-jsx";
import {
  Children,
  cloneElement,
  CSSProperties,
  isValidElement,
  PropsWithChildren,
  ReactElement,
  ReactNode,
} from "react";
import { getUniqueId } from "../utils/UniqueId";
import { PropsFrom } from "../utils/typeUtils";
import { I18nReactElement } from "../hooks/useI18n";

function flattenReactElement(element: ReactElement) {
  const restoreInfo = new Map<string, ReactElement>();
  const mapped =
    Children.map(element.props.children, (c: {} | null | undefined) => {
      if (c === null || c === undefined) {
        return "";
      } else if (isValidElement(c)) {
        const id = `Element-${getUniqueId()}`;
        restoreInfo.set(id, c);
        return id;
      } else {
        return c.toString();
      }
    }) ?? [];

  return [mapped.join(""), restoreInfo] as const;
}

type Match = { index: number; substring: string };

function findFirstOccurrence(str: string, substrings: string[]): Match | null {
  let minIndex: number = -1;
  let matchedSubstring: string | null = null;

  substrings.forEach((sub) => {
    const index = str.indexOf(sub);
    if (index !== -1 && (minIndex === -1 || index < minIndex)) {
      minIndex = index;
      matchedSubstring = sub;
    }
  });

  if (minIndex === -1 || matchedSubstring === null) {
    return null;
  }

  return { index: minIndex, substring: matchedSubstring };
}

function splitWithSubstrings(str: string, substrings: string[]): string[] {
  const result: string[] = [];
  let remainingStr = str;

  while (remainingStr.length > 0) {
    const match = findFirstOccurrence(remainingStr, substrings);

    if (match === null) {
      // No more substrings found, add the remaining string and break
      result.push(remainingStr);
      break;
    }

    const { index, substring } = match;

    // Add the part before the found substring, if any
    if (index > 0) {
      result.push(remainingStr.slice(0, index));
    }

    // Add the found substring
    result.push(substring);

    // Update the remaining string
    remainingStr = remainingStr.slice(index + substring.length);
  }

  return result;
}

function unflattenReactElements(
  text: string,
  restoreInfo: Map<string, ReactElement>,
) {
  const split = splitWithSubstrings(text, Array.from(restoreInfo.keys()));
  if (split.length === 1) {
    return text;
  }

  return split.map((s) => {
    const e = restoreInfo.get(s);
    return e === undefined ? s : e;
  });
}

function restoreReactNode(
  node: ReactNode,
  restoreInfo: Map<string, ReactElement>,
): ReactNode {
  if (isValidElement<PropsWithChildren>(node)) {
    return restoreReactElement(node, restoreInfo);
  } else if (typeof node === "string") {
    return unflattenReactElements(node, restoreInfo);
  } else {
    return node;
  }
}

function restoreReactElement(
  element: ReactElement,
  restoreInfo: Map<string, ReactElement>,
) {
  return cloneElement(
    element,
    undefined,
    Children.map(element.props.children, (c) =>
      restoreReactNode(c, restoreInfo),
    ),
  );
}

export function PowerMarkdown(props: {
  style?: Pick<CSSProperties, "margin" | "marginTop">;
  textStyle?: RuleSet<Object>;
  options?: PropsFrom<typeof Markdown>["options"];
  children: I18nReactElement | string;
}) {
  const options: MarkdownToJSX.Options = {
    ...props.options,
    forceBlock: true,
    overrides: props.textStyle
      ? {
          p: styled.p`
            ${props.textStyle}
          `,
          li: styled.li`
            ${props.textStyle}
          `,
          span: styled.span`
            ${props.textStyle}
          `,
        }
      : undefined,
  };
  let comp;
  if (typeof props.children === "string") {
    comp = <Markdown options={options}>{props.children}</Markdown>;
  } else {
    const [flattened, restoreInfo] = flattenReactElement(props.children);
    const component = compiler(flattened, options);
    comp = restoreReactElement(component, restoreInfo);
  }

  if (props.style) {
    return <div style={props.style}>{comp}</div>;
  } else {
    return comp;
  }
}
