import React, { CSSProperties, PropsWithChildren, useContext } from "react";
import { HStackMixin, Spring } from "./VStack";
import { useSafeAreaInsets } from "../hooks/useSafeAreaInsets";
import styled from "styled-components";
import { VScroll } from "./vscroll/VScroll";
import { BottomSheet, BottomSheetProps } from "./Modal";
import itemCheck from "../res/images/menu_item_check.svg";
import {
  cell_on_pressed,
  full_page_width_cell,
  hPaddingWithPageInset,
} from "./CommonStyles";
import { ModalContext } from "./ModalContext";
import { SingleLineUnspecifiedWidth } from "./CommonViews";
import { Media } from "../proto/Media";
import { Image, MediaSizeMode } from "./Image";
import { AbsImage } from "./AbsImage";
import close from "../res/images/ic_modal_close.svg";
import { removeOptional } from "../utils/typeUtils";

export const MenuItemTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  text-align: start;
  color: var(--color-text00);
  ${SingleLineUnspecifiedWidth}
`;

export const MenuItem = styled.div<{ disabled?: boolean }>`
  ${HStackMixin};
  padding: 24px 0;
  ${full_page_width_cell};
  gap: 12px;
  opacity: ${(p) => (p.disabled ? 0.2 : 1)};
  ${(p) => (p.disabled ? "" : cell_on_pressed)}
`;

export const BSMenuTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  padding-bottom: 18px;
  padding-top: 10px;

  color: var(--color-text00);
`;

type BSMenuItemCellProps = {
  disabled?: boolean;
  dontCloseOnClick?: boolean; // default to yes
  onClick?: () => void;
};

export function BSMenuItemCell(props: PropsWithChildren<BSMenuItemCellProps>) {
  const modalContext = useContext(ModalContext);

  return (
    <MenuItem
      disabled={props.disabled}
      onClick={() => {
        if (props.disabled) return;
        if (props.dontCloseOnClick) {
          if (props.onClick) {
            props.onClick();
          }
        } else {
          modalContext.modal.close(props.onClick);
        }
      }}
    >
      {props.children}
    </MenuItem>
  );
}

export function BSMenuItem(
  props: {
    icon?: readonly [Media | undefined, MediaSizeMode] | string | undefined;
    iconStyle?: Pick<
      CSSProperties,
      "width" | "height" | "borderRadius" | "objectFit"
    >;
    titleStyle?: Pick<CSSProperties, "fontSize" | "fontWeight">;
    trailingTitle?: string;
    title: string | undefined;
    selected?: boolean;
  } & BSMenuItemCellProps,
) {
  return (
    <BSMenuItemCell {...props}>
      {props.icon && (
        <Image
          src={props.icon}
          width={24}
          height={24}
          style={{ objectFit: "contain", ...props.iconStyle }}
        />
      )}
      <MenuItemTitle style={props.titleStyle}>{props.title}</MenuItemTitle>
      <Spring />
      {props.trailingTitle && (
        <MenuItemTitle>{props.trailingTitle}</MenuItemTitle>
      )}
      {props.selected && <Image src={itemCheck}></Image>}
    </BSMenuItemCell>
  );
}

export function BSMenu(
  props: PropsWithChildren<{ title?: string } & BottomSheetProps>,
) {
  const safeAreaInsets = useSafeAreaInsets();
  const showCloseButton = props.hideCloseButton === false;
  return (
    <BottomSheet modal={props.modal} onClickBg={props.onClickBg}>
      {!!props.title && <BSMenuTitle>{props.title}</BSMenuTitle>}
      {showCloseButton && (
        <AbsImage
          src={close}
          style={{ top: 12, right: 12 }}
          onClick={removeOptional(props.modal.close)}
        />
      )}
      <VScroll
        style={{
          paddingBottom: safeAreaInsets.bottom + 18,
          ...hPaddingWithPageInset,
          width: "100%",
          maxHeight: `calc(80vh - ${safeAreaInsets.top}px)`,
          gap: props.itemsGap,
        }}
      >
        {props.children}
      </VScroll>
    </BottomSheet>
  );
}
