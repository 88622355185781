import { SliderDemoPage } from "./SliderDemoPage";
import { ButtonDemoPage } from "./ButtonDemoPage";
import { HapticDemoPage } from "./HapticDemoPage";
import { HooksDemoPage } from "./HooksDemoPage";
import {
  DemoBridgeBottomSheetPage,
  DemoBridgeModalPage,
  ModalDemoPage,
} from "./ModalDemoPage";
import { ComponentsDemoPage } from "./ComponentsDemoPage";
import { ChartDemoPage } from "./ChartDemoPage";
import { InputDemoPage } from "./InputDemoPage";
import React from "react";
import { ContentGroupDemoPage } from "./ContentGroupDemoPage";
import { ImageViewDemoPage } from "./ImageViewDemoPage";
import { SpeedTitleDemoPage } from "./SpeedTitleDemoPage";
import { PressedEffectDemoPage } from "./PressedEffectDemoPage";
import { InstructionDemoPage } from "./InstructionDemoPage";
import { PowerMarkdownDemoPage } from "./PowerMarkdownDemoPage";
import { PageBgDemoPage } from "./PageBgDemoPage";
import { WebHostStateDemoPage } from "./WebHostStateDemoPage";
import { CountDownDemoPage } from "./CountDownDemoPage";
import { SimpleListDemoPage } from "./SimpleListDemoPage";
import { PageArchDemoPage } from "./PageArchDemoPage";
import { SimpleDataDemoPage } from "./SimpleDataDemoPage";
import { FilterTabDemoPage } from "./FilterTabDemoPage";
import { PageFooterDemoPage } from "./PageFooterDemoPage";
import { AutoFitTextPage } from "./AutoFitTextPage";

export const debugPagesRoute = [
  {
    path: "/slider-demo",
    element: <SliderDemoPage />,
  },

  {
    path: "/button-demo",
    element: <ButtonDemoPage />,
  },
  {
    path: "/imageview-demo",
    element: <ImageViewDemoPage />,
  },
  {
    path: "/haptic-demo",
    element: <HapticDemoPage />,
  },
  {
    path: "/modal-demo",
    element: <ModalDemoPage />,
  },
  {
    path: "/components-demo",
    element: <ComponentsDemoPage />,
  },
  {
    path: "/chart-demo",
    element: <ChartDemoPage />,
  },
  {
    path: "/input-demo",
    element: <InputDemoPage />,
  },
  {
    path: "/demo-bridge-modal",
    element: <DemoBridgeModalPage />,
  },
  {
    path: "/demo-bridge-bottom-sheet",
    element: <DemoBridgeBottomSheetPage />,
  },

  {
    path: "/content-group-demo",
    element: <ContentGroupDemoPage />,
  },

  {
    path: "/speed-title-demo",
    element: <SpeedTitleDemoPage />,
  },

  {
    path: "/pressed-effect-demo",
    element: <PressedEffectDemoPage />,
  },

  {
    path: "/instruction-demo",
    element: <InstructionDemoPage />,
  },

  {
    path: "/power-markdown-demo",
    element: <PowerMarkdownDemoPage />,
  },

  {
    path: "/page-bg-demo",
    element: <PageBgDemoPage />,
  },

  {
    path: "/web-host-state-demo",
    element: <WebHostStateDemoPage />,
  },
  {
    path: "/hooks-demo",
    element: <HooksDemoPage />,
  },
  {
    path: "/count-down-demo",
    element: <CountDownDemoPage />,
  },
  {
    path: "/count-down-demo",
    element: <CountDownDemoPage />,
  },
  {
    path: "/page-arch-demo",
    element: <PageArchDemoPage />,
  },
  {
    path: "/simple-list-demo",
    element: <SimpleListDemoPage />,
  },
  {
    path: "/simple-data-demo",
    element: <SimpleDataDemoPage />,
  },
  {
    path: "/filter-tab-demo",
    element: <FilterTabDemoPage />,
  },
  {
    path: "/page-footer-demo",
    element: <PageFooterDemoPage />,
  },
  {
    path: "/auto-fit-text-demo",
    element: <AutoFitTextPage />,
  },
];
