import { css } from "styled-components";
import { RegularInputGroup } from "../../components/Input";
import { useSafeAreaInsets } from "../../hooks/useSafeAreaInsets";
import { h_margin_with_page_inset } from "../../components/CommonStyles";
import { useState } from "react";
import { PageRoot } from "../../components/PageRoot";

export function InputDemoPage() {
  const safeInsets = useSafeAreaInsets();
  const [input, setInput] = useState("");
  return (
    <PageRoot pageData={undefined}>
      <RegularInputGroup
        value={input}
        updateValue={setInput}
        label="Label"
        maxLength={10}
        style={css`
          ${h_margin_with_page_inset}
        `}
      />
    </PageRoot>
  );
}
