export type StateIdPrimitive = number | string | bigint | boolean;
export type StateIdArray = readonly StateId[];
export type StateIdObject = { [x: string]: StateId };

export type StateId = StateIdPrimitive | StateIdArray | StateIdObject;

export function flattenStateId(id: StateId): string {
  if (Array.isArray(id)) {
    return id.map((it) => flattenStateId(it)).join("-");
  } else {
    return `${id}`;
  }
}
