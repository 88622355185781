// This file is generated by xml2ts.py. Don't change


import {ReactElement} from "react";
type I18nReactElement = ReactElement & {__i18nBrand: "I18nReactElement"};
type I18nResult<A> = ReactElement extends A ? I18nReactElement : string;


const pluralRules = new Intl.PluralRules("es-419")
export function getString_es_419(
  strBuilder: (format: string, ...args: (ReactElement|string|number)[]) => unknown
) {
  return {
    app_name_projz_z: ()=>`Project Z`,
    app_name_spongekit_z: ()=>`SpongeKit`,
    auth_log_out: ()=>`Cerrar Sesión`,
    cash_rewards_day_number: <A0 extends ReactElement | number>(arg0: A0)=>strBuilder(`Día %d`, arg0) as I18nResult<A0>,
    clover_migrate_migrated_info: ()=>`Tu cuenta ha sido migrada a nuestra nueva app, Clover.Space. Usa Clover.Space para seguir disfrutando de nuestros servicios. Project Z pronto dejará de estar disponible.`,
    clover_migrate_open_clover: ()=>`Actualiza Tu App`,
    clover_migrate_phase_1_info: ()=>`¡Grandes noticias! Nos emociona anunciarte que estás entre los primeros en recibir una invitación exclusiva para probar nuestra nueva app, Clover.Space. Tu opinión es invaluable para nosotros, y estamos deseando escuchar tus comentarios. ¡Gracias por acompañarnos en esta aventura!`,
    clover_migrate_phase_1_title: ()=>`Preview de Clover.Space`,
    clover_migrate_phase_2_info: ()=>`¡Nueva actualización! Estamos encantados de presentar nuestra nueva aplicación: **Clover.Space**. Actualiza la aplicación para disfrutar de una nueva experiencia. ¡Gracias por sea parte de nuestra jornada!`,
    clover_migrate_phase_2_title: ()=>`Te Presentamos Clover.Space`,
    clover_migrate_phase_3_info_new: ()=>`Estamos encantados de presentar nuestra nueva aplicación: **Clover.Space**. Por favor, actualiza la versión de tu app para continuar utilizando nuestros servicios. ¡Gracias por sea parte de nuestra jornada!`,
    clover_migrate_phase_3_title: ()=>`Te Presentamos Clover.Space`,
    clover_upgrade_app: ()=>`Upgrade App`,
    compose_confirmation: ()=>`¿Deseas dejar de editar?`,
    done: ()=>`Listo`,
    help_split_appeal: ()=>`Apelar`,
    loading: ()=>`Cargando…`,
    mars_account_disabled: ()=>`Cuenta Deshabilitada`,
    mars_account_restricted: ()=>`Cuenta restringida`,
    mars_account_waring: ()=>`Advertencia`,
    mars_bonus_card_title: <A0 extends ReactElement | number>(arg0: A0)=>strBuilder(`RACHA DE %d DÍAS`, arg0) as I18nResult<A0>,
    mars_bonus_missed: ()=>`FALTA`,
    mars_check_gift_detail: ()=>`Ver Detalles del Regalo>>`,
    mars_cloud_box: ()=>`Caja Nube`,
    mars_journey_rule_desc: ()=>`Para conseguir tu Visto bueno del día, necesitas tener al menos 10 rondas de conversación. Una “ronda” se define como un mensaje contestado de cada participante.


Es decir:

- Participante A envía un mensaje

- Participante B responde a ese mensaje.

Esto es una “ronda” de conversación.



En tu Recorrido tendrás 4 Hitos. Al alcanzar cada uno de ellos y conseguir el Visto bueno cada día desde el Hito anterior, recibirás un regalo.`,
    mars_journey_rule_title: ()=>`Instrucciones del Recorrido Amistad 30 Días`,
    mars_mbox_desc: ()=>`El interior contiene exactamente uno de estos`,
    mars_mbox_how_to_claim: <A0 extends ReactElement | string, A1 extends ReactElement | string>(arg0: A0, arg1: A1)=>strBuilder(`Necesitas el Visto bueno durante cada día de %s a %s para reclamar el Regalo.`, arg0, arg1) as I18nResult<A0|A1>,
    mars_mbox_open: ()=>`Abrir`,
    mars_mystery_boxes: ()=>`Cajas Mysteries`,
    mars_mystery_boxes_subtitle: ()=>`De Recorrido Amistad 30 Días`,
    mars_palm_box: ()=>`Caja Palmera`,
    mars_rainbow_box: ()=>`Caja Arcoíris`,
    mars_see_more: ()=>`Ver Más`,
    mars_sys_msg_center: ()=>`Centro de Mensajes del Sistema`,
    mars_sys_msg_post_disabled: ()=>`Publicación Deshabilitada`,
    mars_the_day_of: <A0 extends ReactElement | number>(arg0: A0)=>strBuilder(`el día %d`, arg0) as I18nResult<A0>,
    mars_wave_box: ()=>`Caja Ola`,
    meet_now_try_again: ()=>`Intentar de nuevo`,
    mod_appeal_view_original_content: ()=>`Ver Contenido Original`,
    moon_ads_explore_memecoins: ()=>`Explorar Memecoins`,
    moon_ads_get_for_free: <A0 extends ReactElement | string>(arg0: A0)=>strBuilder(`¡Obtén %s gratis!`, arg0) as I18nResult<A0>,
    moon_ads_just_earned: <A0 extends ReactElement | string, A1 extends ReactElement | string>(arg0: A0, arg1: A1)=>strBuilder(`%s ganó %s`, arg0, arg1) as I18nResult<A0|A1>,
    moon_ads_market_cap: ()=>`Market Cap`,
    moon_ads_truncate_elipsis: <A0 extends ReactElement | string>(arg0: A0)=>strBuilder(`%s…`, arg0) as I18nResult<A0>,
    no_content_yet: ()=>`Aún no hay contenido`,
    oct12_text_continue_editing: ()=>`Seguir editando`,
    ok: ()=>`Aceptar`,
    quit: ()=>`Abandonar`,
    retry: ()=>`Reintentar`,


    plural: (q: number) => {
      return {
        mars_journey_condition_to_claim: <A0 extends ReactElement | number>(arg0: A0) => {
          const pluralMap = new Map([
            ["one", `Necesitas el Visto bueno durante %d día para abrir el Regalo.`],
            ["other", `Necesitas el Visto bueno durante %d días para abrir el Regalo.`],
          ])
          return strBuilder(pluralMap.get(pluralRules.select(q)) ?? pluralMap.get("other") ?? "missing", arg0)  as I18nResult<A0>;
        },
      };
    }
  }
}