import { StateId } from "../StateId";
import { useSWRList } from "./useSWRList";
import { omit } from "../../utils/pick";
import { AlignUndefined } from "../../utils/Nullable";
import { SWRAccum } from "./SWRAccum";

export interface PagedList<E extends {}> {
  list: E[];
  pagination: {
    nextPageToken?: string;
  };
}

export interface CursorAndSize {
  pageToken: string | null;
  size: number;
}

export function useSWRArray<
  E extends {},
  ID extends StateId | undefined = StateId,
>(
  contentId: ID,
  fetcher: (pageParam: CursorAndSize) => Promise<PagedList<E>>,
  config?: Parameters<typeof useSWRList>[2],
): AlignUndefined<SWRAccum<E[]>, ID> {
  const res = useSWRList(contentId, fetcher, config);
  if (res === undefined) {
    return undefined as AlignUndefined<SWRAccum<E[]>, ID>;
  } else {
    return { content: res.content?.list ?? [], ...omit(res, ["content"]) };
  }
}
