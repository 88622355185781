import styled, { css, RuleSet } from "styled-components";
import { HStack, VStack } from "./VStack";
import { useId, useMemo } from "react";
import Grapheme from "grapheme-splitter";

const Label = styled.label<{ mixin?: RuleSet<Object> }>`
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;

  opacity: 0.8;
  ${(p) => p.mixin}
`;

const Input = styled.input<{ mixin?: RuleSet<Object> }>`
  display: flex;
  height: 50px;
  align-items: center;
  flex-shrink: 0;
  padding: 0 16px;
  align-self: stretch;
  background: none;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;

  border: none;
  ${(p) => p.mixin}
`;

const CharCount = styled.div`
  font-size: 12px;
  flex-shrink: 0;
  margin-right: 16px;
`;

type CurrentCountProps = {
  $isOverLimit: boolean;
};

const CurrentCount = styled.span<CurrentCountProps>`
  color: ${(props) => (props.$isOverLimit ? "red" : "rgba(255,255,255,0.4)")};
`;

const MaxCount = styled.span`
  color: rgba(255, 255, 255, 0.4);
`;

export function RegularInputGroup(props: {
  style?: RuleSet<Object>;
  value?: string;
  label: string;
  maxLength: number;
  updateValue: (newValue: string) => void;
}) {
  const id = useId();
  const textLength = useMemo(() => {
    const TextLengthHelper = new Grapheme();
    return TextLengthHelper.splitGraphemes(props.value ?? "").length;
  }, [props.value]);
  const maxLength = props.maxLength;
  return (
    <VStack
      mixin={css`
        align-items: flex-start;
        ${props.style}
      `}
    >
      <Label htmlFor={id}>{props.label}</Label>

      <HStack
        mixin={css`
          border-radius: 6px;
          background: rgba(255, 255, 255, 0.08);
          align-self: stretch;
          margin-top: 12px;
        `}
      >
        <Input
          value={props.value}
          onChange={(e) => props.updateValue(e.target.value)}
          id={id}
          mixin={css`
            flex-grow: 1;
          `}
        />
        <CharCount>
          <CurrentCount $isOverLimit={textLength > maxLength}>
            {textLength}
          </CurrentCount>
          <MaxCount>{"/" + maxLength}</MaxCount>
        </CharCount>
      </HStack>
    </VStack>
  );
}
