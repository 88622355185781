import json_bigint from "json-bigint";

const JSONbigNative = json_bigint({});

export const JSONUtil = {
  parse(str: string): any {
    return JSONbigNative.parse(str);
  },

  stringify(obj: any): string {
    return JSONbigNative.stringify(obj);
  },
};
