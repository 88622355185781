import React, { useState } from "react";
import { Slider } from "../../components/Slider";
import { VSpace } from "../../components/VStack";
import { PageRoot } from "../../components/PageRoot";
import { DemoCell } from "./DemoCell";
import { useWebHostState } from "../../hooks/useWebHostState";

export function SliderDemoPage() {
  const [sliderValue, setSliderValue] = useWebHostState("sliderValue", 20);

  const [sliderValue2, setSliderValue2] = useState(80);
  return (
    <PageRoot pageData={undefined}>
      <VSpace height={200} style={{ backgroundColor: "lightblue" }} />
      <DemoCell>{sliderValue}</DemoCell>
      <Slider
        style={{ margin: "20px 20px" }}
        key={"Slider"}
        min={0}
        max={100}
        numberOfMarks={5}
        value={sliderValue}
        onValueChange={setSliderValue}
        labels={["weak", "middle", "strong"]}
      />
      <DemoCell>{sliderValue2}</DemoCell>
      <Slider
        style={{ margin: "20px 20px" }}
        key={"Slider2"}
        min={0}
        max={100}
        numberOfMarks={5}
        value={sliderValue2}
        onValueChange={setSliderValue2}
        labels={["weak", "middle", "strong"]}
      />
      <VSpace height={1000} style={{ backgroundColor: "gray" }} />
    </PageRoot>
  );
}
