import React, {
  CSSProperties,
  forwardRef,
  PropsWithChildren,
  TouchEvent,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  isAnyRefreshTargetLoading,
  RefreshTarget,
  refreshTargetLoadAll,
} from "./RefreshTarget";
import { MaterialRefreshControl } from "./MaterialRefreshControl";
import { getOS } from "../../utils/deviceModel";
import { CircleRefreshControl } from "./CircleRefreshControl";
import { useSavedScroll } from "../../hooks/useSavedScroll";
import styled, { RuleSet } from "styled-components";
import { useImeTranslate } from "../../hooks/useImeTranslate";
import { andLog } from "../handleError";
import { useWebHost } from "../../hooks/useBridge";

export const VScrollable = styled.div<{ mixin?: RuleSet<Object> }>`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: stretch;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  ${(p) => p.mixin}
`;

export type VScrollProps = {
  style?: Pick<
    CSSProperties,
    | "padding"
    | "paddingBottom"
    | "width"
    | "maxHeight"
    | "paddingLeft"
    | "paddingRight"
    | "gap"
  >;
  refreshControlType?: "material" | "material_large" | "classic_circle";
  refreshTarget?: RefreshTarget | RefreshTarget[];
  onScroll?: OnScrollTarget | OnScrollTarget[];
};

export type LoadMoreObject = {
  readonly loadMore: () => void;
};

export type OnScrollCallback = (event: React.UIEvent<HTMLElement>) => void;

export type OnScrollTarget = LoadMoreObject | OnScrollCallback;

export function isLoadMoreObject(
  refresh: OnScrollTarget,
): refresh is LoadMoreObject {
  return (refresh as any).loadMore;
}

function doOnScrollAll(
  target: OnScrollTarget | OnScrollTarget[],
  event: React.UIEvent<HTMLElement>,
) {
  if (Array.isArray(target)) {
    target.forEach((t) => doOnScroll(t, event));
  } else {
    doOnScroll(target, event);
  }
}

function doOnScroll(target: OnScrollTarget, event: React.UIEvent<HTMLElement>) {
  if (isLoadMoreObject(target)) {
    const eventTarget = event.currentTarget;
    if (
      eventTarget.scrollHeight -
        (eventTarget.scrollTop + eventTarget.clientHeight) <
      eventTarget.clientHeight / 2
    ) {
      target.loadMore();
    }
  } else {
    target(event);
  }
}

export const VScroll = forwardRef(function (
  props: PropsWithChildren<VScrollProps>,
  outerRef: React.ForwardedRef<HTMLDivElement>,
) {
  const savedScroll = useSavedScroll();
  const webHost = useWebHost();

  const refreshControlStyle = useMemo(
    () =>
      props.refreshControlType ??
      (getOS() === "iOS" ? "classic_circle" : "material"),
    [props.refreshControlType],
  );

  const [pullDistance, setPullDistance] = useState<null | number>(null);

  const [refreshThresholdPassed, setRefreshThresholdPassed] = useState(false);

  function onScroll(event: React.UIEvent<HTMLElement>) {
    savedScroll.onScroll(event);

    if (props.onScroll) {
      doOnScrollAll(props.onScroll, event);
    }
  }

  function updateRoot(element: HTMLDivElement | null) {
    savedScroll.scrollRef(element);

    if (typeof outerRef == "function") {
      outerRef(element);
    } else {
      if (outerRef) {
        outerRef.current = element;
      }
    }
  }

  const initialY = useRef<number | null>(null);

  function onTouchStart(event: TouchEvent<HTMLDivElement>) {
    if (!props.refreshTarget) return;

    initialY.current = event.touches[0].clientY;
  }

  function onTouchMove(event: TouchEvent<HTMLDivElement>) {
    if (event.currentTarget.scrollTop > 0) return;

    if (initialY.current) {
      const dy = event.touches[0].clientY - initialY.current;
      setPullDistance(dy);
    }
  }

  function onTouchEnd(event: TouchEvent<HTMLDivElement>) {
    if (initialY.current) {
      initialY.current = null;
      if (refreshThresholdPassed) {
        setPullDistance(null);
      } else {
        setPullDistance(0);
      }
    }
  }

  async function doRefresh() {
    webHost.haptic("light").catch(andLog); // don't await
    if (props.refreshTarget) {
      await refreshTargetLoadAll(props.refreshTarget);
    }
  }

  const [translateStyle, setFocusState] = useImeTranslate();

  return (
    <VScrollable
      style={{
        ...props.style,
        overscrollBehavior: props.refreshTarget ? "none" : "auto",
        ...translateStyle,
        overflowY:
          pullDistance !== null && pullDistance > 0 ? "hidden" : "auto",
      }}
      onScroll={onScroll}
      ref={updateRoot}
      onTouchStartCapture={onTouchStart}
      onTouchMoveCapture={onTouchMove}
      onTouchEndCapture={onTouchEnd}
      onFocus={(e) =>
        setFocusState({
          isFocus: true,
          focusedElement: e.target,
        })
      }
      onBlur={(e) =>
        setFocusState({
          isFocus: false,
          focusedElement: e.target,
        })
      }
    >
      {props.refreshTarget && refreshControlStyle === "classic_circle" && (
        <CircleRefreshControl
          pullDistance={pullDistance}
          isLoading={isAnyRefreshTargetLoading(props.refreshTarget)}
          onThresholdPassed={setRefreshThresholdPassed}
          onStartRefresh={() => {
            doRefresh().catch(andLog);
          }}
        />
      )}

      {props.children}

      {props.refreshTarget &&
        (refreshControlStyle === "material" ||
          refreshControlStyle === "material_large") && (
          <MaterialRefreshControl
            isLarge={refreshControlStyle === "material_large"}
            isLoading={isAnyRefreshTargetLoading(props.refreshTarget)}
            pullDistance={pullDistance}
            onThresholdPassed={setRefreshThresholdPassed}
            onStartRefresh={() => {
              doRefresh().catch(andLog);
            }}
          />
        )}
    </VScrollable>
  );
});
