import { I18n } from "../hooks/useI18n";

export function secondsFromDate(date: Date) {
  return Math.floor(date.getTime() / 1000);
}

export function timeDelta(ms: number) {
  const minute = (Math.floor(Date.now()) - ms) / 1000 / 60;
  return {
    minute: Math.floor(minute),
    hour: Math.floor(minute / 60),
    day: Math.floor(minute / 60 / 24),
  };
}

// export function humanReadableTime(ms: number, i18n: I18n) {
//   const { minute, hour, day } = timeDelta(ms);
//   if (day > 0) {
//     return i18n.plural(day).poll_days(day);
//   } else if (hour > 0) {
//     return i18n.plural(hour).web3_ugc_hour_s(hour.toString());
//   } else if (minute > 0) {
//     return i18n.plural(minute).web3_v0_minute(minute);
//   } else {
//     return undefined;
//   }
// }

export function formatDate(timestamp: number): string {
  const date = new Date(timestamp);
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  };
  const formatter = new Intl.DateTimeFormat(navigator.language, options);
  return formatter.format(date);
}

export function shortFromDate(unixTs: number) {
  const date = new Date(unixTs * 1000);
  const formatter = new Intl.DateTimeFormat(navigator.language, {
    month: "short",
    year: "numeric",
    day: "numeric",
  });
  return formatter.format(date);
}

export function formatDateWithOptions(
  unixTs: number,
  op: Intl.DateTimeFormatOptions,
) {
  const date = new Date(unixTs * 1000);
  const formatter = new Intl.DateTimeFormat(navigator.language, op);
  return formatter.format(date);
}

export const toHHMMSS = (timeSecs: number) => {
  const secs = Math.round(Math.max(0, timeSecs));
  const hours = Math.floor(secs / 3600);
  const minutes = Math.floor(secs / 60) % 60;
  const seconds = secs % 60;

  return [hours, minutes, seconds]
    .map((v) => (v < 10 ? "0" + v : v))
    .filter((v, i) => v !== "00" || i > 0)
    .join(":");
};
