import React, {
  PropsWithChildren,
  ReactElement,
  useContext,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
} from "react";
import { RuleSet, styled } from "styled-components";
import close from "../res/images/ic_close_round.svg";
import {
  BounceIn,
  FadeIn,
  FadeOut,
  None,
  ShrinkFadeOut,
  SlideDown,
  SlideUp,
} from "./Keyframes";
import { Spring } from "./VStack";
import svgToMiniDataURI from "mini-svg-data-uri";
import colorSetAlpha from "color-alpha";
import { linear_gradient_border } from "./vscroll/SVGUtils";
import { useNativeModal, useNativePageOrNull } from "../hooks/useBridge";
import { andLog } from "./handleError";
import { StateId } from "../hooks/StateId";
import { useWebHostState } from "../hooks/useWebHostState";
import { useSafeAreaInsets } from "../hooks/useSafeAreaInsets";
import { useChange } from "../hooks/ExtHooks";
import { removeOptional } from "../utils/typeUtils";
import { ModalContext } from "./ModalContext";
import { PageRootContext } from "./PageRootContext";
import { Image } from "./Image";

const TopLightSVG = `
<svg xmlns="http://www.w3.org/2000/svg" width="309" height="38" viewBox="0 0 309 38" fill="none">
  <g filter="url(#filter0_f_613_4881)">
    <ellipse cx="154.447" cy="-40.472" rx="40.3607" ry="116.042" transform="rotate(-90 154.447 -40.472)" fill="white" fill-opacity="0.6"/>
  </g>
  <defs>
    <filter id="filter0_f_613_4881" x="0.504295" y="-118.732" width="307.885" height="156.521" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
      <feGaussianBlur stdDeviation="18.95" result="effect1_foregroundBlur_613_4881"/>
    </filter>
  </defs>
</svg>
`;

const ProjZBgSVG = `
<svg width="350" height="345" viewBox="0 0 350 345" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M41.6 16.4H309.4C313.887 16.4 317.228 16.4003 319.876 16.6167C322.52 16.8326 324.437 17.2619 326.082 18.1003C329.018 19.5959 331.404 21.9824 332.9 24.9177C333.738 26.5633 334.167 28.4805 334.383 31.1238C334.6 33.7718 334.6 37.113 334.6 41.6V304.4C334.6 308.887 334.6 312.228 334.383 314.876C334.167 317.52 333.738 319.437 332.9 321.082C331.404 324.018 329.018 326.404 326.082 327.9C324.437 328.738 322.52 329.167 319.876 329.383C317.228 329.6 313.887 329.6 309.4 329.6H41.6C37.113 329.6 33.7718 329.6 31.1238 329.383C28.4805 329.167 26.5633 328.738 24.9177 327.9C21.9824 326.404 19.5959 324.018 18.1003 321.082C17.2619 319.437 16.8326 317.52 16.6167 314.876C16.4003 312.228 16.4 308.887 16.4 304.4V41.6C16.4 37.113 16.4003 33.7718 16.6167 31.1238C16.8326 28.4805 17.2619 26.5633 18.1003 24.9177C19.5959 21.9824 21.9824 19.5959 24.9177 18.1003C26.5633 17.2619 28.4805 16.8326 31.1238 16.6167C33.7718 16.4003 37.113 16.4 41.6 16.4Z" fill="#160B29" stroke="#8F77FF" stroke-width="0.8"/>
<g opacity="0.8" filter="url(#filter0_f_302_90)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M15.75 41.2457C15.75 32.2848 15.75 27.8044 17.4939 24.3818C19.0279 21.3712 21.4756 18.9235 24.4862 17.3896C27.9087 15.6457 32.3892 15.6457 41.35 15.6457H309.15C318.111 15.6457 322.591 15.6457 326.014 17.3896C329.024 18.9235 331.472 21.3712 333.006 24.3818C334.75 27.8044 334.75 32.2848 334.75 41.2457V304.046C334.75 313.007 334.75 317.487 333.006 320.91C331.472 323.92 329.024 326.368 326.014 327.902C322.591 329.646 318.111 329.646 309.15 329.646H41.35C32.3892 329.646 27.9087 329.646 24.4862 327.902C21.4756 326.368 19.0279 323.92 17.4939 320.91C15.75 317.487 15.75 313.007 15.75 304.046V41.2457Z" stroke="#8947FF" stroke-width="3"/>
</g>
<path opacity="0.5" d="M26 22.25H311.4C314.484 22.25 316.783 22.2502 318.604 22.399C320.423 22.5476 321.745 22.8432 322.88 23.4217C324.903 24.4523 326.548 26.0969 327.578 28.1196C328.157 29.255 328.452 30.5768 328.601 32.3956C328.75 34.2173 328.75 36.5156 328.75 39.6V320C328.75 322.804 328.75 324.893 328.615 326.548C328.48 328.2 328.211 329.398 327.687 330.426C326.753 332.261 325.261 333.753 323.426 334.687C322.398 335.211 321.2 335.48 319.548 335.615C317.893 335.75 315.804 335.75 313 335.75H42C36.3954 335.75 32.2069 335.75 28.8844 335.478C25.5648 335.207 23.1317 334.666 21.0337 333.597C17.3175 331.704 14.2961 328.683 12.4026 324.966C11.3336 322.868 10.7929 320.435 10.5217 317.116C10.2502 313.793 10.25 309.605 10.25 304V38C10.25 35.1956 10.2502 33.1074 10.3854 31.4524C10.5204 29.8003 10.7887 28.602 11.3127 27.5736C12.2475 25.739 13.739 24.2475 15.5736 23.3127C16.602 22.7887 17.8003 22.5204 19.4524 22.3854C21.1074 22.2502 23.1956 22.25 26 22.25Z" stroke="url(#paint0_linear_302_90)" stroke-width="0.5"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M72.9837 14.8343C73.0539 14.7797 73.1403 14.75 73.2293 14.75H111.927C112.307 14.75 112.473 15.2318 112.172 15.4657L109.987 17.1657C109.917 17.2203 109.831 17.25 109.742 17.25H71.0444C70.6636 17.25 70.4982 16.7682 70.7988 16.5343L72.9837 14.8343Z" fill="#7F60FF"/>
<defs>
<filter id="filter0_f_302_90" x="0.658591" y="0.554267" width="349.183" height="344.183" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="6.7957" result="effect1_foregroundBlur_302_90"/>
</filter>
<linearGradient id="paint0_linear_302_90" x1="133.593" y1="444.454" x2="450.176" y2="180.217" gradientUnits="userSpaceOnUse">
<stop stop-color="#8F77FF"/>
<stop offset="1" stop-color="#8F77FF" stop-opacity="0.5"/>
</linearGradient>
</defs>
</svg>
`;

export const OverTheTop = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
`;

function ModalContainer(props: { children: ReactElement }) {
  const pageRootContext = useContext(PageRootContext);

  useEffect(() => {
    const overTheTop = <OverTheTop>{props.children}</OverTheTop>;
    pageRootContext.addModal(overTheTop);
    return () => {
      pageRootContext.removeModal(overTheTop);
    };
  }, [props.children]);

  return pageRootContext.hasPageRoot ? (
    <></>
  ) : (
    <OverTheTop>{props.children}</OverTheTop>
  );
}

enum Animation {
  None,
  In,
  Out,
}

export const ModalDimmer = styled.div<{ animation: Animation }>`
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  overflow: hidden;

  animation: ${(p) =>
      p.animation === Animation.None
        ? None
        : p.animation === Animation.In
          ? FadeIn
          : FadeOut}
    0.2s linear 1 forwards;
  animation-fill-mode: both;
`;

export function genBorder(baseColor: string | undefined) {
  return linear_gradient_border(
    18,
    1,
    -30,
    [colorSetAlpha(baseColor ?? "white", 0.5), 0],
    [colorSetAlpha(baseColor ?? "white", 0.1), 0.4],
    [colorSetAlpha(baseColor ?? "white", 0.1), 0.6],
    [colorSetAlpha(baseColor ?? "white", 0.5), 1],
  );
}

const ModalBg = styled.div<{ animation: Animation }>`
  position: relative;
  width: 90%;
  max-width: 500px;
  height: auto;
  max-height: 90%;

  border-image-source: url("${svgToMiniDataURI(ProjZBgSVG)}");
  border-image-repeat: round;
  border-image-slice: 30 fill;
  border-width: 30px;
  border-style: solid;

  transition: margin-bottom 0.2s ease-in-out;
  animation: ${(p) =>
      p.animation === Animation.None
        ? None
        : p.animation === Animation.In
          ? BounceIn
          : ShrinkFadeOut}
    ${(p) => (p.animation === Animation.In ? "0.3" : "0.15")}s linear 1 forwards;
  animation-fill-mode: both;
  box-sizing: border-box;
`;

export const ModalTitle = styled.div<{ mixin?: RuleSet<Object> }>`
  font-size: 18px;
  font-weight: 600;
  color: var(--color-text00);
  text-align: center;
  word-break: break-word;
  ${(p) => p.mixin};
`;

export const ModalDesc = styled.div<{ mixin?: RuleSet<Object> }>`
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: var(--color-text00);

  white-space: pre-wrap;
  ${(p) => p.mixin};
`;

export const ModalContentContainer = styled.div<{ mixin?: RuleSet<Object> }>`
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  // as prent
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  overflow: hidden;

  padding: 30px;
  gap: 21px;
  ${(p) => p.mixin};
`;

export function CenterModal(
  props: PropsWithChildren<{
    modal: ModalController;
    onClickBg?: () => void;
    onClickClose?: () => void;
    onBackPressed?: () => void;
    hideCloseBtn?: boolean;
    mixin?: RuleSet<Object>;
  }>,
) {
  requireModalController(props.modal);

  const safeAreaInsets = useSafeAreaInsets();

  useFreezeNavBar(props.modal, props.onBackPressed);

  return props.modal.mounted ? (
    <ModalContainer>
      <ModalContext.Provider value={{ modal: props.modal }}>
        <ModalDimmer
          onClick={() => {
            // document.activeElement always returns <body></body> at this point
            if (safeAreaInsets.imeBottom === 0) {
              if (props.onClickBg) {
                props.onClickBg();
              }
            }
          }}
          animation={props.modal.animation}
        />
        <ModalBg
          style={{ marginBottom: safeAreaInsets.imeBottom }}
          onClick={(e) => {
            e.stopPropagation();
          }}
          animation={props.modal.animation}
          onAnimationEnd={props.modal.onAnimationEnd}
        >
          <ModalContentContainer mixin={props.mixin}>
            {props.children}
          </ModalContentContainer>
        </ModalBg>
        {props.hideCloseBtn !== true && (
          <Image
            src={close}
            style={{ width: 44, height: 44, zIndex: 10000 }}
            onClick={props.onClickClose ?? removeOptional(props.modal.close)}
          />
        )}
      </ModalContext.Provider>
    </ModalContainer>
  ) : (
    <></>
  );
}

const BottomSheetBg = styled.div<{ animation: Animation }>`
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  padding-top: 18px;
  background: rgba(41, 41, 41, 1) url("${svgToMiniDataURI(TopLightSVG)}")
    no-repeat;
  background-position-x: center;

  position: relative;
  width: 100%;
  height: auto;

  animation: ${(p) =>
      p.animation === Animation.None
        ? None
        : p.animation === Animation.In
          ? SlideUp
          : SlideDown}
    0.3s ease-out 1 forwards;

  animation-fill-mode: both;
`;

type ModalControllerImpl = {
  readonly onAnimationEnd: () => void;
  readonly animation: Animation;
} & ModalController;

export interface ModalController {
  readonly mounted: boolean;
  readonly opened: boolean;
  readonly open: (completion?: () => void) => void;
  readonly close: (completion?: () => void) => void;
}

export function useModal(id: StateId, initialValue?: boolean): ModalController {
  const [modalOpen, setModalOpen] = useWebHostState<boolean>(
    id,
    initialValue ?? false,
  );
  const [mounted, setMounted] = useState(initialValue ?? false);
  const hasEverClosed = useRef(false);
  const completionRef = useRef<() => void>();

  useEffect(() => {
    if (modalOpen) {
      console.log("modal opened trigger mount");
      setMounted(true);
      if (completionRef.current) {
        completionRef.current();
      }
    } else {
      if (!hasEverClosed.current) {
        hasEverClosed.current = true;
      }
    }
  }, [modalOpen]);

  const setter = useMemo(() => {
    return (value: boolean, completion: (() => void) | undefined) => {
      setModalOpen((prev) => {
        if (value === prev) {
          if (completion) completion();
        } else {
          completionRef.current = completion;
        }
        return value;
      });
    };
  }, [setModalOpen]);

  return useMemo<ModalControllerImpl>(() => {
    return {
      opened: modalOpen,
      mounted: mounted,
      onAnimationEnd: () => {
        if (!modalOpen) {
          setMounted(false);
        }
        if (completionRef.current) {
          completionRef.current();
        }
      },
      animation: modalOpen
        ? hasEverClosed.current
          ? Animation.In
          : Animation.None
        : Animation.Out,
      close: (completion?: () => void) => {
        setter(false, completion);
      },
      open: (completion?: () => void) => {
        setter(true, completion);
      },
    };
  }, [mounted, modalOpen]);
}

function useFreezeNavBar(
  modal: ModalControllerImpl,
  onBack: (() => void) | undefined,
) {
  const modalId = useId();
  const nativePage = useNativePageOrNull();

  useEffect(() => {
    if (modal.mounted) {
      nativePage
        ?.freezeNavBar(modalId, () => {
          if (onBack) {
            onBack();
          } else {
            modal.close();
          }
        })
        .catch(andLog);
    } else {
      nativePage?.unfreezeNavBar(modalId).catch(andLog);
    }

    return () => {
      nativePage?.unfreezeNavBar(modalId).catch(andLog);
    };
  }, [modal.mounted]);
}

export type BottomSheetProps = {
  modal: ModalController;
  onClickBg?: () => void;
  onBackPressed?: () => void;
  hideCloseButton?: boolean;
  itemsGap?: number;
};

function requireModalController(
  controller: ModalController,
): asserts controller is ModalControllerImpl {
  if (
    "mounted" in controller &&
    "animation" in controller &&
    "onAnimationEnd" in controller
  ) {
  } else {
    throw Error("Not a valid ModalController");
  }
}

export function BottomSheet(props: PropsWithChildren<BottomSheetProps>) {
  requireModalController(props.modal);

  useFreezeNavBar(props.modal, props.onBackPressed);
  return props.modal.mounted ? (
    <ModalContainer>
      <ModalContext.Provider value={{ modal: props.modal }}>
        <ModalDimmer
          onClick={props.onClickBg ?? removeOptional(props.modal.close)}
          animation={props.modal.animation}
        />
        <Spring />
        <BottomSheetBg
          onClick={(e) => {
            e.stopPropagation();
          }}
          animation={props.modal.animation}
          onAnimationEnd={props.modal.onAnimationEnd}
        >
          {props.children}
        </BottomSheetBg>
      </ModalContext.Provider>
    </ModalContainer>
  ) : (
    <></>
  );
}

export function useModalWithItsOwnPage() {
  const nativeModal = useNativeModal();

  const modal = useModal("useModalWithItsOwnPage", false);
  useEffect(() => {
    console.log("useModalWithItsOwnPage open");
    modal.open();
  }, []);

  useChange(modal.mounted, (prev, current) => {
    if (!current) {
      console.log("useModalWithItsOwnPage dismiss");
      nativeModal.dismiss().catch(andLog);
    }
  });

  return modal;
}
